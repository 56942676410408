import { t } from "i18next"
import fp from "lodash/fp"

import { translateBoolean } from "helpers/utils/translations"
import { getAlertState } from "helpers/utils/alerts"
import type {
  AlertSettingParamType,
  AlertSettingStateType,
  AlertSettingType,
  AlertSettingTypeType,
  AccordionAlertSetting,
  IAccordionsList,
  OptionsParamSchema,
  ReverseThresholdField,
  AlertsAmount,
  AlertsFilterOptions,
} from "types/alerts.types"
import type { OrgAlertSetting, OrgAlertSettingState } from "types/orgs.types"
import type { IDeviceWRelsById, IDevsWRelsByIdWStates } from "types/device.types"
import type { DeviceGroupType, IGroupsWRelsByIdWStates } from "types/group.types"

export const getTypeParams = (
  type: string,
  alertTypes: AlertSettingTypeType[],
  filters: string[],
): AlertSettingParamType[] | undefined => {
  const alertType = alertTypes.find(({ name }) => name === type)
  return alertType?.params.filter(({ id }) => !filters.includes(id))
}

export const getConfigValue = (
  typeParam: AlertSettingParamType,
  alertSetting: AlertSettingType,
) => {
  const { id } = typeParam
  const value = alertSetting?.config[id]
  if (value === undefined) {
    return typeParam.default ? typeParam.default : "-"
  }
  return value
}

export const getUiConfigValue = (
  typeParam: AlertSettingParamType,
  alertSetting: AlertSettingType,
) => {
  let value = getConfigValue(typeParam, alertSetting)
  if (typeParam.type === "bool") {
    return translateBoolean(value as boolean)
  }
  if (typeParam.type === "array") {
    return fp
      .map(
        (item: string | ReverseThresholdField) => {
          if (typeof item !== "object") {
            return item
          }
          const parts = []
          // U00a0: nbsp ; U2192: ->
          if (item.min !== undefined && item.min !== null) {
            parts.push(`${item.min}\u00a0<`)
          }
          parts.push(t("alerts.VALUE"))
          if (item.max !== undefined && item.max !== null) {
            parts.push(`<\u00a0${item.max}`)
          }
          parts.push(`\u2192\u00a0${item.target}`)
          return parts.join(" ")
        },
        value as string[] | ReverseThresholdField[],
      )
      .join(", ")
  }
  if (typeParam.type === "options") {
    const optionSchema = typeParam.schema as OptionsParamSchema
    const option = optionSchema?.oneOf.find((item) => item.const === value)
    value = option && option.label ? option.label : value
  }
  if (typeParam.unit) {
    return `${value} ${typeParam.unit}`
  }
  return value
}

export const formatStateBySettingId = (
  states: OrgAlertSettingState[] | undefined,
): { [key: string]: AlertSettingStateType } =>
  fp.flow(fp.indexBy("id"), fp.mapValues(fp.prop("state")))(states)

export const formatSettingsById = (
  settings: OrgAlertSetting[] | undefined,
): { [key: string]: OrgAlertSetting } => fp.indexBy("id", settings)

export const formatSettingsByDeviceId = (
  states: OrgAlertSettingState[] | undefined,
): { [key: string]: number[] } =>
  fp.flow(
    fp.filter("less_id"),
    fp.groupBy("less_id"),
    fp.mapValues(fp.map(fp.prop("id"))),
  )(states)

export const formatSettingsByGroupId = (
  states: OrgAlertSettingState[] | undefined,
): { [key: string]: number[] } =>
  fp.flow(
    fp.filter("group_id"),
    fp.groupBy("group_id"),
    fp.mapValues(fp.map(fp.prop("id"))),
  )(states)

// This function is aimed to format groups and devices
export const formatEntitiesWithStates = (
  entitiesById:
    | { [key: string]: { group: DeviceGroupType } }
    | IDeviceWRelsById
    | undefined,
  settingsByEntityId: { [key: string]: number[] },
  statesById: { [key: string]: AlertSettingStateType },
): IDevsWRelsByIdWStates | IGroupsWRelsByIdWStates =>
  fp.flow(
    fp.defaultTo({}),
    fp.toPairs,
    fp.map(([entityId, entity]) => {
      const getSettingsStates = fp.flow(
        fp.get(entityId),
        fp.map((settingId: number) => [settingId, fp.get(settingId)(statesById)]),
        fp.fromPairs,
      )
      const settingsStates = getSettingsStates(settingsByEntityId)
      return [
        entityId,
        {
          ...entity,
          settingsStates,
        },
      ]
    }),
    fp.fromPairs,
  )(entitiesById)

/**
 * Format the alerts to obtain the data for the accordions
 * Currently ignoring group alerts
 *
 * When using the groupId param, it can be used for the group alerts list (device alerts belonging to a certain group)
 */
export const formatAlerts = (
  orgAlerts: OrgAlertSettingState[] | undefined,
  alertsBySettingId: { [key: string]: OrgAlertSetting },
  devsWRelsById: IDeviceWRelsById | undefined,
  groupId?: number,
): IAccordionsList =>
  fp.flow(
    fp.defaultTo([] as OrgAlertSettingState[]),
    fp.filter((alert: OrgAlertSettingState) => {
      const isDeviceAlert = fp.has("less_id", alert)
      const deviceExistsInMemory = alert.less_id
        ? fp.has(alert.less_id, devsWRelsById)
        : false
      const alertExistsInMemory = fp.has(alert.id, alertsBySettingId)
      return isDeviceAlert && deviceExistsInMemory && alertExistsInMemory
    }),
    fp.map((alert: OrgAlertSettingState): AccordionAlertSetting => {
      return {
        ...alertsBySettingId[alert.id],
        name: (devsWRelsById || {})[alert.less_id as number].device.name,
        state: getAlertState(alert.state),
        triggered_at: alert.state?.triggered_at,
        ...(groupId && {
          groupsIds: (devsWRelsById || {})[alert.less_id as number].groups.map(
            (group) => group.id,
          ),
        }),
      }
    }),
    fp.filter((alert) =>
      groupId
        ? (alert.groupsIds as number[]).includes(groupId) &&
          (alert.config.active as boolean)
        : (alert.config.active as boolean),
    ),
    fp.sortBy((alert) => alert.name.toLowerCase()),
    fp.groupBy((alert) => alert.state),
    fp.defaults({
      open: [],
      silenced: [],
      notTriggered: [],
    }),
  )(orgAlerts)

export const getAlertAmounts = (alerts: IAccordionsList): AlertsAmount => {
  return fp.mapValues((stateAlerts) => stateAlerts.length, alerts)
}

export const getFilteredSortedAlerts = (
  alerts: IAccordionsList,
  filters: AlertsFilterOptions | null,
): AccordionAlertSetting[] => {
  if (!filters) {
    return []
  }
  const keys: ("open" | "silenced" | "notTriggered")[] = [
    "open",
    "silenced",
    "notTriggered",
  ]
  return fp.flatMap((key) => (filters[key] ? alerts[key] : []), keys)
}
