import { lazy, useCallback, useState } from "react"
import type { FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"
import Stack from "@mui/material/Stack"
import { Dialog } from "@mui/material"

import { useChangePasswordMutation, useUserQuery } from "features/api"
import {
  selectCurrentUser,
  selectOtpCode,
  updateCode,
  updateOtpCode,
  updateToken,
} from "features/store/authSlice"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import CardWithTitle from "widgets/common/CardWithTitle"
import FormButtons from "widgets/common/FormButtons"
import OTPVerifierModal from "widgets/common/otp/OTPVerifierModal"
import ControlledPasswordInput from "../common/ControlledPasswordInput"

const NewPasswordInput = lazy(() =>
  import("../common/NewPasswordInput").then((module) => ({
    default: module.default,
  })),
)

interface IChangePasswordModalProps {
  onClose: () => void
  open: boolean
}

interface IChangePasswordForm {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

const ChangePasswordModal: FC<IChangePasswordModalProps> = ({ onClose, open }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [changePasswordData, setChangePasswordData] = useState<IChangePasswordForm>({
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  })
  const [openOTPVerifier, setOpenOTPVerifier] = useState(false)
  const username = useSelector(selectCurrentUser)
  const { data: user } = useUserQuery({ username: String(username) }, { skip: !username })
  const otpCode = useSelector(selectOtpCode)

  const handleOTPVerifierClose = () => {
    setOpenOTPVerifier(false)
  }

  const methods = useForm<IChangePasswordForm>({
    mode: "all",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
  })
  const { formState } = methods

  const [changePassword, { isLoading: changePasswordLoading, reset }] =
    useChangePasswordMutation()

  const handleCancel = useCallback(() => {
    methods.reset()
    reset()
    onClose()
  }, [methods, onClose, reset])

  const onSubmit = async (data: IChangePasswordForm) => {
    if (user?.otp?.enabled) {
      // OTP verification required
      setChangePasswordData(data)
      setOpenOTPVerifier(true)
    } else {
      const req = {
        username: username as string,
        current_password: data.currentPassword,
        new_password: data.newPassword,
      }
      snackbarMutation({
        mutation: changePassword(req).unwrap(),
        getErrorMessage: buildGetErrorMessage(
          t("error.UPDATING_ITEM", { item: t("login.PASSWORD").toLowerCase() }),
        ),
        getSuccessMessage: () =>
          t("success.UPDATING_ITEM", {
            item: t("login.PASSWORD"),
            count: 1,
            context: "female",
          }),
      })
        .then((response) => {
          dispatch(
            updateCode({
              code: response?.code,
            }),
          )
        })
        .then(() => {
          handleCancel()
        })
        .catch()
    }
  }

  const handleOTPVerifier = useCallback(
    async (value: string) => {
      const req = {
        username: username as string,
        current_password: changePasswordData.currentPassword,
        new_password: changePasswordData.newPassword,
        otp_value: value,
      }
      await snackbarMutation({
        mutation: changePassword(req).unwrap(),
        getErrorMessage: buildGetErrorMessage(
          t("error.UPDATING_ITEM", { item: t("login.PASSWORD").toLowerCase() }),
        ),
        getSuccessMessage: () =>
          t("success.UPDATING_ITEM", {
            item: t("login.PASSWORD"),
            count: 1,
            context: "female",
          }),
      })
        .then((response) => {
          dispatch(updateCode({ code: response.code }))
          dispatch(updateOtpCode({ otpCode: response.otp_code }))
          dispatch(updateToken({ token: response.access_token }))
        })
        .catch()
    },
    [
      changePassword,
      changePasswordData.currentPassword,
      changePasswordData.newPassword,
      dispatch,
      t,
      username,
    ],
  )

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        fullWidth
        maxWidth={"sm"}
        sx={{
          "& .MuiPaper-root": {
            overflow: "visible",
          },
        }}
      >
        <CardWithTitle
          titleKey="change_password.CHANGE_PASSWORD"
          handleAction={handleCancel}
          actionIcon={<CloseIcon fontSize="small" color="action" />}
        >
          <form autoComplete="new-password" onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <Stack mb={2}>
                <ControlledPasswordInput
                  size="small"
                  variant="outlined"
                  label={t("change_password.OLD_PASSWORD")}
                  {...methods.register("currentPassword", {
                    required: t("generic.FIELD_REQUIRED"),
                  })}
                />
                <NewPasswordInput methods={methods} />
                <ControlledPasswordInput
                  size="small"
                  variant="outlined"
                  label={t("change_password.NEW_PASSWORD_REPEAT")}
                  {...methods.register("newPasswordConfirmation", {
                    required: t("generic.FIELD_REQUIRED"),
                    validate: {
                      matchesPreviousPassword: (value) => {
                        const { newPassword } = methods.getValues()
                        return newPassword === value || t("register.PASSWORD_MATCH")
                      },
                    },
                  })}
                />
              </Stack>
              <FormButtons
                onClose={handleCancel}
                disabled={!formState.isValid}
                buttonText={"generic.UPDATE"}
                isLoading={changePasswordLoading}
              />
            </FormProvider>
          </form>
        </CardWithTitle>
      </Dialog>
      <OTPVerifierModal
        open={openOTPVerifier}
        onClose={handleOTPVerifierClose}
        onParentClose={handleCancel}
        otpCode={otpCode || ""}
        onVerify={handleOTPVerifier}
        isVerifying={changePasswordLoading}
      />
    </>
  )
}

export default ChangePasswordModal
