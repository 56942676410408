import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"

import { apiMiddleware } from "features/api/base"
import { api } from "../api"
import { authMiddleware, authReducer } from "./authSlice"
import { configMiddleware, configReducer } from "./configSlice"
import { orgMiddleware, orgReducer } from "./orgSlice"
import { tablesMiddleware, tablesReducer } from "./tablesSlice"
import { dateRangeReducer } from "./dateRangeSlice"

// const logger = (store) => (next) => (action) => {
//   console.group(action.type)
//   console.info("dispatching", action)
//   const result = next(action)
//   console.log("next state", store.getState())
//   console.groupEnd()
//   return result
// }

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    config: configReducer,
    org: orgReducer,
    tables: tablesReducer,
    dateRange: dateRangeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      apiMiddleware,
      authMiddleware,
      configMiddleware,
      orgMiddleware,
      tablesMiddleware,
    ),
})

// Configure listeners with the recommended defaults
// Useful for refetch on events like focus or reconnect
// https://redux-toolkit.js.org/rtk-query/api/setupListeners
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
