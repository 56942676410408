import type { FC } from "react"
import { Link } from "react-router-dom"

import { Stack } from "@mui/material"
import type { DeviceGroupRefType } from "../../types/group.types"
import useRoutes from "../../helpers/hooks/useRoutes"
import Chip from "../../widgets/common/Chip"

interface GroupChipProps {
  group: DeviceGroupRefType
}

export const GroupChip: FC<GroupChipProps> = ({ group: { name, id } }) => {
  const { baseOrgURL } = useRoutes()

  return (
    <Link to={`${baseOrgURL}/device-groups/${id}`}>
      <Chip text={name} colored />
    </Link>
  )
}

interface GroupsChipsProps {
  groups: DeviceGroupRefType[]
}

const GroupsChips: FC<GroupsChipsProps> = ({ groups }) => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "0.5rem",
      }}
    >
      {groups.map((group) => (
        <GroupChip key={group.id} group={group} />
      ))}
    </Stack>
  )
}

export default GroupsChips
