import type { FC } from "react"
import { Card, Typography, Button, CardContent } from "@mui/material"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

interface ResultCardProps {
  type: "success" | "error"
  text: string
  button?: string
  responsive?: boolean
  onClick?: () => void
}

const ResultCard: FC<ResultCardProps> = ({
  type,
  text,
  button,
  responsive = false,
  onClick,
}) => {
  const successType = type === "success"
  const errorType = type === "error"

  const cardContent = (
    <CardContent data-testid={"card-" + type}>
      {successType && (
        <CheckCircleOutlineIcon color="secondary" sx={{ fontSize: "3rem" }} />
      )}
      {errorType && <ErrorOutlineIcon color="error" sx={{ fontSize: "3rem" }} />}
      <Typography variant="h5" component="h5" align="center" sx={{ mb: 2, mt: 1 }}>
        {text}
      </Typography>
      {button && <Button onClick={onClick}>{button}</Button>}
    </CardContent>
  )

  if (responsive) {
    return (
      <Card
        sx={{
          width: "100%",
          textAlign: "center",
          p: "1rem",
          py: { mb: "2rem" },
        }}
      >
        {cardContent}
      </Card>
    )
  }

  return (
    <Card
      sx={{
        maxWidth: 360,
        minWidth: 280,
        width: "50%",
        textAlign: "center",
        p: "1rem",
      }}
      elevation={4}
    >
      {cardContent}
    </Card>
  )
}

export default ResultCard
