import type { FC } from "react"
import { ButtonBase, Card, Stack, Typography } from "@mui/material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import type { AlertSettingParamType, ThresholdsField } from "../../../types/alerts.types"
import type { InputRulesType } from "../../common/ControlledInput"
import { DashedBox } from "../../styled/containers"
import ThresholdInput from "./ThresholdInput"
import { getRangeDropdownOptions } from "./thresholds"

interface ThresholdFormProps {
  param: AlertSettingParamType
  rules?: InputRulesType
}

const ThresholdForm: FC<ThresholdFormProps> = ({ param }) => {
  const { control } = useFormContext()
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "thresholds",
  })
  const { t } = useTranslation()
  const { defaultRangeCondition } = getRangeDropdownOptions(t)

  const statusDropdownOptions =
    param?.schema &&
    "type" in param.schema &&
    param.schema.type === "array" &&
    param?.schema?.items?.type === "object"
      ? param.schema.items.properties?.target?.enum?.map((item) => ({
          label: item.toUpperCase(),
          value: item.toUpperCase(),
        }))
      : undefined

  return (
    <>
      <Stack sx={{ width: "100%", mb: "1rem" }}>
        <Typography sx={{ mb: "1rem" }}>{param.name}</Typography>
        {fields?.map((field, index) => (
          <Card
            sx={{ width: "100%", p: "0rem 1rem 1rem 1rem", mb: "1rem" }}
            key={field.id}
          >
            <ThresholdInput
              update={update}
              remove={remove}
              index={index}
              value={field as ThresholdsField}
              control={control}
              statusDropdownOptions={statusDropdownOptions}
            />
          </Card>
        ))}
        <DashedBox>
          <ButtonBase
            sx={{ width: "100%", height: "100%", p: "1rem" }}
            onClick={() => {
              append({
                rangeCondition: defaultRangeCondition,
                above: undefined,
                below: undefined,
                inside: { from: undefined, to: undefined },
                outside: { from: undefined, to: undefined },
                alertStatus: statusDropdownOptions && statusDropdownOptions[0].value,
              })
            }}
          >
            <AddCircleOutlineIcon color="primary" />
          </ButtonBase>
        </DashedBox>
      </Stack>
    </>
  )
}

export default ThresholdForm
