import type { CSSProperties } from "react"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import type { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables"

import type { UserOrgRole } from "types/users.types"
import type { USER_ROLES } from "helpers/utils/constants"

import { getColsByName } from "helpers/utils/alerts"
import SkeletonWrapper from "widgets/common/SkeletonWrapper"
import GenericTable from "widgets/common/Table"
import { formatUsersTableCsv, getUsersTableColumns, getUsersTableRows } from "./helpers"
import EditUserModal from "./EditUserModal"
import RemoveUserModal from "./RemoveUserModal"

interface UsersTableProps {
  users: UserOrgRole[] | undefined
  isLoading: boolean
  isFetching?: boolean
  titleKey?: string
  customColumns?: MUIDataTableColumn[]
  options?: MUIDataTableOptions
  styles?: CSSProperties
}

const UsersTable = ({
  users = [],
  isLoading,
  isFetching,
  titleKey,
  customColumns = [],
  options,
  styles = {},
}: UsersTableProps) => {
  const { t } = useTranslation()
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false)
  const [rowUsername, setRowUsername] = useState<string>("")
  const [rowRole, setRowRole] = useState<keyof typeof USER_ROLES>("editor")

  const handleOpenEdit = useCallback(
    (username: string, role: keyof typeof USER_ROLES) => {
      setRowUsername(username)
      setRowRole(role)
      setOpenEditModal(true)
    },
    [],
  )
  const handleCloseEdit = useCallback(() => setOpenEditModal(false), [])

  const handleOpenRemove = useCallback((username: string) => {
    setRowUsername(username)
    setOpenRemoveModal(true)
  }, [])
  const handleCloseRemove = useCallback(() => setOpenRemoveModal(false), [])

  const columns: MUIDataTableColumn[] = useMemo(
    () =>
      customColumns.length > 0
        ? customColumns
        : getUsersTableColumns(t, handleOpenEdit, handleOpenRemove),
    [customColumns, handleOpenRemove, handleOpenEdit, t],
  )

  const { rows, columnsIndexes } = useMemo(() => {
    return {
      rows: getUsersTableRows(users),
      columnsIndexes: getColsByName(columns),
    }
  }, [users, columns])

  return (
    <>
      <SkeletonWrapper
        isWaiting={isLoading || Boolean(isFetching)}
        height={540}
        width={"100vw"}
      >
        <GenericTable
          styles={styles}
          title={titleKey}
          rows={rows}
          columns={columns}
          options={{
            selectableRows: "none",
            onDownload: (buildHead, buildBody, columns, data) => {
              const formattedData = formatUsersTableCsv(data, columnsIndexes, t)
              return `${buildHead(columns)}${buildBody(formattedData)}`.trim()
            },
            rowsPerPage: 10,
            sortOrder: { name: "username", direction: "asc" },
            viewColumns: false,
            ...options,
          }}
        />
      </SkeletonWrapper>
      <EditUserModal
        open={openEditModal}
        onClose={handleCloseEdit}
        username={rowUsername}
        role={rowRole}
      />
      <RemoveUserModal
        open={openRemoveModal}
        onClose={handleCloseRemove}
        username={rowUsername}
      />
    </>
  )
}

export default UsersTable
