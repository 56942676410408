import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useLimitedAlertsQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { ALERT_TIME } from "helpers/utils/constants"

interface useLimitedEventsProps {
  settingId: number
  limit?: number
}

const useLimitedEvents = ({ settingId, limit = 10 }: useLimitedEventsProps) => {
  const { i18n } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)

  const { data: events, isLoading } = useLimitedAlertsQuery(
    {
      params: {
        lang: i18n.resolvedLanguage,
        org_id: Number(orgId),
        alert_setting_id: settingId,
        limit,
      },
    },
    { pollingInterval: ALERT_TIME, skip: !orgId },
  )

  return {
    events,
    isLoading,
  }
}

export default useLimitedEvents
