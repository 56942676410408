import { useMemo } from "react"
import { useSelector } from "react-redux"

import type {
  IDeviceWRelsById,
  IDevsWRelsByIdWStates,
  ILessIdByDeviceId,
} from "../../types/device.types"
import { formatDevsWRelsById } from "../../helpers/formatters/dataFormatters"
import { formatEntitiesWithStates } from "../../helpers/formatters/alertSettingsFormatters"
import { selectCurrentOrgId } from "../../features/store/orgSlice"
import { useDevicesQuery } from "../../features/api"
import { DEVICES_TABLE_TIME } from "../utils/constants"
import useAlertSettingsStates from "./useAlertSettingsStates"
import useDeviceGroups from "./useDeviceGroups"

interface IUseDevices {
  isSuccess: boolean
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  devsWRelsById: IDeviceWRelsById | undefined
  devsWRelsByIdWStates: IDevsWRelsByIdWStates
  lessIdByDeviceId: ILessIdByDeviceId | undefined
}

const useDevices = (): IUseDevices => {
  const orgId = useSelector(selectCurrentOrgId)
  const { groups } = useDeviceGroups()
  const { alertSettingsByDeviceId, statesBySettingId } = useAlertSettingsStates()
  const {
    currentData: orgDevices,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useDevicesQuery(
    {
      params: { org_id: orgId as number },
    },
    {
      skip: !orgId,
      pollingInterval: DEVICES_TABLE_TIME,
    },
  )

  const { devsWGroups, lessIdByDeviceId } = useMemo(
    () => formatDevsWRelsById(groups, orgDevices),
    [groups, orgDevices],
  )

  const devsWRelsById = devsWGroups

  const devsWRelsByIdWStates = useMemo(
    () =>
      formatEntitiesWithStates(
        devsWRelsById,
        alertSettingsByDeviceId,
        statesBySettingId,
      ) as IDevsWRelsByIdWStates,
    [devsWRelsById, alertSettingsByDeviceId, statesBySettingId],
  )

  return {
    devsWRelsById,
    devsWRelsByIdWStates,
    lessIdByDeviceId,
    isError,
    isSuccess,
    isFetching,
    isLoading,
  }
}

export default useDevices
