import type { PropsWithChildren } from "react"

import type { SkeletonPropsVariantOverrides } from "@mui/material"
import { Box, Skeleton } from "@mui/material"
import type { OverridableStringUnion } from "@mui/types"

interface SkeletonWrapperProps {
  isWaiting: boolean
  variant?: OverridableStringUnion<
    "text" | "rectangular" | "rounded" | "circular",
    SkeletonPropsVariantOverrides
  >
  height?: number | string
  width?: number | string
}

const SkeletonWrapper = ({
  isWaiting,
  variant = "rounded",
  children,
  height,
  width,
}: PropsWithChildren<SkeletonWrapperProps>) => {
  return isWaiting ? (
    <Skeleton variant={variant} sx={{ overflow: "hidden" }}>
      <Box height={height} width={width}>
        {children}
      </Box>
    </Skeleton>
  ) : (
    <>{children}</>
  )
}

export default SkeletonWrapper
