import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { DEFAULT_LANGUAGE, LANGUAGE_KEYS } from "../helpers/utils/constants"
import { DEBUG } from "./config"

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
}

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    debug: DEBUG,
    fallbackLng: DEFAULT_LANGUAGE,
    whitelist: LANGUAGE_KEYS,
    interpolation: {
      escapeValue: false,
    },
    detection: DETECTION_OPTIONS,
  })

export default i18next
