import type { FC } from "react"
import { useMemo } from "react"

import { getColorFromString } from "../../helpers/utils/styles"
import { Chip as MuiChip } from "../styled/buttons"

interface ChipProps {
  text: string
  colored?: boolean
  color?:
    | "error"
    | "default"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | undefined
}

const Chip: FC<ChipProps> = ({ text, colored, color }) => {
  const chipColor = useMemo(
    () => (colored ? getColorFromString(text) : undefined),
    [text, colored],
  )

  return (
    <MuiChip
      color={color}
      customColor={chipColor}
      label={text}
      variant="outlined"
      size="small"
    />
  )
}

export default Chip
