import { useSelector } from "react-redux"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { useTowerQuery } from "features/api"

interface useTowerProps {
  id: number | undefined
}

const useTower = ({ id }: useTowerProps) => {
  const orgId = useSelector(selectCurrentOrgId)

  const {
    data: tower,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useTowerQuery(
    {
      id: Number(id),
      params: { org_id: Number(orgId) },
    },
    {
      skip: !orgId || !id,
    },
  )

  return { tower, isFetching, isLoading, isError, isSuccess }
}

export default useTower
