import { Grid } from "@mui/material"

import type { AccordionAlertSetting } from "types/alerts.types"
import AlertAccordion from "widgets/alerts/AlertAccordion"
import Message from "widgets/common/Message"

interface AccordionsSectionProps {
  alerts: AccordionAlertSetting[]
}

const AccordionsSection = ({ alerts }: AccordionsSectionProps) => {
  return (
    <Grid container spacing={2} mb={2}>
      {alerts.length ? (
        alerts.map((alert) => {
          return (
            <Grid key={alert.id} item xs={12}>
              <AlertAccordion alert={alert} />
            </Grid>
          )
        })
      ) : (
        <Message messageKey="alerts.NO_ALERTS_ACCORDIONS" />
      )}
    </Grid>
  )
}

export default AccordionsSection
