import type { ReactNode } from "react"
import { Stack, Typography } from "@mui/material"

interface IconTextStackProps {
  icon: ReactNode
  text: string
}

const IconTextStack = ({ icon, text }: IconTextStackProps) => {
  return (
    <Stack direction={"row"} gap={1} alignItems={"center"}>
      {icon}
      <Typography>{text}</Typography>
    </Stack>
  )
}

export default IconTextStack
