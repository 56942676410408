import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"
import fp from "lodash/fp"

import type { DeviceWGroupsNStatesType } from "types/device.types"

import useDevices from "helpers/hooks/useDevices"
import usePermissions from "helpers/hooks/usePermissions"
import Title from "widgets/common/Title"
import { HeaderContainer } from "widgets/styled/containers"
import CreateDeviceButton from "./editor/CreateDeviceButton"
import DevicesTable from "./DevicesTable"

const OrgDevices = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { devsWRelsByIdWStates, isFetching, isLoading } = useDevices()
  const { isBackoffice } = usePermissions()

  const devices: DeviceWGroupsNStatesType[] = useMemo(
    () => fp.values(devsWRelsByIdWStates),
    [devsWRelsByIdWStates],
  )
  const isFirstFetch = useMemo(
    () => isFetching && fp.isEmpty(devsWRelsByIdWStates),
    [devsWRelsByIdWStates, isFetching],
  )

  return (
    <>
      <HeaderContainer>
        <Title text={t("generic.DEVICES")} />
        {isBackoffice && <CreateDeviceButton />}
      </HeaderContainer>
      <DevicesTable
        devices={devices}
        isFetching={isFirstFetch}
        isLoading={isLoading}
        styles={{ border: `1px solid ${theme.palette.neutral[400]}` }}
      />
    </>
  )
}

export default OrgDevices
