import type { CSSProperties, ReactNode } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { getEventsTableRows } from "helpers/utils/alerts"
import type { Paged } from "types/common.types"
import type { AlertsRes } from "types/alerts.types"
import IconTextStack from "widgets/common/IconTextStack"
import GenericTable from "widgets/common/Table"
import Spinner from "widgets/common/Spinner"

interface EventsTableProps {
  events: Paged<AlertsRes> | undefined
  isLoading: boolean
}

function EventsTable({ events, isLoading }: EventsTableProps) {
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      {
        name: "eventDescription",
        label: t("alerts.EVENT_DESCRIPTION"),
        options: {
          filter: false,
          sort: true,
          display: true,
          setCellProps: (): object => ({ style: { minWidth: "250px" } }),
          customBodyRender: (value: { icon: ReactNode; description: string }) => (
            <IconTextStack icon={value.icon} text={value.description} />
          ),
        },
      },
      {
        name: "timestamp",
        label: t("generic.TIMESTAMP"),
        options: {
          setCellProps: (): object => ({ style: { minWidth: "150px" } }),
          filter: false,
          sort: true,
          display: true,
        },
      },
    ],
    [t],
  )

  const rows = useMemo(() => {
    if (events) {
      return getEventsTableRows(events.results, t)
    }
  }, [events, t])

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {rows && (
            <GenericTable
              rows={rows}
              columns={columns}
              options={{
                pagination: false,
                search: false,
                download: false,
                print: false,
                viewColumns: false,
              }}
              styles={{
                backgroundColor: "transparent",
                ["& .MuiTableCell-head" as keyof CSSProperties]: {
                  backgroundColor: "transparent",
                },
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default EventsTable
