import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import type { QuerySubState } from "@reduxjs/toolkit/dist/query/core/apiState"
import type { BaseEndpointDefinition } from "@reduxjs/toolkit/dist/query/endpointDefinitions"

import AlertsTable from "widgets/alerts/AlertsTable"
import DateRangePicker from "widgets/dates/DateRangePicker"
import { SectionContainer } from "widgets/styled/containers"
import type { DateRange } from "types/date.types"
import type { RootState } from "features/store"

interface AlertsContainerProps {
  dateRange: DateRange | null
  dispatchDateRange: (dateRange: DateRange) => void
  isDateRangeValid: boolean
  devicesIds?: number[]
  groupId?: number
  showDatePicker?: boolean
}

const AlertsContainer = ({
  dateRange,
  dispatchDateRange,
  isDateRangeValid,
  devicesIds,
  groupId,
  showDatePicker = true,
}: AlertsContainerProps) => {
  const isFetching = useSelector((state: RootState) => {
    const queries = Object.values(state.api.queries)
    return queries.some(
      (query: QuerySubState<BaseEndpointDefinition<any, any, any>> | undefined) =>
        query?.status === "pending",
    )
  })

  return (
    <>
      {showDatePicker && (
        <Box
          sx={{
            p: "1.5rem 0 0.75rem 0",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <DateRangePicker
            dateRange={dateRange}
            dispatchDateRange={dispatchDateRange}
            isFetching={isFetching}
          />
        </Box>
      )}
      <SectionContainer disableGutters maxWidth="xl">
        <AlertsTable
          dateRange={dateRange}
          isDateRangeValid={isDateRangeValid}
          devicesIds={devicesIds}
          groupId={groupId}
        />
      </SectionContainer>
    </>
  )
}

export default AlertsContainer
