import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import {
  formatAlerts,
  getAlertAmounts,
  getFilteredSortedAlerts,
} from "helpers/formatters/alertSettingsFormatters"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import useAlertSettingsStates from "helpers/hooks/useAlertSettingsStates"
import useDevices from "helpers/hooks/useDevices"
import { paginateList } from "helpers/utils/common"
import type { AlertsFilterOptions } from "types/alerts.types"
import Spinner from "widgets/common/Spinner"
import AccordionsSection from "widgets/alerts/AccordionsSection"
import AlertsFilter from "widgets/alerts/AlertsFilter"
import Pagination from "widgets/common/Pagination"

const GroupAlertsList = () => {
  const { id } = useParams()
  const { allAlerts, isLoading } = useAlertSettingsStates({
    groupId: Number(id),
  })
  const { orgAlertsBySettingId } = useAlertSettings()
  const { devsWRelsById } = useDevices()
  const [filters, setFilters] = useState<AlertsFilterOptions | null>(null)
  const [page, setPage] = useState(0)

  const formattedGroupAlerts = useMemo(
    () => formatAlerts(allAlerts, orgAlertsBySettingId, devsWRelsById, Number(id)),
    [allAlerts, orgAlertsBySettingId, devsWRelsById, id],
  )
  const groupAlertsAmount = useMemo(
    () => getAlertAmounts(formattedGroupAlerts),
    [formattedGroupAlerts],
  )
  const groupAlertsSortedByState = useMemo(
    () => getFilteredSortedAlerts(formattedGroupAlerts, filters),
    [filters, formattedGroupAlerts],
  )
  const pagedAlerts = useMemo(
    () => paginateList(page, groupAlertsSortedByState),
    [groupAlertsSortedByState, page],
  )

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <AlertsFilter
            alertsAmount={groupAlertsAmount}
            filters={filters}
            setFilters={setFilters}
          />
          <AccordionsSection alerts={pagedAlerts} />
          <Pagination
            count={groupAlertsSortedByState.length}
            page={page}
            setPage={setPage}
          />
        </>
      )}
    </>
  )
}

export default GroupAlertsList
