import styled from "@emotion/styled"
import { Container } from "@mui/material"

import { DASHBOARD_PADDING, FOOTER_HEIGHT, NAVBAR_HEIGHT } from "helpers/utils/constants"

export const DashboardLayoutRoot = styled(Container)(() => ({
  flexGrow: 1,
  height: "100%",
  overflow: "auto",
  paddingTop: NAVBAR_HEIGHT + 20,
  paddingRight: DASHBOARD_PADDING,
  paddingLeft: DASHBOARD_PADDING,
  marginBottom: FOOTER_HEIGHT,
  width: "unset",
}))
