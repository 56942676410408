import { useCallback, type SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import type { UserOTPRegisterRes } from "types/users.types"

import { useUserOTPDisableMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import ConfirmationPopup from "widgets/common/ConfirmationPopup"

interface OTPDisableModalProps {
  open: boolean
  onClose: () => void
  username: string
  setOtpRegisterData: (value: SetStateAction<UserOTPRegisterRes | undefined>) => void
}

const OTPDisableModal = ({
  open,
  onClose,
  username,
  setOtpRegisterData,
}: OTPDisableModalProps) => {
  const { t } = useTranslation()
  const [otpDisable, { isLoading }] = useUserOTPDisableMutation()

  const disableOtp = useCallback(async () => {
    try {
      const req = { username }
      const res = await snackbarMutation({
        mutation: otpDisable(req).unwrap(),
        getErrorMessage: buildGetErrorMessage(
          t("error.DISABLING_ITEM", {
            item: t("account_settings.TWO_FA").toLowerCase(),
          }),
        ),
        getSuccessMessage: () =>
          t("success.DISABLING_ITEM", {
            item: t("account_settings.TWO_FA"),
            count: 1,
            context: "female",
          }),
      }).then(() => {
        setOtpRegisterData(undefined)
        onClose()
      })
      return res
    } catch {
      // nothing
    }
  }, [onClose, otpDisable, setOtpRegisterData, t, username])

  return (
    <ConfirmationPopup
      open={open}
      onClose={onClose}
      description={t("account_settings.ARE_YOU_SURE_TO_DISABLE_2FA")}
      mainButtonText={t("generic.ACCEPT")}
      onConfirm={disableOtp}
      isLoading={isLoading}
    />
  )
}

export default OTPDisableModal
