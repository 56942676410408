import useDateRange from "helpers/hooks/useDateRange"

import AlertsContainer from "widgets/alerts/AlertContainer"
import { BodyContainer } from "widgets/styled/containers"

function EventsLog() {
  const { eventsDateRange, dispatchEventsDateRange, isEventsDateRangeValid } =
    useDateRange()
  return (
    <BodyContainer disableGutters maxWidth="xl">
      <AlertsContainer
        dateRange={eventsDateRange}
        dispatchDateRange={dispatchEventsDateRange}
        isDateRangeValid={isEventsDateRangeValid}
      />
    </BodyContainer>
  )
}

export default EventsLog
