import {
  Button,
  ListItemButton as MuiListItemButton,
  Chip as MuiChip,
} from "@mui/material"
import { alpha, styled } from "@mui/material/styles"

import { DEFAULT_THEME } from "helpers/utils/constants"
import type {
  IAccordionButtonProps,
  ISidebarListStyleButtonProps,
  IStyledChipProps,
} from "types/styled.types"

export const SidebarListStyleButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "open",
})<ISidebarListStyleButtonProps>(({ theme, active, open }) => ({
  ...(active && {
    color: theme.palette.secondary.main,
  }),
  paddingLeft: open ? "1rem" : 0,
  ...(!open && {
    paddingRight: 0,
  }),
  borderRadius: 1,
  justifyContent: open ? "flex-start" : "center",
  textAlign: "left",
  textTransform: "none",
  width: "100%",
  "&.Mui-selected": {
    backgroundColor: alpha(theme.palette.secondary.light, 0.03),
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.light, 0.07),
    },
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.light, 0.05),
  },
}))

export const SecondaryButton = styled(Button)(({ theme }) => ({
  color:
    theme.palette.mode === DEFAULT_THEME
      ? theme.palette.grey[300]
      : theme.palette.grey[600],
}))

export const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== "customColor",
})<IStyledChipProps>(({ customColor }) => ({
  ...(customColor && {
    "&.MuiChip-root": {
      "&:hover": {
        backgroundColor: alpha(customColor, 0.1),
      },
    },
  }),
  color: customColor,
  borderColor: customColor,
  fontSize: "0.7rem",
}))

export const TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "variant",
})<IAccordionButtonProps>(({ theme, variant }) => ({
  color: theme.palette.text.primary,
  borderColor: theme.palette.neutral[400],
  backgroundColor: variant === "contained" ? theme.palette.neutral[300] : "transparent",
  "&:hover": {
    backgroundColor: theme.palette.neutral[400],
    borderColor: theme.palette.neutral[400],
  },
}))

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  "&.Mui-selected": {
    backgroundColor: theme.palette.neutral[100],
    "&:hover": {
      backgroundColor: theme.palette.neutral[100],
    },
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.neutral[100] as string, 0.5),
  },
}))
