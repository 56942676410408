import { useState } from "react"
import type { FC } from "react"
import { Grid } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"

import type { OrgType } from "types/orgs.types"

import CardWithTitle from "widgets/common/CardWithTitle"
import Spinner from "../common/Spinner"
import NotificationSettingsForm from "./NotificationSettingsForm"
import TelephoneList from "./TelephoneList"
import EmailList from "./EmailList"

interface NotificationSettingsProps {
  org: OrgType
  isFetching: boolean
}

const NotificationSettings: FC<NotificationSettingsProps> = ({ org, isFetching }) => {
  const [isEditing, setIsEditing] = useState(false)
  const handleEditClick = () => {
    setIsEditing(true)
  }
  const handleClose = () => {
    setIsEditing(false)
  }

  return (
    <CardWithTitle
      titleKey={"device_settings.NOTIFICATION_SETTINGS"}
      isDoingAction={isEditing}
      handleAction={handleEditClick}
      actionIcon={<EditIcon fontSize="small" />}
    >
      {isFetching ? (
        <Spinner />
      ) : isEditing ? (
        <NotificationSettingsForm org={org} onClose={handleClose} />
      ) : (
        <Grid container gap={2}>
          <Grid item xs={12}>
            <TelephoneList telephones={org?.telephone_list || []} />
          </Grid>
          <Grid item xs={12}>
            <EmailList emails={org?.email_list || []} />
          </Grid>
        </Grid>
      )}
    </CardWithTitle>
  )
}

export default NotificationSettings
