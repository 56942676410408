import type { TFunction } from "i18next"
import { DateTime } from "luxon"
import type { Dictionary } from "lodash"
import fp from "lodash/fp"
import { Tooltip } from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import CheckIcon from "@mui/icons-material/Check"
import type { MUIDataTableColumnState, MUISortOptions } from "mui-datatables"

import type {
  DeviceWGroupsNStatesType,
  DevicesTableRow,
  IDeviceWRelsById,
  DeviceReportingStatus,
} from "types/device.types"
import { formatDateTime } from "helpers/utils/dateUtils"
import { hasDeviceTriggeredAlerts } from "helpers/utils/alerts"
import { defaultSort, getGroupsString } from "helpers/utils/tables"
import { compare } from "helpers/utils/common"
import type { IDefaultIdName } from "types/common.types"
import type { RecordType } from "types/dashboard.types"
import type { DeviceGroupRefType, DeviceTableAlertStatus } from "types/group.types"
import type {
  OrgAlertSetting,
  OrgAlertSettingState,
  OrgAlertSettingsStatesRes,
} from "types/orgs.types"
import CustomLink from "widgets/common/CustomLink"
import GroupsChips from "widgets/deviceGroups/GroupsChips"
import DeviceStatus from "widgets/device/DeviceStatus"
import { toSentenceCase } from "./translations"

/**
 * Business logic function, to obtain the reporting status of a device
 *
 * @param {DateTime?} datetime
 * @param {DateTime?} now
 * @returns {"reporting" | "active" | "stopped" | "inactive"} string value specifying device current reporting status depending on the time of the last message
 */

export const getDeviceReportingStatus = (
  datetime?: DateTime | number,
  now?: DateTime,
): DeviceReportingStatus => {
  if (datetime === undefined) {
    return "inactive"
  }
  if (typeof datetime === "number") {
    datetime = DateTime.fromMillis(datetime)
  }
  if (now === undefined) {
    now = DateTime.now()
  }
  if (now.minus({ minutes: 5 }) < datetime) {
    return "reporting"
  } else if (now.minus({ days: 2 }) < datetime) {
    return "active"
  } else if (now.minus({ months: 1 }) < datetime) {
    return "stopped"
  } else {
    return "inactive"
  }
}

export const getDevicesByStatus = (
  devsWRelsById: IDeviceWRelsById | undefined,
): RecordType[] => {
  const now = DateTime.now()
  const names = {
    reporting: "reporting_status.REPORTING",
    active: "reporting_status.ACTIVE",
    stopped: "reporting_status.STOPPED",
    inactive: "reporting_status.INACTIVE",
  }

  return fp.flow(
    fp.defaultTo({}),
    fp.values,
    fp.countBy(({ device }) =>
      getDeviceReportingStatus(device.last_message_timestamp, now),
    ),
    fp.defaults({ reporting: 0, active: 0, stopped: 0, inactive: 0 }),
    fp.toPairs,
    fp.map(([key, value]: [DeviceReportingStatus, number]) => ({
      colorKey: key,
      nameKey: names[key],
      value,
    })),
  )(devsWRelsById)
}

export const getDevicesByAlertStatus = (
  devsWRelsById: IDeviceWRelsById | undefined,
  allAlerts: OrgAlertSettingsStatesRes | undefined,
  orgAlertsBySettingId: { [key: string]: OrgAlertSetting },
): RecordType[] => {
  const isDeviceAlert = (alert: OrgAlertSettingState) => !!alert?.less_id
  const isActive = (alert: OrgAlertSettingState) =>
    !!orgAlertsBySettingId[alert.id]?.config?.active
  const isTriggered = (alert: OrgAlertSettingState) =>
    !!alert.state?.triggered && !alert.state?.silenced

  const triggeredDevicesAmount = fp.flow(
    fp.defaultTo([] as OrgAlertSettingsStatesRes),
    fp.filter(fp.allPass([isDeviceAlert, isActive, isTriggered])),
    fp.map("less_id"),
    fp.uniq,
    fp.size,
  )(allAlerts)
  const notTriggeredDevicesAmount = fp.size(devsWRelsById) - triggeredDevicesAmount
  return [
    {
      colorKey: "triggered",
      nameKey: "alerts.WITH_TRIGGERED",
      value: triggeredDevicesAmount,
    },
    {
      colorKey: "notTriggered",
      nameKey: "alerts.WITHOUT_TRIGGERED",
      value: notTriggeredDevicesAmount,
    },
  ]
}

export const getDeviceTableColumns = (baseOrgURL: string, t: TFunction) => {
  return [
    {
      name: "deviceId",
      label: t("device.DEVICE_ID"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: number) => {
          return (
            <CustomLink href={`${baseOrgURL}/devices/${value}`} hover bold>
              {value}
            </CustomLink>
          )
        },
      },
    },
    {
      name: "name",
      label: toSentenceCase(t("generic.DEVICE_NAME")),
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        customBodyRender: (value: { name: string; id: number }) => {
          return (
            <CustomLink href={`${baseOrgURL}/devices/${value.id}`} hover bold>
              {value.name}
            </CustomLink>
          )
        },
        sortCompare: (order: MUISortOptions["direction"]) => defaultSort(order),
      },
    },

    {
      name: "lastDataReceived",
      label: t("device_information.LAST_DATA_RECEIVED"),
      options: {
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        filter: false,
        sort: true,
      },
    },
    {
      name: "deviceType",
      label: t("device_information.DEVICE_TYPE"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "groups",
      label: toSentenceCase(t("device_groups.DEVICE_GROUPS")),
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "200px" } }),
        sortCompare: (order: MUISortOptions["direction"]) => {
          return (
            obj1: { data: DeviceGroupRefType[] },
            obj2: { data: DeviceGroupRefType[] },
          ) => {
            const obj1GroupString = getGroupsString(obj1?.data)
            const obj2GroupString = getGroupsString(obj2?.data)

            return order === "asc"
              ? obj1GroupString.localeCompare(obj2GroupString)
              : obj2GroupString.localeCompare(obj1GroupString)
          }
        },
        customBodyRender: (groups: DeviceGroupRefType[]) =>
          groups ? <GroupsChips groups={groups} /> : <></>,
      },
    },
    {
      name: "reportingStatus",
      label: t("device_information.STATUS"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: "reporting" | "active" | "stopped" | "inactive") => {
          return <DeviceStatus deviceStatus={value} />
        },
      },
    },
    {
      name: "alertStatus",
      label: t("device_information.ALERT_STATUS"),
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: () => {
          return <ErrorIcon aria-label="alert status" fontSize="small" />
        },
        sortCompare: (order: MUISortOptions["direction"]) => {
          const by =
            order === "asc"
              ? (o: DeviceTableAlertStatus) => [!o.status, o.lastDataReceived]
              : (o: DeviceTableAlertStatus) => [o.status, o.lastDataReceived]

          return (
            obj1: { data: DeviceTableAlertStatus },
            obj2: { data: DeviceTableAlertStatus },
          ) => {
            const a1 = by(obj1.data)
            const a2 = by(obj2.data)
            return compare(a2, a1)
          }
        },
        customBodyRender: (value: DeviceTableAlertStatus) => {
          return (
            <>
              {value.status ? (
                <Tooltip title={t("alerts.TRIGGERED_ALERT")}>
                  <ErrorIcon
                    fontSize="small"
                    sx={{ color: (theme) => theme.palette.error.main }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={t("alerts.NO_TRIGGERED_ALERTS")}>
                  <CheckIcon
                    fontSize="small"
                    sx={{
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  />
                </Tooltip>
              )}
            </>
          )
        },
      },
    },
  ]
}

export const getDevicesTableRows = (
  devices: DeviceWGroupsNStatesType[],
  orgAlertsBySettingId: { [key: string]: OrgAlertSetting },
) => {
  const now = DateTime.now()
  return devices?.map((device) => {
    const deviceObj = device?.device
    const groups = device?.groups
    const lastMessageDateTime = deviceObj?.last_message_timestamp
      ? DateTime.fromMillis(deviceObj?.last_message_timestamp)
      : undefined
    return {
      name: { name: deviceObj?.name, id: deviceObj?.less_id },
      deviceId: deviceObj?.less_id,
      lastDataReceived: formatDateTime(lastMessageDateTime),
      deviceType: deviceObj?.device_type,
      reportingStatus: getDeviceReportingStatus(lastMessageDateTime, now),
      alertStatus: device.settingsStates
        ? {
            status: hasDeviceTriggeredAlerts(device.settingsStates, orgAlertsBySettingId),
            lastDataReceived: lastMessageDateTime,
          }
        : { status: false, lastDataReceived: lastMessageDateTime },
      groups,
    }
  })
}

export const buildDevicesSearch = (columnsIndexes: Dictionary<number>, t: TFunction) => {
  const getSearchInGroups = (text: string, row: DevicesTableRow) => {
    if (columnsIndexes.groups === undefined) {
      return []
    }
    const groups = row[columnsIndexes.groups] as IDefaultIdName[]
    return groups.filter((group) => group.name.toLowerCase().includes(text))
  }
  const getSearchInName = (text: string, row: DevicesTableRow) => {
    if (columnsIndexes.name === undefined) {
      return false
    }
    const deviceName = row[columnsIndexes.name] as IDefaultIdName
    return deviceName.name.toLowerCase().includes(text)
  }
  const getSearchInReportingStatus = (text: string, row: DevicesTableRow) => {
    if (columnsIndexes.reportingStatus === undefined) {
      return false
    }

    const reportingStatus = row[columnsIndexes.reportingStatus] as string
    const reportingStatusTranslated = t(
      `reporting_status.${reportingStatus.toUpperCase()}`,
    )
    return reportingStatusTranslated.toLowerCase().includes(text)
  }

  const devicesCustomSearch = (
    searchQuery: string,
    currentRow: DevicesTableRow,
    _columns: MUIDataTableColumnState[],
  ): boolean => {
    const searchText = searchQuery.toLowerCase()

    const searchInGroups = getSearchInGroups(searchText, currentRow)
    const searchInName = getSearchInName(searchText, currentRow)
    const searchInReportingStatus = getSearchInReportingStatus(searchText, currentRow)

    const isFound = currentRow.some(
      (col) =>
        col.toString().toLowerCase().includes(searchText) ||
        searchInGroups.length ||
        searchInName ||
        searchInReportingStatus,
    )
    return isFound
  }

  return devicesCustomSearch
}

export const formatDevicesTableCsv = (
  data: { index: number; data: DevicesTableRow }[],
  columnsIndexes: Dictionary<number>,
  t: TFunction,
) => {
  return data?.map((row, index) => ({
    index,
    data: row?.data?.map((field: any, index) => {
      // field can be any of the types of DevicesTableRows
      // array order comes from columns definition
      switch (index) {
        case columnsIndexes.groups:
          return field.map((f: IDefaultIdName) => f.name).join(" - ")
        case columnsIndexes.alertStatus:
          return field.status ? t("alerts.TRIGGERED") : t("alerts.NOT_TRIGGERED")
        case columnsIndexes.name:
          return field.name
        case columnsIndexes.reportingStatus:
          return t(`reporting_status.${field.toUpperCase()}`)
        default:
          return field
      }
    }),
  }))
}

/**
 * Function that receives a version and a target and checks if the version is above or equal to the target version
 * @param {string} version
 * @param {string} target
 * @returns {boolean}
 */

export const isVersionAboveOrEqual = (version: string, target: string): boolean => {
  if (!version) return false
  const [vMajor, vMinor] = version.split(".").map(Number)
  const [tMajor, tMinor] = target.split(".").map(Number)

  return vMajor > tMajor || (vMajor === tMajor && vMinor >= tMinor)
}

/**
 * Function that receives a deviceType and checks if it's a virtual_powerline or virtual_span
 * @param {string} deviceType
 * @returns {boolean}
 */

export const isVirtualDevice = (deviceType: string) => {
  return deviceType === "virtual_span" || deviceType === "virtual_powerline"
}
