import { useMemo } from "react"
import fp from "lodash/fp"

import type { DateReference } from "types/date.types"

const useSelectOptions = () => {
  const dateRefOptions = useMemo(
    () => [
      {
        value: "lastTrigger",
        time: { timeAmount: 0, timeUnit: "" },
        label: "alerts.LAST_TRIGGER",
      },
      {
        value: "lastHour",
        time: { timeAmount: 1, timeUnit: "hours" },
        label: "generic.LAST_HOUR",
      },
      {
        value: "lastThreeHours",
        time: { timeAmount: 3, timeUnit: "hours" },
        label: "generic.LAST_3_HOURS",
      },
      {
        value: "lastTwelveHours",
        time: { timeAmount: 12, timeUnit: "hours" },
        label: "generic.LAST_12_HOURS",
      },
      {
        value: "lastDay",
        time: { timeAmount: 1, timeUnit: "days" },
        label: "generic.LAST_DAY",
      },
      {
        value: "lastWeek",
        time: { timeAmount: 7, timeUnit: "days" },
        label: "generic.LAST_WEEK",
      },
      {
        value: "lastMonth",
        time: { timeAmount: 1, timeUnit: "months" },
        label: "generic.LAST_MONTH",
      },
      {
        value: "lastQuarter",
        time: { timeAmount: 3, timeUnit: "months" },
        label: "generic.LAST_QUARTER",
      },
      {
        value: "lastYear",
        time: { timeAmount: 1, timeUnit: "years" },
        label: "generic.LAST_YEAR",
      },
      {
        value: "custom",
        time: { timeAmount: 0, timeUnit: "" },
        label: "generic.TW_CUSTOM",
      },
    ],
    [],
  )

  const deviceSelectOptions = useMemo(
    () => dateRefOptions.filter((opt) => opt.value !== "lastTrigger"),
    [dateRefOptions],
  )
  const triggeredAccordionSelectOptions = useMemo(
    () => dateRefOptions.filter((opt) => opt.value !== "custom"),
    [dateRefOptions],
  )
  const notTriggeredAccordionSelectOptions = useMemo(
    () =>
      dateRefOptions.filter(
        (opt) => opt.value !== "custom" && opt.value !== "lastTrigger",
      ),
    [dateRefOptions],
  )

  // nit: Could do with the same format as selectOptions
  const dateReferences: { [key: string]: DateReference } = useMemo(() => {
    const references: DateReference[] = dateRefOptions.map(
      ({ value, time }): DateReference => ({
        name: value,
        timeAmount: time.timeAmount,
        timeUnit: time.timeUnit,
      }),
    )
    return fp.indexBy(({ name }: DateReference) => name)(references)
  }, [dateRefOptions])

  return {
    dateRefOptions,
    deviceSelectOptions,
    triggeredAccordionSelectOptions,
    notTriggeredAccordionSelectOptions,
    dateReferences,
  }
}

export default useSelectOptions
