import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Grid, Stack, Tooltip, useTheme } from "@mui/material"
import InfoOutlined from "@mui/icons-material/InfoOutlined"

import useLimitedEvents from "helpers/hooks/useLimitedEvents"
import useSelectOptions from "helpers/hooks/useSelectOptions"
import { DATE_RANGE_TIME } from "helpers/utils/constants"
import type { AccordionAlertSetting } from "types/alerts.types"
import AlertChart from "widgets/alerts/AlertChart"
import AlertsContext from "widgets/alerts/AlertsContext"
import EventsTable from "widgets/alerts/EventsTable"
import TabButtonGroup from "widgets/common/TabButtonGroup"
import { BodyContainer } from "widgets/styled/containers"
import AccordionSelect from "widgets/dates/AccordionSelect"
import { setAccordionDateRange } from "widgets/dates/helpers"

interface AlertAccordionDetailsContentProps {
  alert: AccordionAlertSetting
}

const AlertAccordionDetailsContent = ({ alert }: AlertAccordionDetailsContentProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const TAB_BUTTONS = [
    { name: "generic.EVENTS", value: 0 },
    { name: "alerts.CHART", value: 1 },
  ]
  const [tab, setTab] = useState(TAB_BUTTONS[0].value)
  const activeEvents = tab === TAB_BUTTONS[0].value
  const activeChart = tab === TAB_BUTTONS[1].value
  const { multipleDateRange, dispatchMultipleDateRange } = useContext(AlertsContext)
  const { events, isLoading } = useLimitedEvents({ settingId: alert.id })
  const { dateReferences } = useSelectOptions()
  const isTriggered = useMemo(() => alert.state !== "notTriggered", [alert.state])
  const areTabsAllowed = useMemo(() => alert.type !== "deadman", [alert.type])
  const alertInfo = useMemo(() => {
    return { id: alert.id, triggeredAt: alert.triggered_at }
  }, [alert.id, alert.triggered_at])

  const dateRange = useMemo(
    () => multipleDateRange[alert.id],
    // update only when this accordion date range changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alert.id, multipleDateRange[alert.id]],
  )

  const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)
  const updateDateReference = useCallback(
    (dateReferenceName) => {
      interval.current && clearInterval(interval.current)
      interval.current = undefined
      setAccordionDateRange(
        alertInfo,
        dateReferenceName,
        dateReferences,
        dispatchMultipleDateRange,
      )
      interval.current = setInterval(() => {
        setAccordionDateRange(
          alertInfo,
          dateReferenceName,
          dateReferences,
          dispatchMultipleDateRange,
        )
      }, DATE_RANGE_TIME)
    },
    [alertInfo, dateReferences, dispatchMultipleDateRange],
  )

  useEffect(() => {
    // Set initial context value
    const dateReferenceName = alert.state === "notTriggered" ? "lastWeek" : "lastTrigger"
    updateDateReference(dateReferenceName)
    // This is only updated after the first render.
    // Intentionally not re-evaluating on update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      interval.current && clearInterval(interval.current)
    }
  }, [interval])

  return (
    <>
      {dateRange && (
        <BodyContainer disableGutters maxWidth="xl" sx={{ padding: "1rem" }}>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              {areTabsAllowed && (
                <TabButtonGroup buttons={TAB_BUTTONS} tab={tab} setTab={setTab} />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              {activeChart && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ [theme.breakpoints.up("sm")]: { justifyContent: "end" } }}
                >
                  <AccordionSelect
                    setSelectedDateReference={updateDateReference}
                    dateReferenceName={dateRange.range}
                    alertState={alert.state}
                  />
                  {isTriggered && (
                    <Tooltip title={t("alerts.LAST_TRIGGER_INFO")}>
                      <Stack m={1}>
                        <InfoOutlined color="primary" />
                      </Stack>
                    </Tooltip>
                  )}
                </Stack>
              )}
            </Grid>
          </Grid>
          <Stack mt={"1.5rem"}>
            {activeEvents && <EventsTable events={events} isLoading={isLoading} />}
            {activeChart && <AlertChart alert={alert} />}
          </Stack>
        </BodyContainer>
      )}
    </>
  )
}

export default AlertAccordionDetailsContent
