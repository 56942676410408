import { useSelector } from "react-redux"
import { Grid } from "@mui/material"

import { selectCurrentOrgName } from "features/store/orgSlice"
import Title from "widgets/common/Title"
import DevicesStatusPieChart from "./DevicesStatusPieChart"
import DevicesAlertStatusPieChart from "./DevicesAlertStatusPieChart"

const OrgPieCharts = () => {
  const orgName = useSelector(selectCurrentOrgName)

  return (
    <>
      <Title text={String(orgName)} styles={{ marginBottom: "1rem" }} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <DevicesStatusPieChart />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <DevicesAlertStatusPieChart />
        </Grid>
      </Grid>
    </>
  )
}

export default OrgPieCharts
