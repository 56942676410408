import { useEffect, useState } from "react"
import { DateTime } from "luxon"
import { Stack, Typography, useTheme } from "@mui/material"
import LineChartComponent from "../../widgets/plots/recharts/LineChartComponent"
import { VerticalBarChart } from "../../widgets/plots/recharts/VerticalBarChart"
import { ArrowDot } from "../../widgets/plots/recharts/components/ArrowDot"

const series = [
  {
    name: "Series 0",
    data: [
      { category: 1680001400000, value: 22 },
      { category: 1680111400156, value: 23 },
      { category: 1680151400300, value: 25 },
    ],
  },
  {
    name: "Series 1",
    data: [
      { category: 1680271400000, value: 15 },
      { category: 1680371400156, value: 23 },
      { category: 1680471400300, value: 20 },
      { category: 1680571400600, value: 22 },
      { category: 1680671400800, value: 18 },
      { category: 1680771101000, value: 20 },
      { category: 1680871401300, value: 15 },
      { category: 1680971101500, value: 8 },
      { category: 1681071101800, value: 9 },
    ],
  },
  {
    name: "Series 2",
    data: [
      { category: 1680271400000, value_alternative: 25 },
      { category: 1680371400156, value_alternative: 20 },
      { category: 1680471400300, value_alternative: 10 },
      { category: 1680571400600, value_alternative: 5 },
      { category: 1680671400800, value_alternative: 8 },
      { category: 1680771101000, value_alternative: 0 },
      { category: 1680871401300, value_alternative: 5 },
      { category: 1680971101500, value_alternative: 18 },
      { category: 1681071101800, value_alternative: 19 },
    ],
  },
]

const serieCustomDot = [
  {
    name: "Series 0",
    data: [
      {
        name: 1680011400000,
        wind: 2400,
        ang: 40,
      },
      {
        name: 1680021400000,
        wind: 1398,
        ang: -20,
      },
      {
        name: 1680031400000,
        wind: 5000,
        ang: 60,
      },
      {
        name: 1680041400000,
        wind: 3908,
        ang: 40,
      },
      {
        name: 1680051400000,
        wind: 4800,
        ang: 70,
      },
      {
        name: 1680061400000,
        wind: 3800,
        ang: 100,
      },
      {
        name: 1680071400000,
        wind: 4300,
        ang: 60,
      },
    ],
  },
]

// const series1 = [
//   {
//     name: "moves",
//     severity: "high",
//     from_date: 1680001400000,
//     to_date: 1680111400156,
//   },
//   {
//     name: "moves",
//     severity: "medium",
//     from_date: 1680571400600,
//     to_date: 1680671400800,
//   },
//   {
//     name: "moves",
//     severity: "medium",
//     from_date: 1680671400800,
//     to_date: 1680771101000,
//   },
//   {
//     name: "moves",
//     severity: "high",
//     from_date: 1680771101000,
//     to_date: 1680871401300,
//   },
//   {
//     name: "wind",
//     severity: "high",
//     from_date: 1680871401300,
//     to_date: 1680471400300,
//   },
//   {
//     name: "wind",
//     severity: "medium",
//     from_date: 1680471400300,
//     to_date: 1680472400300,
//   },
//   {
//     name: "wind",
//     severity: "high",
//     from_date: 1680771101000,
//     to_date: 1680871401300,
//   },
//   {
//     name: "break",
//     severity: "high",
//     from_date: 1680971101500,
//     to_date: 1681071101800,
//   },
//   {
//     name: "break",
//     severity: "high",
//     from_date: 1680981101500,
//     to_date: 1680981500000,
//   },
// ]

/**
 * Example of how the chart should receive the data
 */
/**
 * !important
 *
 * The name of each attribute is a way I could find to pass the values
 * and also other data such as "warning type" and "severity"
 */

const data1 = [
  {
    name: "moves",
    medium_moves_1: [1680001400000, 1680001400000],
    medium_moves_2: [1680003400000, 1680004400000],
    medium_moves_3: [1680014400000, 1680024400000],
    medium_moves_4: [1680124400000, 1680134400000],
    high_moves_1: [1680671400800, 1680685101000],
    medium_moves_5: [1680685101000, 1680687101000],
    medium_moves_6: [1680787101000, 1680787551000],
    high_moves_2: [1680977101000, 1680977551000],
  },
  {
    name: "wind",
    high_wind_0: [1680111400156, 1680121500156],
    medium_wind_0: [1680121500156, 1680141400156],
    high_wind_1: [1680145400156, 1680148400156],
    medium_wind_1: [1680148400156, 1680160400156],
    medium_wind_2: [1680325400156, 1680345400156],
    high_wind_2: [1680345400156, 1680388400156],
    medium_wind_3: [1680471400300, 1680571400300],
    high_wind_3: [1680571400300, 1680591400300],
    medium_wind_4: [1680591400300, 1680601400300],
    medium_wind_5: [1680791400300, 1680802400300],
  },
  {
    name: "break",
    medium_break_0: [1680771400300, 1680782400300],
    high_break_1: [1680977101000, 1680977551000],
  },
]

const data2 = [
  {
    name: "operation_mode",
    active_sleep_1: [1680001400000, 1680100400000],
    low_active_1: [1680100400000, 1680400400000],
    medium_save_1: [1680400400000, 1680550400000],
    high_inactive_2: [1680550400000, 1680580400000],
    low_active_2: [1680580400000, 1680855101000],
    medium_save_3: [1680855101000, 1680900101000],
    low_active_3: [1680900101000, 1680981500000],
  },
]

function PlotsNew() {
  const theme = useTheme()
  const [showPage, setShowPage] = useState(false)

  useEffect(() => {
    const onKeyDownHandler = (event) => {
      setShowPage(event.key === "s" || event.key === "S")
    }

    document.addEventListener("keydown", onKeyDownHandler)
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler)
    }
  }, [])

  if (!series) {
    return <>Loading</>
  }

  return (
    <>
      {showPage && (
        <>
          <Typography variant="h3">LINE CHARTS</Typography>
          <br />
          <br />
          <br />
          <Stack height="350px" width="100%">
            <LineChartComponent
              data={serieCustomDot}
              XAxisName="name"
              customDot={<ArrowDot />}
              lineNames={[{ key: "wind", stroke: theme.palette.primary.main }]}
              dateRange={{
                fromDate: DateTime.fromMillis(1680001400000),
                toDate: DateTime.fromMillis(1681071101800),
              }}
            />
          </Stack>
          <br />
          <br />
          <br />
          <Stack height="350px" width="100%">
            <LineChartComponent
              data={series}
              title="ground_clearance_plot"
              XAxisName="category"
              lineNames={[
                { key: "value_alternative", stroke: theme.palette.secondary.main },
                { key: "value", stroke: theme.palette.primary.main },
              ]}
              dateRange={{
                fromDate: DateTime.fromMillis(1680011400000),
                toDate: DateTime.fromMillis(1680071400000),
              }}
              background={[
                {
                  bottom: 18,
                  top: undefined,
                  color: theme.palette.secondary.main,
                },
                { bottom: 10, top: 18, color: theme.palette.warning.main },
                { bottom: undefined, top: 10, color: theme.palette.error.main },
              ]}
            />
          </Stack>
          <br />
          <br />
          <br />

          <br />
          <br />
          <br />
          <Typography variant="h3">BAR CHARTS</Typography>
          <br />
          <br />
          <br />
          <Stack height="350px" width="100%">
            <VerticalBarChart data={data1} title="fast_incident_detection_plot" />
          </Stack>
          <br />
          <br />
          <br />
          <Stack height="190px" width="100%">
            <VerticalBarChart
              data={data2}
              hideTick
              hideHorizontalCartesianLines
              title="operation_mode_plot"
              barGap={0}
            />
          </Stack>
        </>
      )}
    </>
  )
}

export default PlotsNew
