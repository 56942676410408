import { styled } from "@mui/material"
import { MaterialDesignContent } from "notistack"

import type { IDefaultStyledProps } from "types/styled.types"

declare module "notistack" {
  interface VariantOverrides {
    triggered: true
    renotification: true
  }
}

export const StyledMaterialDesignContent = styled(
  MaterialDesignContent,
)<IDefaultStyledProps>(({ theme }) => ({
  "&.notistack-MuiContent": {
    [theme.breakpoints.up("sm")]: {
      minWidth: "400px",
      width: "400px",
    },
    display: "flex",
    flexWrap: "nowrap",
    fontSize: theme.typography.body1.fontSize,
  },
  "&.notistack-MuiContent-success": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.success.dark
        : theme.palette.success.light,
    color: theme.palette.text.primary,
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.warning.dark
        : theme.palette.warning.light,
    color: theme.palette.text.primary,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.error.dark
        : theme.palette.error.light,
    color: theme.palette.text.primary,
  },
  "&.notistack-MuiContent-info": {
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.info.dark : theme.palette.info.light,
    color: theme.palette.text.primary,
  },
  "&.notistack-MuiContent-triggered": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.error.dark
        : theme.palette.error.light,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
      width: "600px",
    },
  },
  "&.notistack-MuiContent-renotification": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.warning.dark
        : theme.palette.warning.light,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
      width: "600px",
    },
  },
}))
