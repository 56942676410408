import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import PersonSearchIcon from "@mui/icons-material/PersonSearch"

const UserHomepage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          height: "100vh",
          maxHeight: "400px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PersonSearchIcon sx={{ opacity: 0.5 }} fontSize="large" />
        <Box>
          <Typography sx={{ opacity: 0.5 }}>
            {t("organization.NO_ORGANIZATION")}
          </Typography>
        </Box>
      </Stack>
    </>
  )
}

export default UserHomepage
