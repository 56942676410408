import { useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useUserOTPRecoverGenerateMutation } from "features/api"
import { SectionCard } from "widgets/styled/containers"
import RecoveryCodesConfig from "widgets/user/RecoveryCodesConfig"
import TwoFactorAuthConfig from "widgets/user/TwoFactorAuthConfig"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import { infoMessage } from "helpers/utils/snackbars"
import { updateOtpCode, updateToken } from "features/store/authSlice"

import type { UserOTPRecoverCodes, UserType } from "types/users.types"

const AccountSecurity = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user, isLoadingUser, isErrorUser } = useOutletContext<{
    user: UserType | undefined
    isLoadingUser: boolean
    isErrorUser: boolean
  }>()
  const [openRegisterModal, setOpenRegisterModal] = useState(false)
  const [openRecoverCodesModal, setOpenRecoverCodesModal] = useState(false)

  const [otpRecoverCodes, setOtpRecoverCodes] = useState<UserOTPRecoverCodes | undefined>(
    undefined,
  )

  const [otpRecoverGenerate, { isLoading: isGenerating }] =
    useUserOTPRecoverGenerateMutation()

  const { username, enabled, remainingRecoveryCodes } = useMemo(() => {
    const enabled = user?.otp?.enabled ?? false
    const remainingCodes = user?.otp?.remaining_recovery_codes ?? 0
    return {
      username: user?.username ?? "",
      enabled,
      remainingRecoveryCodes: enabled && remainingCodes ? remainingCodes : 0,
    }
  }, [user])

  const generateRecoverCodes = async () => {
    infoMessage(t("account_settings.GENERATING_RECOVERY_CODES"), 10000)
    const req = { username }
    const mutation = otpRecoverGenerate(req).unwrap()
    await snackbarMutation({
      mutation,
      getErrorMessage: buildGetErrorMessage(
        t("error.CREATING_ITEM", {
          item: t("account_settings.RECOVERY_CODES").toLowerCase(),
        }),
      ),
      getSuccessMessage: () =>
        t("success.CREATING_ITEM", {
          item: t("account_settings.RECOVERY_CODES"),
          count: 2,
          context: "male",
        }),
    }).then((res) => {
      setOtpRecoverCodes(res.recovery_codes)
      dispatch(updateOtpCode({ otpCode: res.otp_code }))
      dispatch(updateToken({ token: res.access_token }))
      setOpenRecoverCodesModal(true)
      setOpenRegisterModal(false)
    })
  }

  const handleRecoverCodesClose = () => {
    setOpenRecoverCodesModal(false)
  }

  return (
    <SectionCard>
      <TwoFactorAuthConfig
        user={{ username, enabled, isLoadingUser, isErrorUser }}
        registerModal={{ openRegisterModal, setOpenRegisterModal }}
        onComplete={generateRecoverCodes}
        isGenerating={isGenerating}
      />
      <RecoveryCodesConfig
        user={{ username, enabled, remainingRecoveryCodes, isLoadingUser, isErrorUser }}
        recoverCodesModal={{ openRecoverCodesModal, handleRecoverCodesClose }}
        generateRecoverCodes={generateRecoverCodes}
        otpRecoverCodes={otpRecoverCodes}
        isGenerating={isGenerating}
      />
    </SectionCard>
  )
}

export default AccountSecurity
