import * as PropTypes from "prop-types"
import { Plot } from "@influxdata/giraffe"
import Container from "@mui/material/Container"

import useGiraffeConfig from "./configuration/useGiraffeConfig"

const GiraffePlot = ({ view, data, recordService }) => {
  const config = useGiraffeConfig({ view, data, recordService })
  return (
    <>
      {config && (
        <Container
          maxWidth="xl"
          sx={{
            width: "inherit",
            height: "inherit",
            ...config.containerStyles,
          }}
        >
          <Plot config={config.configuration} />
        </Container>
      )}
    </>
  )
}

GiraffePlot.propTypes = {
  view: PropTypes.object,
  data: PropTypes.array,
  recordService: PropTypes.object,
}

export default GiraffePlot
