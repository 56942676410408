import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Box, Grid } from "@mui/material"

import { selectCurrentOrgId } from "features/store/orgSlice"
import useOrgs from "helpers/hooks/useOrgs"
import EditableOrgName from "widgets/organization/EditableOrgName"
import NotificationSettings from "widgets/organization/NotificationSettings"
import OrgUsers from "widgets/organization/OrgUsers"
import AuthenticationSettings from "widgets/organization/AuthenticationSettings"

function OrgSettings() {
  const { t } = useTranslation()
  const currentOrgId = useSelector(selectCurrentOrgId)
  const { getOrgById, orgsFetching } = useOrgs()

  const currentOrg = useMemo(() => getOrgById(currentOrgId), [currentOrgId, getOrgById])

  return (
    <>
      <Box mb={1}>
        {t("organization.ORGANIZATION_SETTINGS")}
        <EditableOrgName name={currentOrg?.name} />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          {currentOrg && (
            <NotificationSettings org={currentOrg} isFetching={orgsFetching} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          {currentOrg && <AuthenticationSettings org={currentOrg} />}
        </Grid>
        <Grid item xs={12}>
          <OrgUsers orgId={currentOrgId} />
        </Grid>
      </Grid>
    </>
  )
}

export default OrgSettings
