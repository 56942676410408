import { useCallback, useEffect, type Dispatch, type SetStateAction } from "react"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Stack } from "@mui/material"
import WarningIcon from "@mui/icons-material/Warning"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import AdjustIcon from "@mui/icons-material/Adjust"

import type { AlertsAmount, AlertsFilterOptions } from "types/alerts.types"
import IconTextStack from "widgets/common/IconTextStack"
import FilterSelect from "widgets/alerts/FilterSelect"
import { initializeAlertsFilters, updateAlertsFilters } from "./helpers"

interface AlertsFilterProps {
  alertsAmount: AlertsAmount
  filters: AlertsFilterOptions | null
  setFilters: Dispatch<SetStateAction<AlertsFilterOptions | null>>
}

const AlertsFilter = ({ alertsAmount, filters, setFilters }: AlertsFilterProps) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    initializeAlertsFilters(searchParams, setSearchParams, setFilters)
    // This is only updated after the first render.
    // Intentionally not re-evaluating on update.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateFilters = useCallback(
    (filters: AlertsFilterOptions) => {
      updateAlertsFilters(filters, setSearchParams, setFilters)
    },
    [setFilters, setSearchParams],
  )

  return (
    <Stack direction={"row"} justifyContent={"space-between"} mb={1} flexWrap={"wrap"}>
      {filters && (
        <>
          <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
            {filters.open && (
              <IconTextStack
                icon={
                  <WarningIcon
                    color="error"
                    fontSize="small"
                    data-testid={"open-summary"}
                  />
                }
                text={`${alertsAmount.open} ${t("alerts.OPEN_ALERTS")}`}
              />
            )}
            {filters.silenced && (
              <IconTextStack
                icon={
                  <NotificationsOffIcon
                    color="warning"
                    fontSize="small"
                    data-testid={"silenced-summary"}
                  />
                }
                text={`${alertsAmount.silenced} ${t("alerts.SILENCED_ALERTS")}`}
              />
            )}
            {filters.notTriggered && (
              <IconTextStack
                icon={
                  <AdjustIcon
                    color="action"
                    fontSize="small"
                    data-testid={"not-triggered-summary"}
                  />
                }
                text={`${alertsAmount.notTriggered} ${t("alerts.NOT_TRIGGERED")}`}
              />
            )}
          </Stack>
          <FilterSelect filters={filters} updateFilters={updateFilters} />
        </>
      )}
    </Stack>
  )
}

export default AlertsFilter
