import { useState } from "react"
import { useTranslation } from "react-i18next"
import Snackbar from "@mui/material/Snackbar"
import InputAdornment from "@mui/material/InputAdornment"
import OutlinedInput from "@mui/material/OutlinedInput"
import IconButton from "@mui/material/IconButton"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { Stack } from "@mui/material"

import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import CardWithTitle from "widgets/common/CardWithTitle"
import { BodyContainer } from "widgets/styled/containers"
import { usePermanentTokenMutation } from "features/api"
import Spinner from "widgets/common/Spinner"

const CreateToken = () => {
  const { t } = useTranslation()
  const [showTokenField, setShowTokenField] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [getToken, { isLoading, isSuccess }] = usePermanentTokenMutation()
  const [token, setToken] = useState<string>()

  const handleCopyToken = async () => {
    token && (await navigator.clipboard.writeText(token))
    setShowMessage(true)
  }

  const handleCreateToken = () => {
    setShowTokenField(false)
    snackbarMutation({
      mutation: getToken().unwrap(),
      getErrorMessage: buildGetErrorMessage(t("error.CREATING_ITEM", { item: "Token" })),
    })
      .then((res) => {
        setToken(res.token)
        setShowTokenField(true)
      })
      .catch()
  }

  return (
    <BodyContainer disableGutters maxWidth="xl" sx={{ pt: 2 }}>
      <CardWithTitle titleKey={"api.CREATE_TOKEN"}>
        <Stack sx={{ alignItems: "start" }}>
          <Typography variant="body2" component="p" sx={{ pb: 1 }}>
            {t("api.TOKEN_WARNING")}
          </Typography>
          {isLoading ? (
            <Spinner
              disableGutters
              styles={{ width: "fit-content", margin: 0, marginBottom: 2 }}
              size="1.5rem"
            />
          ) : (
            showTokenField &&
            isSuccess && (
              <OutlinedInput
                id="token"
                type={"text"}
                size="small"
                value={token}
                disabled
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="copy token"
                      onClick={handleCopyToken}
                      edge="end"
                      size="small"
                    >
                      <ContentCopyIcon />
                    </IconButton>
                    <Snackbar
                      message={t("api.COPIED_TO_CLIPBOARD")}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={2000}
                      onClose={() => setShowMessage(false)}
                      open={showMessage}
                    />
                  </InputAdornment>
                }
                label="Token"
                sx={{
                  width: {
                    xxs: "80%",
                    xs: "70%",
                    lg: "50%",
                  },
                  mb: 1,
                }}
              />
            )
          )}
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => handleCreateToken()}
          >
            {t("api.CREATE_NEW_TOKEN")}
          </Button>
        </Stack>
      </CardWithTitle>
    </BodyContainer>
  )
}

export default CreateToken
