import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { Dialog, Stack, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import CardWithTitle from "widgets/common/CardWithTitle"
import ControlledInput from "widgets/common/ControlledInput"
import { USER_ROLES } from "helpers/utils/constants"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import { useUpdateOrgUserMutation } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import FormButtons from "widgets/common/FormButtons"

interface IEditUserForm {
  username: string
  role: keyof typeof USER_ROLES
}

interface EditUserModalProps {
  open: boolean
  onClose: () => void
  username: string
  role: keyof typeof USER_ROLES
}

const EditUserModal = ({ open, onClose, username, role }: EditUserModalProps) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const methods = useForm<IEditUserForm>({
    mode: "all",
    defaultValues: {
      username,
      role,
    },
  })

  useEffect(() => {
    methods.reset({ role })
  }, [methods, role])

  const [updateUser, { isLoading: isUpdating }] = useUpdateOrgUserMutation()

  const roles = useMemo(
    () =>
      Object.entries(USER_ROLES).map(([key, value]) => ({
        value,
        label: t(`roles.${key.toUpperCase()}`, key),
      })),
    [t],
  )

  const onSubmit = async ({ role }: IEditUserForm) => {
    snackbarMutation({
      mutation: updateUser({
        username,
        orgId: orgId as number,
        role,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.UPDATING_ITEM", { item: t("generic.USER") }),
      ),
      getSuccessMessage: () => t("users.USER_UPDATED"),
    })
      .then(() => {
        methods.reset()
        onClose()
      })
      .catch()
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        methods.reset()
        onClose()
      }}
      fullWidth
      maxWidth={"sm"}
    >
      <CardWithTitle
        titleKey="users.EDIT_USER"
        handleAction={() => {
          methods.reset()
          onClose()
        }}
        actionIcon={<CloseIcon fontSize="small" color="action" />}
      >
        <Stack>
          <Typography variant="h6" mb={3} align="center">
            {username}
          </Typography>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <ControlledInput
                type="select"
                placeholder=""
                name="role"
                label={t("generic.ROLE")}
                selectItems={roles}
                variant="outlined"
              />
              <FormButtons
                onClose={() => {
                  methods.reset()
                  onClose()
                }}
                buttonText={"generic.UPDATE"}
                isLoading={isUpdating}
              />
            </FormProvider>
          </form>
        </Stack>
      </CardWithTitle>
    </Dialog>
  )
}

export default EditUserModal
