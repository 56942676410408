import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"

import { LogoContainer } from "widgets/styled/containers"
import { useValidateOAuthMutation } from "../../features/api"
import { setCredentials } from "../../features/store/authSlice"
import { DEFAULT_TARGET, IMAGOTYPE } from "../../helpers/utils/constants"
import CustomLink from "../../widgets/common/CustomLink"
import type { LocationState } from "../../types/locationState.types"

function LoginOAuth() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useMemo(() => {
    return new URLSearchParams(window.location.search)
  }, [])

  const locationState = location?.state
    ? (location.state as LocationState)
    : ({} as LocationState)

  const [validateOAuth, { isLoading }] = useValidateOAuthMutation()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const sendOAuthData = useCallback(async () => {
    const oAuthData = {
      code: urlParams.get("code") || "",
      state: urlParams.get("state") || "",
    }
    validateOAuth(oAuthData)
      .unwrap()
      .then((user) => {
        dispatch(
          setCredentials({
            user: user.username,
            token: user.access_token,
            globalRole: user.role,
          }),
        )
        const target = locationState.redirectedFrom ?? DEFAULT_TARGET
        navigate(target, { state: { redirectedFrom: location.pathname } })
      })
      .catch((error) => {
        const message =
          error.status === 404 || error.status === 401 || !error.data
            ? t("login.OAUTH_VALIDATION_FAILED")
            : error.data.message
        setErrorMessage(message)
      })
  }, [
    dispatch,
    location.pathname,
    locationState.redirectedFrom,
    navigate,
    t,
    urlParams,
    validateOAuth,
  ])

  useEffect(() => {
    sendOAuthData()
  }, [sendOAuthData])

  return (
    <Card
      sx={{ maxWidth: 360, minWidth: 280, width: "50%", textAlign: "center" }}
      elevation={4}
    >
      <LogoContainer disableGutters>
        <Box component="img" src={IMAGOTYPE} sx={{ width: "60%" }} />
      </LogoContainer>
      <CardContent sx={{ p: 4 }}>
        {isLoading && (
          <Typography variant="body2" component="p" align="center" sx={{ mb: 2, mt: 1 }}>
            {t("login.VALIDATING_DATA")}
          </Typography>
        )}
        {errorMessage && (
          <Typography
            variant="body2"
            component="p"
            align="center"
            color="error"
            sx={{ mb: 2, mt: 1 }}
          >
            {errorMessage}
          </Typography>
        )}
        <CustomLink
          href="/accounts/login"
          underlineHover
          variant="body2"
          styles={{ mt: 4 }}
        >
          {t("generic.RETURN_TO") + t("login.LOGIN_PAGE")}
        </CustomLink>
      </CardContent>
    </Card>
  )
}

export default LoginOAuth
