import Container from "@mui/material/Container"
import { useTranslation } from "react-i18next"
import { useMemo } from "react"

import ApiDocEn from "./ApiDoc.en"
import ApiDocEs from "./ApiDoc.es"

export default function APIDoc() {
  const { i18n } = useTranslation()
  const userLanguage = i18n.resolvedLanguage

  const urlData = useMemo(
    () => ({
      lessUrl: window.location.href.includes("lessindustries"),
      alphaUrl: window.location.href.includes("alpha"),
      betaUrl: window.location.href.includes("beta"),
    }),
    [],
  )
  const { urlPrefix, urlCompany } = useMemo(
    () => ({
      urlPrefix: urlData.lessUrl
        ? urlData.alphaUrl
          ? "alpha-api"
          : urlData.betaUrl
          ? "beta-api"
          : "api"
        : urlData.alphaUrl
        ? "api.alpha"
        : urlData.betaUrl
        ? "api.beta"
        : "api",
      urlCompany: urlData.lessUrl ? ".lessindustries" : ".sentrisense",
    }),
    [urlData],
  )

  const url = "https://" + urlPrefix + urlCompany + ".com"

  return (
    <Container maxWidth="xl" disableGutters>
      {userLanguage?.includes("es") ? <ApiDocEs url={url} /> : <ApiDocEn url={url} />}
    </Container>
  )
}
