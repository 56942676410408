import type { Layout } from "react-grid-layout"

interface ILayoutPayload extends Layout {
  id: string
}

type LayoutActionT = {
  type: "ADD_CELL_REF" | "UPDATE_CELL_HEIGHT"
  payload: ILayoutPayload
}

type DashboardLayoutT = {
  [key: string]: Layout
}

export const layoutReducer = (layout: DashboardLayoutT, action: LayoutActionT) => {
  switch (action.type) {
    case "ADD_CELL_REF": {
      const { id, ...rest } = action.payload
      return { ...layout, [id]: { ...rest, i: id } }
    }
    case "UPDATE_CELL_HEIGHT":
      return {
        ...layout,
        [action.payload.id]: { ...layout[action.payload.id], h: action.payload.h },
      }
    default:
      return layout
  }
}

type CellStateActionT = {
  type: "UPDATE_CELL_STATE" | string
  payload: {
    id: string
    isFetching: boolean
  }
}

export const cellStateReducer = (
  loader: Record<string, boolean>,
  action: CellStateActionT,
) => {
  switch (action.type) {
    case "UPDATE_CELL_STATE":
      return {
        ...loader,
        [action.payload.id]: action.payload.isFetching,
      }
    default:
      return loader
  }
}
