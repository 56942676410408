import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import type { SelectChangeEvent } from "@mui/material/Select"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"

import { switchLanguage } from "../../features/store/configSlice"
import { LANGUAGE_KEYS, LANGUAGES } from "../../helpers/utils/constants"

export default function LanguageSelect() {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    dispatch(switchLanguage(event.target.value))
    i18n.changeLanguage(event.target.value)
  }

  const userLanguage = i18n.resolvedLanguage

  return (
    <FormControl size="small" sx={{ m: 1, maxWidth: 120 }}>
      <Select
        value={userLanguage}
        onChange={handleLanguageChange}
        color="primary"
        sx={{
          backgroundImage: "none",
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
        inputProps={{ id: "language-select", "data-testid": "language-select" }}
      >
        {LANGUAGE_KEYS.map((key) => (
          <MenuItem key={key} value={key}>
            {LANGUAGES[key as keyof typeof LANGUAGES].name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
