import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded"

import useRoutes from "../../helpers/hooks/useRoutes"
import type { LocationState } from "../../types/locationState.types"

interface IPreviousPageProps {
  textKey?: string
  href?: string
}

export default function PreviousPage({
  textKey = "generic.GO_BACK",
  href,
}: IPreviousPageProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const locationState = location?.state
    ? (location.state as LocationState)
    : ({} as LocationState)
  const { baseOrgURL } = useRoutes()

  const handleClick = () => {
    if (href) {
      navigate(href, { replace: true })
    } else if (locationState.redirectedFrom === "/accounts/login") {
      navigate(`${baseOrgURL}`)
    } else {
      navigate(-1)
    }
  }

  return (
    <Box sx={{ width: "fit-content" }}>
      <Typography
        onClick={handleClick}
        variant="body2"
        sx={{
          mb: 2,
          color: (theme) => theme.palette.primary.main,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <ArrowBackIosRoundedIcon sx={{ pr: 1 }} />
        {t(textKey)}
      </Typography>
    </Box>
  )
}
