import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"

import LanguageSelect from "../widgets/user/LanguageSelect"
import ProfileMenu from "../widgets/user/ProfileMenu"
import ThemeSwitch from "../widgets/user/ThemeSwitch"
import { NAVBAR_HEIGHT } from "../helpers/utils/constants"
import { DashboardNavbarRoot, MainNavbarRoot } from "../widgets/styled/containers"

interface NavbarProps {
  open?: boolean
  layout: string
  toggleMobileDrawer?: () => void
}

export default function Navbar({ open, layout, toggleMobileDrawer }: NavbarProps) {
  return (
    <>
      {layout === "dashboard" ? (
        <DashboardNavbarRoot elevation={0} position="absolute" open={open}>
          <Toolbar
            disableGutters
            sx={{
              minHeight: NAVBAR_HEIGHT,
              left: 0,
              px: 2,
            }}
          >
            <IconButton
              onClick={toggleMobileDrawer}
              sx={{
                display: {
                  xs: "inline-flex",
                  lg: "none",
                },
              }}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <LanguageSelect />
            <ProfileMenu />
          </Toolbar>
        </DashboardNavbarRoot>
      ) : (
        <MainNavbarRoot elevation={0}>
          <Toolbar sx={{ justifyContent: "flex-end" }}>
            <LanguageSelect />
            <ThemeSwitch />
          </Toolbar>
        </MainNavbarRoot>
      )}
    </>
  )
}
