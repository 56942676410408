import type { FC } from "react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import { useUpdateDeviceGroupNameMutation } from "../../../features/api"
import { selectCurrentOrgId } from "../../../features/store/orgSlice"
import type { ICustomError } from "../../../helpers/errors/errorTypes"
import EditableTitle from "../../common/editable/EditableTitle"
import PopUp from "../../common/PopUp"

interface IEditableDeviceGroupNameProps {
  name: string
}

const EditableDeviceGroupName: FC<IEditableDeviceGroupNameProps> = ({ name }) => {
  const { id } = useParams()
  const orgId = useSelector(selectCurrentOrgId)
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [updateDeviceGroupName, { isLoading, isSuccess, isError, error }] =
    useUpdateDeviceGroupNameMutation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const onSubmitHandler = (newGroupName: string) => {
    updateDeviceGroupName({
      group_id: Number(id),
      new_group_name: newGroupName,
      org_id: orgId as number,
    })
  }

  useEffect(() => {
    if (isError) {
      handleOpen()
      const newError = error as ICustomError
      setErrorMessage(newError.data.message)
    }
  }, [isError, error])

  return (
    <>
      <EditableTitle
        text={name}
        onSubmitHandler={onSubmitHandler}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />

      <PopUp open={open} onClose={handleClose} severity="error">
        <Alert
          severity={"error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ p: "1rem" }}
        >
          {errorMessage ? errorMessage : t("error.UPDATING_GROUP_NAME")}
        </Alert>
      </PopUp>
    </>
  )
}

export default EditableDeviceGroupName
