import { useForm } from "react-hook-form"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"

// import { register as registerUser } from "../../service/userService"
import { LogoContainer } from "widgets/styled/containers"
import { errorMessage } from "../../helpers/errors/formErrors"
import { DEBUG } from "../../features/config"
import { IMAGOTYPE, PASSWORD_MIN_LENGTH } from "../../helpers/utils/constants"
import CustomLink from "../../widgets/common/CustomLink"

export interface RegisterForm {
  username: string
  password: string
  passwordConfirmation: string
}

export default function Register() {
  // const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm<RegisterForm>({ mode: "all" })

  const onSubmit = (event: RegisterForm) => {
    DEBUG && console.log(event)
    /*const account = {
      username: getValues("username"),
      password: getValues("password"),
    }
    return registerUser(account)
      .then((data) => {
        const userData = data
        console.log(userData)
        navigate("/login")
      })
      .catch((error) => {
        console.error("Error:", error)
        throw error
      })*/
  }

  return (
    <Card sx={{ maxWidth: 360, textAlign: "center" }} elevation={4}>
      <LogoContainer disableGutters>
        <Box component="img" src={IMAGOTYPE} sx={{ width: "60%" }} />
      </LogoContainer>
      <CardContent sx={{ px: "7%" }}>
        <form name="formLogin" onSubmit={handleSubmit(onSubmit)} noValidate>
          <TextField
            error={errors.username ? true : false}
            fullWidth
            size="small"
            type="email"
            id="username"
            label="Email"
            inputProps={{ "aria-invalid": errors.username ? "true" : "false" }}
            FormHelperTextProps={{ style: { textAlign: "right", marginRight: 0 } }}
            helperText={errorMessage(errors, "username")}
            {...register("username", {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
          />
          <TextField
            error={errors.password ? true : false}
            fullWidth
            size="small"
            type="password"
            id="password"
            label="Password"
            sx={{ fontSize: "0.9rem" }}
            inputProps={{ "aria-invalid": errors.password ? "true" : "false" }}
            helperText={errorMessage(errors, "password", PASSWORD_MIN_LENGTH)}
            FormHelperTextProps={{ style: { textAlign: "right", marginRight: 0 } }}
            {...register("password", {
              required: true,
              minLength: PASSWORD_MIN_LENGTH,
            })}
          />
          <TextField
            error={errors.passwordConfirmation ? true : false}
            fullWidth
            size="small"
            type="password"
            id="passwordConfirmation"
            label="Confirm password"
            sx={{ fontSize: "0.9rem" }}
            inputProps={{
              "aria-invalid": errors.passwordConfirmation ? "true" : "false",
            }}
            helperText={errorMessage(errors, "passwordConfirmation")}
            FormHelperTextProps={{ style: { textAlign: "right", marginRight: 0 } }}
            {...register("passwordConfirmation", {
              required: true,
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues()
                  return password === value || "Passwords should match"
                },
              },
            })}
          />
          {isSubmitted && !isSubmitSuccessful && (
            <Typography color="error" component="p" align="right">
              There has been an error. Try again.
            </Typography>
          )}
          <Button variant="contained" type="submit" fullWidth sx={{ mt: 1, mb: 1 }}>
            Login
          </Button>
        </form>
        <Typography align="center" component="p" sx={{ mt: 1 }}>
          Already have an account?&nbsp;
          <CustomLink href="login">Log in</CustomLink>
        </Typography>
      </CardContent>
    </Card>
  )
}
