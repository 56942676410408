import { useEffect, useMemo, useState } from "react"
import { Navigate } from "react-router-dom"

import useOrgs from "../helpers/hooks/useOrgs"
import useRoutes from "../helpers/hooks/useRoutes"
import Preloader from "../widgets/common/PreLoader"
import { LOADER_TIME } from "../helpers/utils/constants"

const BoardLoader = () => {
  const { indexURL } = useRoutes()
  const { orgsFetching, orgsInfoLoading } = useOrgs()
  const [loading, setLoading] = useState(true)
  const infoLoading = useMemo(
    () => orgsFetching || orgsInfoLoading || !indexURL,
    [orgsFetching, orgsInfoLoading, indexURL],
  )

  useEffect(() => {
    let timeout = null
    if (loading || infoLoading) {
      timeout = setTimeout(() => {
        setLoading(false)
      }, LOADER_TIME)
    }
    return () => timeout && clearTimeout(timeout)
  }, [loading, infoLoading])

  return <>{!loading && !infoLoading ? <Navigate to={indexURL} /> : <Preloader />}</>
}

export default BoardLoader
