import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import {
  selectAlertsTable,
  setAlertsTableRowsAmount,
  setAlertsTableSortOrder,
} from "features/store/tablesSlice"
import useEvents from "helpers/hooks/useEvents"
import useRoutes from "helpers/hooks/useRoutes"
import {
  alertsCustomSearch,
  formatAlertsTableCsv,
  getAlertsTableColumns,
  getAlertsTableRows,
  getColsByName,
} from "helpers/utils/alerts"
import Spinner from "widgets/common/Spinner"
import GenericTable from "widgets/common/Table"
import type { DateRange } from "types/date.types"

interface AlertsTableProps {
  dateRange: DateRange | null
  isDateRangeValid: boolean
  devicesIds?: number[]
  groupId?: number
}

function AlertsTable({
  dateRange,
  isDateRangeValid,
  devicesIds,
  groupId,
}: AlertsTableProps) {
  const dispatch = useDispatch()
  const { baseOrgURL } = useRoutes()
  const { t } = useTranslation()
  const alertsTable = useSelector(selectAlertsTable)
  const { events, isLoading } = useEvents({
    fromDate: dateRange?.fromDate,
    toDate: dateRange?.toDate,
    isDateRangeValid,
    devicesIds,
    groupId,
  })

  const columns = useMemo(
    () => getAlertsTableColumns(baseOrgURL, devicesIds, groupId, t),
    [baseOrgURL, devicesIds, groupId, t],
  )

  const { rows, columnsIndexes } = useMemo(() => {
    const alertList = events && getAlertsTableRows(events, t)
    return { rows: alertList, columnsIndexes: getColsByName(columns) }
  }, [t, columns, events])

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {rows && (
            <GenericTable
              rows={rows}
              columns={columns}
              options={{
                customSearch: (searchQuery, currentRow, _columns) => {
                  return alertsCustomSearch(searchQuery, currentRow, columnsIndexes)
                },
                onDownload: (buildHead, buildBody, columns, data) => {
                  const formattedData = formatAlertsTableCsv(data, columnsIndexes, t)
                  return `${buildHead(columns)}${buildBody(formattedData)}`.trim()
                },

                /* --- Persistence values --- */
                rowsPerPage: alertsTable?.qtyRows,
                sortOrder: alertsTable?.sortOrder,
                onChangeRowsPerPage: (numberOfRows) => {
                  dispatch(setAlertsTableRowsAmount(numberOfRows))
                },
                onColumnSortChange: (changedColumn, direction) => {
                  dispatch(setAlertsTableSortOrder({ name: changedColumn, direction }))
                },
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default AlertsTable
