import { formatLocale } from "d3-format"

export const en = formatLocale({
  thousands: ",",
  grouping: [3],
  decimal: ".",
  currency: ["$", ""],
})

export const es = formatLocale({
  thousands: ".",
  grouping: [3],
  decimal: ",",
  currency: ["$", ""],
})

export const getLocaleFormat = (locale: string) => {
  switch (locale) {
    case "es":
      return es
    case "en":
      return en
  }
  return en
}
