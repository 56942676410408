import { useMemo } from "react"
import { useTheme } from "@mui/material"

import type { ISingleStatConfig } from "types/singleStatPlot.types"
import type { CellViewRes, RecordType } from "types/dashboard.types"

import singleStatConfig from "./singleStat"

interface UseSingleStatConfig {
  view: CellViewRes
  data: RecordType[]
  locale?: string
}

export default function useSingleStatConfig({ view, data, locale }: UseSingleStatConfig) {
  const theme = useTheme()

  const graphConfig: ISingleStatConfig = useMemo(
    () =>
      singleStatConfig({
        view,
        data,
        palette: theme.palette,
        locale,
      }),
    [data, view, locale, theme.palette],
  )

  return graphConfig
}
