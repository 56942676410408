import type { FC } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import type { DeviceGroupType } from "../../../types/group.types"
import type { DeviceWGroupsNStatesType } from "../../../types/device.types"
import ConfirmationPopup from "../../common/ConfirmationPopup"

interface IRemoveDevicePopupProps {
  isLoading: boolean
  selectedDevices: DeviceWGroupsNStatesType[]
  deviceGroup: DeviceGroupType
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const RemoveDevicePopup: FC<IRemoveDevicePopupProps> = ({
  isLoading,
  selectedDevices,
  deviceGroup,
  onClose,
  onConfirm,
  open,
}) => {
  const { t } = useTranslation()

  const deviceList = useMemo(
    () =>
      selectedDevices
        ?.filter((selectedDevice) => selectedDevice && selectedDevice.device)
        ?.map(
          ({ device }) =>
            `${t("generic.DEVICE_NAME")}: ${device.name} - ID: ${device.less_id}`,
        ),
    [selectedDevices, t],
  )

  return (
    <ConfirmationPopup
      open={open}
      onClose={onClose}
      title={t("device_groups.ARE_YOU_SURE_TO_REMOVE_DEVICES", {
        groupName: deviceGroup.name,
      })}
      description={deviceList}
      mainButtonText={t("device_groups.REMOVE_DEVICES")}
      onConfirm={onConfirm}
      isLoading={isLoading}
      isDeletePopup
    />
  )
}

export default RemoveDevicePopup
