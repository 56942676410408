import type { Paged } from "types/common.types"
import type {
  PowerlineTowersRes,
  PowerlineTowersReq,
  TowersRes,
  TowersReq,
  TowerRes,
  TowerReq,
} from "types/towers.types"
import { api } from "./base"

export const towersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    powerlineTowers: builder.query<Paged<PowerlineTowersRes>, PowerlineTowersReq>({
      query: ({ id, params }) => ({
        url: `v1/powerlines/${id}/towers`,
        params,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Tower" as const, id }],
    }),
    towers: builder.query<Paged<TowersRes>, TowersReq>({
      query: ({ params }) => ({
        url: `v1/powerlines_towers`,
        params,
      }),
      providesTags: (_result, _error, { params }) => [
        { type: "Tower" as const, id: params.org_id },
      ],
    }),
    tower: builder.query<TowerRes, TowerReq>({
      query: ({ id, params }) => ({
        url: `v1/powerlines_towers/${id}`,
        params,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Tower" as const, id }],
    }),
  }),
})
