import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import { Outlet } from "react-router-dom"

import { getAPITabsConfig } from "helpers/config/tabsConfigs"
import Title from "widgets/common/Title"
import LinkTabsSection from "widgets/common/LinkTabsSection"

export default function APIDocumentation() {
  const { t } = useTranslation()

  const APITabsConfig = useMemo(() => getAPITabsConfig(), [])

  return (
    <>
      <Title text={t("api.SENTRISENSE_API")} styles={{ mb: 2 }} />
      <LinkTabsSection tabsConfig={APITabsConfig} />
      <Outlet />
    </>
  )
}
