import { useMemo } from "react"
import { Box, Container, Skeleton, useTheme } from "@mui/material"
import { useSelector } from "react-redux"
import type { LatLngTuple } from "leaflet"

import { selectCurrentOrgId } from "features/store/orgSlice"
import useDevices from "helpers/hooks/useDevices"
import { DASHBOARD_PADDING, FOOTER_HEIGHT, NAVBAR_HEIGHT } from "helpers/utils/constants"
import Map from "widgets/plots/geo/Map"
import GeoJSONLayers from "widgets/geo/GeoJSONLayers"
import { getMapCenterFromDeviceGeo } from "helpers/utils/map"
import useGeolocations from "helpers/hooks/useGeolocations"

const MainMap = () => {
  const orgId = useSelector(selectCurrentOrgId)
  const { geoData, isFetching: geoFetching } = useGeolocations()
  const { isFetching: devicesFetching } = useDevices()
  const theme = useTheme()

  const center: LatLngTuple = useMemo(() => getMapCenterFromDeviceGeo(geoData), [geoData])

  return (
    <>
      <Box
        sx={{
          width: `calc(100% + (${DASHBOARD_PADDING}*2))`,
          height: `calc(100vh - ${NAVBAR_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
          position: "relative",
          mt: `-${DASHBOARD_PADDING}`,
          ml: `-${DASHBOARD_PADDING}`,
          mr: `-${DASHBOARD_PADDING}`,
        }}
      >
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            // Colors for GeoJSON popups that depend on the theme
            "& .leaflet-popup-content-wrapper": {
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            },
          }}
        >
          {!geoFetching && !devicesFetching ? (
            <Map center={center} key={orgId} mapHeight="100%">
              <GeoJSONLayers data={geoData} />
            </Map>
          ) : (
            <Skeleton variant="rectangular" height="100%" />
          )}
        </Container>
      </Box>
    </>
  )
}

export default MainMap
