import type { FC } from "react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import { useUpdateDeviceNameMutation } from "../../../features/api"
import type { ICustomError } from "../../../helpers/errors/errorTypes"
import EditableTitle from "../../common/editable/EditableTitle"
import PopUp from "../../common/PopUp"

interface IEditableDeviceNameProps {
  name: string
}

const EditableDeviceName: FC<IEditableDeviceNameProps> = ({ name }) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [updateDeviceName, { isLoading, isSuccess, isError, error }] =
    useUpdateDeviceNameMutation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const onSubmitHandler = (newName: string) => {
    updateDeviceName({
      less_id: Number(id),
      name: newName,
    })
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (isError) {
      handleOpen()
      const newError = error as ICustomError
      setErrorMessage(newError.data.message)
    }
  }, [isError, error])

  return (
    <>
      <EditableTitle
        text={name}
        onSubmitHandler={onSubmitHandler}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
      <PopUp open={open} onClose={handleClose}>
        <Alert
          severity={"error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ p: "1rem" }}
        >
          {errorMessage ? errorMessage : t("error.UPDATING_DEVICE_NAME")}
        </Alert>
      </PopUp>
    </>
  )
}

export default EditableDeviceName
