import type { FC, MouseEvent as ReactMouseEvent } from "react"
import { useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { useTranslation } from "react-i18next"
import { DateTime } from "luxon"

import useDateRange from "helpers/hooks/useDateRange"
import LoadingButton from "widgets/common/LoadingButton"

export interface CSVButtonProps {
  isFetching: boolean
  download: (props: {
    id: number
    filename: string
    fromDate: string
    toDate: string
  }) => Promise<any>
}

const CsvButton: FC<CSVButtonProps> = ({ isFetching, download }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { deviceDateRange } = useDateRange()
  const { fromDate, toDate } = useMemo(() => {
    return { fromDate: deviceDateRange?.fromDate, toDate: deviceDateRange?.toDate }
  }, [deviceDateRange?.fromDate, deviceDateRange?.toDate])

  const fileName = useMemo(() => {
    if (fromDate && toDate) {
      const from = DateTime.fromISO(fromDate).toISODate()
      const to = DateTime.fromISO(toDate).toISODate()
      return `${id}-${from}-${to}-data.csv`
    }
    return ""
  }, [id, fromDate, toDate])

  const downloadFile = useCallback(
    async (event: ReactMouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      if (!(fileName && fromDate && toDate)) {
        return
      }
      const props = { id: Number(id), fromDate, toDate, filename: fileName }
      return download(props)
    },
    [id, fromDate, toDate, fileName, download],
  )

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <LoadingButton
          loading={isFetching}
          onClick={downloadFile}
          icon={<CloudDownloadIcon />}
          styles={{ color: "inherit" }}
          type="button"
        >
          {t("table.DOWNLOAD_CSV")}
        </LoadingButton>
      </Box>
    </>
  )
}

export default CsvButton
