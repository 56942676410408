import type { FC } from "react"
import { Box, Stack, Typography } from "@mui/material"
// import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import ControlledInput from "../../common/ControlledInput"

type RangeConditionType = "above" | "below" | "inside" | "outside"

interface RangeInputProps {
  rangeCondition: RangeConditionType
  index?: number
}

const RangeInput: FC<RangeInputProps> = ({ rangeCondition, index }) => {
  const { register } = useFormContext()
  const { t } = useTranslation()

  const getInputs = (rangeCondition: RangeConditionType) => {
    switch (rangeCondition) {
      case "above":
        return (
          <ControlledInput
            placeholder=""
            variant="outlined"
            type="number"
            {...register(`thresholds.${index}.above`, {
              required: true,
              valueAsNumber: true,
            })}
          />
        )
      case "below":
        return (
          <ControlledInput
            placeholder=""
            variant="outlined"
            type="number"
            {...register(`thresholds.${index}.below`, {
              required: true,
              valueAsNumber: true,
            })}
          />
        )
      case "inside":
        return (
          <Stack
            sx={{
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              width: "100%",
            }}
          >
            <ControlledInput
              placeholder=""
              variant="outlined"
              type="number"
              {...register(`thresholds.${index}.inside.from`, {
                required: true,
                valueAsNumber: true,
              })}
            />
            <Box>
              <Typography>{t("alert_settings.TO")}</Typography>
            </Box>
            <ControlledInput
              placeholder=""
              variant="outlined"
              type="number"
              {...register(`thresholds.${index}.inside.to`, {
                required: true,
                valueAsNumber: true,
              })}
            />
          </Stack>
        )
      case "outside":
        return (
          <Stack
            sx={{
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              width: "100%",
            }}
          >
            <ControlledInput
              placeholder=""
              variant="outlined"
              type="number"
              {...register(`thresholds.${index}.outside.from`, {
                required: true,
                valueAsNumber: true,
              })}
            />
            <Box>
              <Typography>to</Typography>
            </Box>
            <ControlledInput
              placeholder=""
              variant="outlined"
              type="number"
              {...register(`thresholds.${index}.outside.to`, {
                required: true,
                valueAsNumber: true,
              })}
            />
          </Stack>
        )
    }
  }

  return (
    <Stack
      sx={{
        flexDirection: "row",
        width: "100%",
      }}
    >
      {getInputs(rangeCondition)}
    </Stack>
  )
}

export default RangeInput
