import { Outlet } from "react-router-dom"

import { DashboardLayoutRoot } from "widgets/styled/layout"

interface DashboardLayoutProps {
  fullWidth?: boolean
}

export function DashboardLayout({ fullWidth }: DashboardLayoutProps) {
  return (
    <DashboardLayoutRoot disableGutters maxWidth={fullWidth ? false : "xl"}>
      <Outlet />
    </DashboardLayoutRoot>
  )
}
