import type { FC } from "react"
import { Autocomplete as MUIAutocomplete, Box, TextField } from "@mui/material"
import { createFilterOptions } from "@mui/material/Autocomplete"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import fp from "lodash/fp"

import type { TelemetryOptionType } from "../../types/telemetries.types"
import { Chip } from "../styled/containers"
import type { InputRulesType } from "./ControlledInput"

type OptionType = {
  label: string
  value: string
}

interface ControlledAutocompleteProps {
  name: string
  options: OptionType[]
  label?: string
  rules?: InputRulesType
  multiple?: boolean
}
const filter = createFilterOptions<OptionType>()

const ControlledAutocomplete: FC<ControlledAutocompleteProps> = ({
  name,
  options,
  label,
  rules,
  multiple,
}: ControlledAutocompleteProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Box sx={{ width: "100%", height: "fit-content" }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MUIAutocomplete
            {...field}
            value={field.value || []}
            multiple={multiple}
            options={options}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option
              }
              return option.label
            }}
            isOptionEqualToValue={(
              option: TelemetryOptionType,
              value: TelemetryOptionType,
            ) => fp.isEqual(option, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                required={!!rules?.required}
                sx={{ mb: 0 }}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip {...getTagProps({ index })} label={option.label} />
              ))
            }}
            onChange={(_, data) => {
              const newValues = fp.map((newValue: any) => {
                // Value selected with enter, right from the input
                if (typeof newValue === "string") {
                  return { label: newValue, value: newValue }
                }
                // Regular option
                return newValue
              }, data)
              return field.onChange(newValues)
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)
              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.value)
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  label: `${t("generic.ADD")} "${inputValue}"`,
                  value: inputValue,
                })
              }
              return filtered
            }}
            freeSolo
            clearOnBlur
            selectOnFocus
            handleHomeEndKeys
          />
        )}
      />
    </Box>
  )
}

export default ControlledAutocomplete
