import type { FC } from "react"
import { useState } from "react"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"

import { useTranslation } from "react-i18next"
import AddDevicesToGroupPopup from "../../deviceGroups/editor/AddDevicesToGroupPopup"
import type { DeviceWGroupsNStatesType } from "../../../types/device.types"

interface IDevicesTableToolbarProps {
  devices: DeviceWGroupsNStatesType[]
}

const DevicesTableToolbar: FC<IDevicesTableToolbarProps> = ({ devices }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Button onClick={handleClick} startIcon={<AddIcon />}>
        {t("device_groups.ADD_DEVICE")}
      </Button>
      {open && (
        <AddDevicesToGroupPopup
          onClose={handleClose}
          open={open}
          groupDevices={devices}
        />
      )}
    </>
  )
}

export default DevicesTableToolbar
