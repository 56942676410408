import { useTranslation } from "react-i18next"
import { Stack, Typography, Dialog } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import type { UserOTPRecoverCodes } from "types/users.types"
import CardWithTitle from "widgets/common/CardWithTitle"
import OTPRecoverCodes from "./OTPRecoverCodes"

interface OTPRecoverCodesModalProps {
  open: boolean
  onClose: () => void
  username: string
  data: UserOTPRecoverCodes | undefined
}

const OTPRecoverCodesModal = ({ open, onClose, data }: OTPRecoverCodesModalProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="otp-codes-title"
      aria-describedby="otp-codes-description"
      fullWidth
      maxWidth={"md"}
    >
      <CardWithTitle
        titleKey="account_settings.ACCOUNT_RECOVERY_CODES"
        handleAction={onClose}
        actionIcon={<CloseIcon fontSize="small" color="action" />}
      >
        <Stack id="otp-register-description" width={"100%"}>
          <Typography mb={1}>{t("account_settings.IF_LOSE_KEYS")}</Typography>
          <Typography mb={1}>{t("account_settings.CODES_PURPOSE")}</Typography>
          <Typography mb={1}>{t("account_settings.CODES_DISCLAIMER")}</Typography>
        </Stack>
        {data && (
          <Stack alignItems={"center"} width={"100%"}>
            <OTPRecoverCodes codes={data} />
          </Stack>
        )}
      </CardWithTitle>
    </Dialog>
  )
}

export default OTPRecoverCodesModal
