import { useSelector } from "react-redux"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { useSpanQuery } from "features/api"

interface useSpanProps {
  id: number | undefined
}

const useSpan = ({ id }: useSpanProps) => {
  const orgId = useSelector(selectCurrentOrgId)

  const {
    data: span,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useSpanQuery(
    {
      id: Number(id),
      params: { org_id: Number(orgId) },
    },
    {
      skip: !orgId || !id,
    },
  )

  return { span, isFetching, isLoading, isError, isSuccess }
}

export default useSpan
