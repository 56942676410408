import { useSelector } from "react-redux"

import { usePowerlineQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"

interface usePowerlineProps {
  id: number | undefined
}

const usePowerline = ({ id }: usePowerlineProps) => {
  const orgId = useSelector(selectCurrentOrgId)

  const {
    data: powerline,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = usePowerlineQuery(
    {
      id: Number(id),
      params: { org_id: Number(orgId) },
    },
    {
      skip: !orgId || !id,
    },
  )

  return {
    powerline,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export default usePowerline
