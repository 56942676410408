import { useState } from "react"
import { Button, Box } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"

import CreateUserPopup from "./CreateUserPopup"

const CreateUserButton = () => {
  const { t } = useTranslation()
  const [createPopupOpen, setCreatePopupOpen] = useState(false)

  const handleCreateUserOpen = () => {
    setCreatePopupOpen(true)
  }
  const handleCreateUserClose = () => {
    setCreatePopupOpen(false)
  }

  return (
    <>
      <Box>
        <Button
          onClick={handleCreateUserOpen}
          startIcon={<AddIcon />}
          variant="outlined"
          size="small"
        >
          {t("enterprise.CREATE_USER")}
        </Button>
      </Box>
      {createPopupOpen && (
        <CreateUserPopup open={createPopupOpen} onClose={handleCreateUserClose} />
      )}
    </>
  )
}

export default CreateUserButton
