import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"

import { useOrgUsersQuery } from "features/api"
import usePermissions from "helpers/hooks/usePermissions"
import Title from "widgets/common/Title"
import { HeaderContainer } from "../styled/containers"
import CreateUserButton from "./CreateUserButton"
import UsersTable from "./UsersTable"

interface OrgUsersProps {
  orgId: number | null
}

const OrgUsers: FC<OrgUsersProps> = ({ orgId }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isAdmin } = usePermissions()
  const { currentData: usersRoles, isLoading: usersLoading } = useOrgUsersQuery(
    {
      orgId: orgId as number,
    },
    { skip: !orgId },
  )

  return (
    <>
      <HeaderContainer>
        <Title text={t("generic.USERS")} />
        {isAdmin && <CreateUserButton />}
      </HeaderContainer>
      <UsersTable
        users={usersRoles?.roles}
        isLoading={usersLoading}
        styles={{ border: `1px solid ${theme.palette.neutral[400]}` }}
      />
    </>
  )
}

export default OrgUsers
