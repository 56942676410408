import type { UserType } from "types/users.types"

export interface ProfileForm {
  firstName: string | undefined
  lastName: string | undefined
}

export const getDefaultValues = (user: UserType | undefined): ProfileForm => ({
  firstName: user?.first_name ?? "",
  lastName: user?.last_name ?? "",
})
