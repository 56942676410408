import type { FC } from "react"
import { Grid } from "@mui/material"

import DeviceGroups from "pages/orgs/DeviceGroups"
import OrgPieCharts from "widgets/organization/pieCharts/OrgPieCharts"
import OrgDevices from "widgets/device/OrgDevices"

const OrgHomepage: FC = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} data-testid="org-grid">
          <OrgPieCharts />
        </Grid>
        <Grid item xs={12} data-testid="devices-group-grid">
          <DeviceGroups />
        </Grid>
        <Grid item xs={12} data-testid="devices-grid">
          <OrgDevices />
        </Grid>
      </Grid>
    </>
  )
}

export default OrgHomepage
