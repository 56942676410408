import { useTranslation } from "react-i18next"
import { Grid, Stack, Typography, useTheme } from "@mui/material"
import WarningIcon from "@mui/icons-material/Warning"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import AdjustIcon from "@mui/icons-material/Adjust"

import CustomLink from "widgets/common/CustomLink"
import useRoutes from "helpers/hooks/useRoutes"
import type { AccordionAlertSetting } from "types/alerts.types"
import AlertAccordionMenu from "widgets/alerts/AlertAccordionMenu"
import usePermissions from "helpers/hooks/usePermissions"

interface AlertAccordionSummaryContentProps {
  alert: AccordionAlertSetting
}

const AlertAccordionSummaryContent = ({ alert }: AlertAccordionSummaryContentProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { baseOrgURL } = useRoutes()
  const { isEditor } = usePermissions()
  const IS_OPEN = alert.state === "open"
  const IS_SILENCED = alert.state === "silenced"
  const IS_NOT_TRIGGERED = alert.state === "notTriggered"

  return (
    <Grid container spacing={2} justifyContent={"center"} marginX={0.5}>
      <Grid item xs={12} sm={12} md={1} lg={1} xl={1} alignSelf={"center"}>
        {IS_OPEN && <WarningIcon color="error" fontSize="small" />}
        {IS_SILENCED && <NotificationsOffIcon color="warning" fontSize="small" />}
        {IS_NOT_TRIGGERED && <AdjustIcon color="action" fontSize="small" />}
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Stack>
          <Typography color="text.secondary">{t("alerts.ALERT_NAME")}:</Typography>
          <Typography variant="h5">{alert.config.custom_name}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Stack>
          <Typography color="text.secondary">{t("generic.DEVICE")}:</Typography>
          <CustomLink
            href={`${baseOrgURL}/devices/${alert.less_id}`}
            variant="h5"
            hover
            bold
            styles={{ pointerEvents: "auto" }}
          >
            {alert.name}
          </CustomLink>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Stack>
          <Typography color="text.secondary">{t("generic.ID")}:</Typography>
          <CustomLink
            href={`${baseOrgURL}/devices/${alert.less_id}`}
            variant="h5"
            hover
            bold
            styles={{ pointerEvents: "auto" }}
          >
            {alert.less_id}
          </CustomLink>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2} xl={2} alignSelf={"center"}>
        <Stack
          sx={{
            alignItems: "flex-end",
            [theme.breakpoints.down("md")]: { alignItems: "flex-start" },
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {isEditor && <AlertAccordionMenu alert={alert} />}
        </Stack>
      </Grid>
    </Grid>
  )
}

export default AlertAccordionSummaryContent
