import type { FC, PropsWithChildren } from "react"
import { Divider, Typography } from "@mui/material"
import { SectionCardHeader as HeaderContainer } from "widgets/styled/containers"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContainerProps {}
export const SectionCardHeader: FC<PropsWithChildren<ContainerProps>> = ({
  children,
}) => {
  return (
    <>
      <HeaderContainer>
        <Typography variant="h6" component="h4">
          {children}
        </Typography>
      </HeaderContainer>
      <Divider />
    </>
  )
}

export const Subtitle: FC<PropsWithChildren<ContainerProps>> = ({ children }) => {
  return (
    <>
      <Typography variant="h6" component="h6" sx={{ pb: 0.5 }}>
        {children}
      </Typography>
    </>
  )
}

export const Paragraph: FC<PropsWithChildren<ContainerProps>> = ({ children }) => {
  return (
    <>
      <Typography
        color="text.secondary"
        component="p"
        fontWeight={300}
        sx={{ pb: 2, textAlign: "justify" }}
      >
        {children}
      </Typography>
    </>
  )
}
