import { type FC } from "react"

import useAlertSettings from "helpers/hooks/useAlertSettings"
import type { DeviceLocationType } from "types/geolocation.types"
import { getDevicePosition, getMarkerIcon } from "helpers/utils/map"
import { hasDeviceTriggeredAlerts } from "../../../helpers/utils/alerts"
import GeoPopup from "./GeoPopup"
import MapMarker from "./MapMarker"

interface IDeviceMapMarkersProps {
  devices: DeviceLocationType[]
}

const DeviceMapMarkers: FC<IDeviceMapMarkersProps> = ({ devices }) => {
  const { orgAlertsBySettingId } = useAlertSettings()

  return (
    <>
      {devices?.map((device: DeviceLocationType) => {
        const triggered =
          !!device.settingsStates &&
          hasDeviceTriggeredAlerts(device.settingsStates, orgAlertsBySettingId)
        const deviceVersion = device.settings?.device_version ?? ""
        const hwVersion = device.hardware_version ?? ""
        const [, deviceVersionPart] = deviceVersion.split("v")
        const [, hwVersionPart] = hwVersion.split("-")
        const version = deviceVersionPart ?? hwVersionPart
        const target = deviceVersionPart ? "3.5" : "3.3"

        const icon = getMarkerIcon(triggered, version, target)
        const position = getDevicePosition(device)

        return (
          <MapMarker
            key={device.id}
            position={position}
            icon={icon}
            zIndexOffset={triggered ? 500 : 100}
            popup={<GeoPopup device={device} />}
            tooltipContent={device.name}
          />
        )
      })}
    </>
  )
}

export default DeviceMapMarkers
