import { useMemo, type ReactNode } from "react"
import { Stack, Typography, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"

interface SectionTitleProps {
  titleKey: string
  actionPermission?: boolean
  isDoingAction?: boolean
  handleAction?: () => void
  actionIcon?: ReactNode
}

const SectionTitle = ({
  titleKey,
  actionPermission = true,
  isDoingAction = false,
  handleAction,
  actionIcon,
}: SectionTitleProps) => {
  const { t } = useTranslation()
  const hasAction = useMemo(() => handleAction && actionIcon, [handleAction, actionIcon])

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom={1}
      borderColor={(theme) => theme.palette.neutral[400]}
      px={2.5}
      py={1.5}
      height={"45px"}
    >
      <Typography variant="h5" component="h5">
        {t(titleKey)}
      </Typography>
      {actionPermission && hasAction && !isDoingAction && (
        <IconButton size="small" color="primary" onClick={handleAction}>
          {actionIcon}
        </IconButton>
      )}
    </Stack>
  )
}

export default SectionTitle
