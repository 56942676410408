import { Dialog, Stack, Typography } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { useRemoveOrgUserMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import CardWithTitle from "widgets/common/CardWithTitle"
import FormButtons from "widgets/common/FormButtons"

interface IRemoveUserForm {
  username: string
}

interface RemoveUserModalProps {
  open: boolean
  onClose: () => void
  username: string
}

const RemoveUserModal = ({ open, onClose, username }: RemoveUserModalProps) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const methods = useForm<IRemoveUserForm>({
    mode: "all",
    defaultValues: {
      username,
    },
  })

  const [removeUser, { isLoading: isDeleting }] = useRemoveOrgUserMutation()

  const onSubmit = async () => {
    snackbarMutation({
      mutation: removeUser({ username, orgId: orgId as number }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.DELETING_ITEM", { item: t("generic.USER") }),
      ),
      getSuccessMessage: () => t("users.USER_DELETED"),
    })
      .then(() => {
        methods.reset()
        onClose()
      })
      .catch()
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        methods.reset()
        onClose()
      }}
      fullWidth
      maxWidth={"sm"}
    >
      <CardWithTitle
        titleKey="users.REMOVE_USER"
        handleAction={() => {
          methods.reset()
          onClose()
        }}
        actionIcon={<CloseIcon fontSize="small" color="action" />}
      >
        <Stack>
          <Typography variant="h6" mb={3} align="center">
            {username}
          </Typography>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <FormButtons
                onClose={() => {
                  methods.reset()
                  onClose()
                }}
                buttonText={"generic.REMOVE"}
                isLoading={isDeleting}
                isDelete
              />
            </FormProvider>
          </form>
        </Stack>
      </CardWithTitle>
    </Dialog>
  )
}

export default RemoveUserModal
