import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { Typography, Stack } from "@mui/material"

interface TenantsListProps {
  tenants: string[]
}

const TenantsList: FC<TenantsListProps> = ({ tenants }) => {
  const { t } = useTranslation()

  return (
    <Stack>
      <Typography variant="h5" component="h5" sx={{ pt: 1, pb: 0.5 }}>
        {t("auth.TENANTS")}
      </Typography>
      {tenants.map((tenant) => (
        <Typography sx={{ color: (theme) => theme.palette.grey[400] }} key={tenant}>
          {tenant}
        </Typography>
      ))}
    </Stack>
  )
}

export default TenantsList
