import { useContext, useEffect, useMemo } from "react"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import fp from "lodash/fp"

import { useDeviceTelemetryQuery } from "features/api"
import { getRecordService } from "helpers/formatters/dataFormatters"
import useDevices from "helpers/hooks/useDevices"
import useDateRange from "helpers/hooks/useDateRange"
import { getCellHeight } from "helpers/utils/dashboards"
import { formatTelemetryPlotData } from "helpers/utils/plots"
import {
  formatBatteryStatusData,
  formatSignalStatusData,
} from "helpers/utils/telemetries"
import DashboardContext from "widgets/device/Dashboard/DashboardContext"
import type { IPlotReqConfig, IPlotView, RecordService } from "types/dashboard.types"
import type { DeviceTelemetryType } from "types/telemetries.types"
import Plot from "./Plot"

type GetFill = (view: IPlotView, config: IPlotReqConfig) => string[]
const getFill: GetFill = (view, config) => {
  const minimalFill = ["_field"]
  const groupFill = fp.getOr(minimalFill, "group", config)
  const fill = fp.getOr(groupFill, "properties.fill", view)
  const legendColumns = fp.getOr([], "properties.legendColumns", view)
  const aggregates = fp.getOr([], "aggregate", config)
  const extra = [
    // conveniently add name to fill if needed by the legends to show (required by giraffe)
    ...(fp.includes("name", legendColumns) ? ["name"] : []),
    // if we have multiple aggregates, we need the result in the fill
    ...(aggregates.length > 1 ? ["result"] : []),
  ]
  return fp.uniq(fp.concat(fill, extra))
}

interface TelemetryPlotProps {
  view: IPlotView
  reqConfig: IPlotReqConfig
  draggable?: boolean
}

const DeviceTelemetryPlot: FC<TelemetryPlotProps> = ({ view, draggable, reqConfig }) => {
  const { t } = useTranslation()
  const {
    deviceId,
    dispatchPlotLayout,
    dispatchPlotState,
    config: dashboardConfig,
  } = useContext(DashboardContext)
  const { deviceDateRange, isDeviceDateRangeValid } = useDateRange()
  const { devsWRelsById } = useDevices()
  const { aggregate, aggregate_n, fields, group, method, aggregate_every } = reqConfig

  const fromDate = useMemo(
    () =>
      view.nameKey === "LOCATION" && devsWRelsById?.[deviceId]?.device.activated_at
        ? new Date(devsWRelsById[deviceId].device.activated_at as number).toISOString()
        : deviceDateRange?.fromDate,
    [devsWRelsById, deviceDateRange, deviceId, view.nameKey],
  )

  const { data, isFetching } = useDeviceTelemetryQuery(
    {
      id: deviceId,
      params: {
        aggregate,
        aggregate_n,
        method,
        group,
        aggregate_every,
        field: fields,
        from_date: fromDate,
        to_date: deviceDateRange?.toDate,
      },
    },
    {
      skip: !isDeviceDateRangeValid || !fromDate || !fields || !fields.length,
    },
  )

  const recordService: RecordService = useMemo(() => {
    // What should we use as fill if we are using pivot?
    const fill = getFill(view, reqConfig)
    return getRecordService(fill, t)
  }, [reqConfig, t, view])

  const formattedData = useMemo(() => {
    // needed for giraffe as we can't put code in the tooltip
    type AddName = (records: DeviceTelemetryType[]) => DeviceTelemetryType[]
    const addName: AddName = fp.map((record: DeviceTelemetryType) => ({
      ...record,
      name: recordService.getName(record),
    }))
    const result = addName(formatTelemetryPlotData(data))

    if (result && result.length > 0) {
      if (view.nameKey === "SIGNAL_LEVEL") {
        return formatSignalStatusData(result)
      }
      if (view.nameKey === "BATTERY_STATUS") {
        return formatBatteryStatusData(result)
      }
    }
    return result
  }, [data, view, recordService])

  const shouldRender = data && data.length !== 0

  useEffect(() => {
    dispatchPlotLayout({
      type: "UPDATE_CELL_HEIGHT",
      payload: {
        id: view.nameKey,
        h: shouldRender ? getCellHeight(view.nameKey, dashboardConfig.type) : 0,
      },
    })
  }, [dispatchPlotLayout, view, shouldRender, dashboardConfig.type])

  useEffect(() => {
    dispatchPlotState({
      type: "UPDATE_CELL_STATE",
      payload: {
        id: view.nameKey,
        isFetching,
      },
    })
  }, [isFetching, dispatchPlotState, view])

  return (
    <>
      {formattedData && formattedData.length > 0 && (
        <Plot
          data={formattedData}
          view={view}
          draggable={draggable}
          recordService={recordService}
        />
      )}
    </>
  )
}

export default DeviceTelemetryPlot
