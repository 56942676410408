import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Stack, Tooltip, Typography } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import type { CellViewType } from "types/dashboard.types"
import { getPlotName } from "helpers/utils/translations"

interface PlotTitleProps {
  view: CellViewType
  titleWithUnit: boolean
}

const PlotTitle = ({ view, titleWithUnit }: PlotTitleProps) => {
  const { t } = useTranslation()
  const { nameKey, unit, tooltip } = getPlotName(view, titleWithUnit)
  const tooltipText = useMemo(
    () => tooltip?.map((tooltipItem) => <div key={tooltipItem}>{t(tooltipItem)}</div>),
    [t, tooltip],
  )

  return (
    <Stack direction={"row"} gap={1} alignItems={"center"} pb={2} height={40}>
      <Typography variant="h5">
        {t([`device_information.${nameKey}`, view.name])}
        {unit && ` ${unit}`}
      </Typography>
      {tooltipText && (
        <Tooltip
          title={<>{tooltipText}</>}
          enterTouchDelay={0}
          placement="bottom-start"
          arrow
        >
          <InfoOutlinedIcon fontSize="small" color="primary" />
        </Tooltip>
      )}
    </Stack>
  )
}

export default PlotTitle
