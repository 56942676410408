import type { FC, PropsWithChildren } from "react"
import type { LatLngTuple } from "leaflet"
import { Grid } from "@mui/material"

import { MAP_HEIGHT } from "helpers/utils/constants"
import Map from "widgets/plots/geo/Map"
import { SectionContainer } from "widgets/styled/containers"
import Spinner from "./Spinner"

interface MapProps {
  isPending: boolean
  center: LatLngTuple
  shouldRender: boolean
}

const MapSection: FC<PropsWithChildren<MapProps>> = ({
  isPending,
  center,
  shouldRender,
  children,
}) => {
  if (isPending) {
    return <Spinner styles={{ height: MAP_HEIGHT }} />
  }
  if (shouldRender) {
    return (
      <Grid item xs={12} marginTop={1}>
        <SectionContainer
          maxWidth="xl"
          disableGutters
          sx={{
            height: `calc(${MAP_HEIGHT} + 3rem)`,
            boxShadow: 3,
            margin: 0,
            padding: "1.5rem",
          }}
        >
          <Map center={center}>{children}</Map>
        </SectionContainer>
      </Grid>
    )
  }
  return <></>
}
export default MapSection
