import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

import Title from "widgets/common/Title"
import { getAlertsTabsConfig } from "helpers/config/tabsConfigs"
import useDateRange from "helpers/hooks/useDateRange"
import LinkTabsSection from "widgets/common/LinkTabsSection"

function Alerts() {
  const { t } = useTranslation()
  const { eventsSearchParams } = useDateRange()

  const alertsTabsConfig = useMemo(
    () => getAlertsTabsConfig(eventsSearchParams),
    [eventsSearchParams],
  )

  return (
    <>
      <Title text={t("alerts.ALERTS_OVERVIEW")} styles={{ mb: 2 }} />
      <LinkTabsSection tabsConfig={alertsTabsConfig} />
      <Outlet />
    </>
  )
}

export default Alerts
