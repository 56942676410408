import type { FC } from "react"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded"
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import SensorsRoundedIcon from "@mui/icons-material/SensorsRounded"
import { Tooltip } from "@mui/material"
import StatusBox from "../common/StatusBox"

interface IDeviceStatusProps {
  deviceStatus: "reporting" | "active" | "stopped" | "inactive"
}

const DeviceStatus: FC<IDeviceStatusProps> = ({ deviceStatus }) => {
  const { t } = useTranslation()
  const statusStyles = {
    backgroundColor: "none",
    gap: "0.5rem",
    border: "none",
    paddingLeft: 0,
    paddingRight: 0,
  }

  switch (deviceStatus) {
    case "reporting":
      return (
        <Tooltip title={t("device.REPORTING_STATUS")}>
          <span>
            <StatusBox status="success" styles={statusStyles}>
              <ShowChartRoundedIcon fontSize="small" />
              <Typography component="p">{t("reporting_status.REPORTING")}</Typography>
            </StatusBox>
          </span>
        </Tooltip>
      )
    case "active":
      return (
        <Tooltip title={t("device.ACTIVE_STATUS")}>
          <span>
            <StatusBox status="info" styles={statusStyles}>
              <SensorsRoundedIcon fontSize="small" />
              <Typography component="p">{t("reporting_status.ACTIVE")}</Typography>
            </StatusBox>
          </span>
        </Tooltip>
      )
    case "stopped":
      return (
        <Tooltip title={t("device.STOPPED_STATUS")}>
          <span>
            <StatusBox status="warning" styles={statusStyles}>
              <ErrorOutlineRoundedIcon fontSize="small" />
              <Typography component="p">{t("reporting_status.STOPPED")}</Typography>
            </StatusBox>
          </span>
        </Tooltip>
      )
    case "inactive":
      return (
        <Tooltip title={t("device.INACTIVE_STATUS")}>
          <span>
            <StatusBox status="neutral-dark" styles={statusStyles}>
              <CloseRoundedIcon fontSize="small" />
              <Typography component="p">{t("reporting_status.INACTIVE")}</Typography>
            </StatusBox>
          </span>
        </Tooltip>
      )
    default:
      return null
  }
}

export default DeviceStatus
