import { useMemo } from "react"
import { useSelector } from "react-redux"

import { selectCurrentUserGlobalRole } from "features/store/authSlice"
import { selectCurrentOrgUserRole } from "features/store/orgSlice"
import {
  adminCheckByRole,
  editCheckByRole,
  ownerCheckByRole,
  viewerCheckByRole,
} from "helpers/utils/user"

const usePermissions = () => {
  const userGlobalRole = useSelector(selectCurrentUserGlobalRole)
  const userRole = useSelector(selectCurrentOrgUserRole)

  const isBackoffice = useMemo(
    () => userGlobalRole === "backoffice_admin",
    [userGlobalRole],
  )
  const isOwner = useMemo(() => ownerCheckByRole(userRole), [userRole])
  const isAdmin = useMemo(() => adminCheckByRole(userRole), [userRole])
  const isEditor = useMemo(() => editCheckByRole(userRole), [userRole])
  const isViewer = useMemo(() => viewerCheckByRole(userRole), [userRole])

  return { isBackoffice, isOwner, isAdmin, isEditor, isViewer }
}

export default usePermissions
