import {
  deviceTabLayoutLg,
  powerlineInfoTabLayoutLg,
  spanTabLayoutLg,
} from "../config/dashboardLayoutsConfig"

export const getLayout = (type: string) => {
  switch (type) {
    case "span":
      return spanTabLayoutLg
    case "device":
      return deviceTabLayoutLg
    case "powerline":
      return powerlineInfoTabLayoutLg
    default:
      return {}
  }
}

export const getCellHeight = (name: string, dashboardType: string) => {
  if (dashboardType === "span" && spanTabLayoutLg[name]) {
    return spanTabLayoutLg[name].h
  } else if (dashboardType === "device" && deviceTabLayoutLg[name]) {
    return deviceTabLayoutLg[name].h
  } else if (dashboardType === "powerline" && powerlineInfoTabLayoutLg[name]) {
    return powerlineInfoTabLayoutLg[name].h
  } else {
    return 0
  }
}
