import ConditionalInput from "widgets/common/ConditionalInput"
import type { InstallationDetailsForm } from "./helpers"

interface DetailsSectionOneProps {
  defaultValues: InstallationDetailsForm
  isEditing: boolean
  isLoading: boolean
}

const DetailsSectionOne = ({
  defaultValues,
  isEditing,
  isLoading,
}: DetailsSectionOneProps) => {
  return (
    <>
      <ConditionalInput
        name="installationType"
        labelKey="device_installation.INSTALLATION_TYPE"
        defaultValue={defaultValues.installationType}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="orientation"
        labelKey="device_installation.ORIENTATION"
        defaultValue={defaultValues.orientation}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="anchoringPointDistance"
        type="number"
        labelKey="device_installation.ANCHORING_POINT_DISTANCE"
        defaultValue={defaultValues.anchoringPointDistance}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="voltageNominal"
        type="number"
        labelKey="device_installation.VOLTAGE_NOMINAL"
        defaultValue={defaultValues.voltageNominal}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="powerlineId"
        type="number"
        labelKey="device_installation.POWERLINE_ID"
        defaultValue={defaultValues.powerlineId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="spanId"
        type="number"
        labelKey="device_installation.SPAN_ID"
        defaultValue={defaultValues.spanId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="phaseId"
        labelKey="device_installation.PHASE_ID"
        defaultValue={defaultValues.phaseId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
    </>
  )
}

export default DetailsSectionOne
