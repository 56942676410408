import { useParams } from "react-router-dom"

import useDateRange from "helpers/hooks/useDateRange"
import AlertsContainer from "widgets/alerts/AlertContainer"
import { BodyContainer } from "widgets/styled/containers"

const DeviceEventsLog = () => {
  const { id } = useParams()
  const { deviceDateRange, dispatchDeviceDateRange, isDeviceDateRangeValid } =
    useDateRange()

  return (
    <BodyContainer disableGutters maxWidth="xl">
      <AlertsContainer
        showDatePicker={false}
        dateRange={deviceDateRange}
        dispatchDateRange={dispatchDeviceDateRange}
        isDateRangeValid={isDeviceDateRangeValid}
        devicesIds={[Number(id)]}
      />
    </BodyContainer>
  )
}

export default DeviceEventsLog
