import { useMemo } from "react"
import { useSelector } from "react-redux"

import { useOrgAlertSettingsQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { formatSettingsById } from "helpers/formatters/alertSettingsFormatters"
import { ALERT_SETTINGS_TIME } from "helpers/utils/constants"

const useAlertSettings = () => {
  const orgId = useSelector(selectCurrentOrgId)
  const {
    currentData: orgAlertSettings,
    isLoading,
    isError,
  } = useOrgAlertSettingsQuery(
    {
      org_id: orgId as number,
    },
    {
      pollingInterval: ALERT_SETTINGS_TIME,
      skip: !orgId,
    },
  )

  const orgAlertsBySettingId = useMemo(
    () => formatSettingsById(orgAlertSettings),
    [orgAlertSettings],
  )

  const orgActiveDeviceAlertSettings = useMemo(
    () =>
      orgAlertSettings?.filter(
        (setting) => !!setting.less_id && !!setting.config?.active,
      ),
    [orgAlertSettings],
  )

  return {
    orgAlertSettings,
    orgActiveDeviceAlertSettings,
    orgAlertsBySettingId,
    isLoading,
    isError,
  }
}

export default useAlertSettings
