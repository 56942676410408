import type { Paged } from "types/common.types"
import type {
  PowerlineReq,
  PowerlineRes,
  PowerlinesReq,
  PowerlinesRes,
} from "types/powerlines.types"
import { api } from "./base"

export const powerlinesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    powerlines: builder.query<Paged<PowerlinesRes>, PowerlinesReq>({
      query: ({ params }) => ({
        url: "v1/powerlines",
        params,
      }),
      providesTags: (_result, _error, { params }) => [
        { type: "Powerline" as const, id: params.org_id },
      ],
    }),
    powerline: builder.query<PowerlineRes, PowerlineReq>({
      query: ({ id, params }) => ({
        url: `v1/powerlines/${id}`,
        params,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Powerline" as const, id }],
    }),
  }),
})
