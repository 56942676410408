import { Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

interface TelephoneListProps {
  telephones: string[]
}

const TelephoneList: FC<TelephoneListProps> = ({ telephones }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Typography variant="h5" component="h5" sx={{ pt: 1, pb: 0.5 }}>
        {t("enterprise.TELEPHONES")}
      </Typography>
      {telephones?.length === 0 && (
        <Typography sx={{ color: theme.palette.grey[400], fontStyle: "oblique" }}>
          {t("organization.NO_ADDED_PHONE_NUMBERS")}
        </Typography>
      )}
      {telephones?.map((telephone) => (
        <Typography sx={{ color: theme.palette.grey[400] }} key={telephone}>
          {telephone}
        </Typography>
      ))}
    </>
  )
}

export default TelephoneList
