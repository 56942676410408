import type { LoginReq } from "./auth.types"

export enum Strength {
  veryWeak = 0,
  weak = 1,
  fair = 2,
  good = 3,
  strong = 4,
}

export enum WarningPassword {
  W_WEAK_PASS = "W_WEAK_PASS",
  W_EXPIRED_PASS = "W_EXPIRED_PASS",
}

export enum FailurePassword {
  E_WEAK_PASS = "E_WEAK_PASS",
  E_EXPIRED_PASS = "E_EXPIRED_PASS",
}

export type StrengthLabel = keyof typeof Strength
export type TWarningPassword = keyof typeof WarningPassword
export type TFailurePassword = keyof typeof FailurePassword

export interface ResetPasswordRes {
  code?: TWarningPassword
}

export interface ChangePasswordRes {
  access_token: string
  role: string
  code?: TWarningPassword
  otp_code?: string
}

export interface RecoverReq {
  username: string
  brand: string
}

export interface ResetPasswordReq extends LoginReq {
  secret: string
}

export interface ChangePasswordReq {
  username: string
  current_password: string
  new_password: string
  otp_value?: string
}

export interface IStrengthState {
  label: StrengthLabel
  score: number
  percentage: number
}
