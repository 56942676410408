import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import { Grid, Stack, Typography } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"

import { useUserUpdateMutation } from "features/api"
import ConditionalInput from "widgets/common/ConditionalInput"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import FormButtons from "widgets/common/FormButtons"
import type { UserType } from "types/users.types"
import Message from "widgets/common/Message"
import CardWithTitle from "widgets/common/CardWithTitle"
import SkeletonWrapper from "widgets/common/SkeletonWrapper"
import Avatar from "./Avatar"
import type { ProfileForm } from "./helpers"
import { getDefaultValues } from "./helpers"

interface ProfileProps {
  user: UserType | undefined
  isLoadingUser: boolean
  isErrorUser: boolean
}

const Profile = ({ user, isLoadingUser, isErrorUser }: ProfileProps) => {
  const { t } = useTranslation()
  const [userUpdate, { isLoading: isUpdating }] = useUserUpdateMutation()

  const [isEditing, setIsEditing] = useState(false)
  const username = useMemo(() => user?.username, [user])
  const defaultValues = useMemo(() => getDefaultValues(user), [user])
  const methods = useForm<ProfileForm>({
    mode: "all",
    defaultValues,
  })

  const handleOpen = useCallback(() => {
    setIsEditing(true)
  }, [])
  const handleClose = useCallback(() => {
    methods.reset()
    setIsEditing(false)
  }, [methods])

  const onSubmit = async (data: ProfileForm) => {
    if (!username) return
    const req = { username, first_name: data.firstName, last_name: data.lastName }
    return snackbarMutation({
      mutation: userUpdate(req).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.UPDATING_ITEM", {
          item: t("account_settings.PROFILE_INFO").toLowerCase(),
        }),
      ),
      getSuccessMessage: () =>
        t("success.UPDATING_ITEM", {
          item: t("account_settings.PROFILE_INFO"),
          count: 1,
          context: "female",
        }),
    })
      .then(() => {
        setIsEditing(false)
      })
      .catch(() => {})
  }

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues, methods])

  if (isErrorUser) {
    return (
      <Message
        messageKey={"error.FETCHING_ITEM"}
        messageOptions={{
          item: t("generic.USER").toLowerCase(),
        }}
        type="error"
      />
    )
  }

  return (
    <CardWithTitle
      titleKey={"account_settings.PROFILE"}
      actionPermission={true}
      isDoingAction={isEditing}
      handleAction={handleOpen}
      actionIcon={<EditIcon fontSize="small" />}
    >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Stack justifyContent={"center"} alignItems={"center"} gap={2}>
                <SkeletonWrapper
                  isWaiting={isLoadingUser}
                  variant="circular"
                  width={100}
                  height={100}
                >
                  <Avatar email={username} size={100} />
                </SkeletonWrapper>
                <SkeletonWrapper isWaiting={isLoadingUser} width={180} height={18}>
                  <Typography variant="h5" component="h5">
                    {username}
                  </Typography>
                </SkeletonWrapper>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <ConditionalInput
                    name="firstName"
                    labelKey="account_settings.FIRST_NAME"
                    defaultValue={defaultValues.firstName}
                    isEditing={isEditing}
                    isLoading={isLoadingUser}
                    orientation="vertical"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <ConditionalInput
                    name="lastName"
                    labelKey="account_settings.LAST_NAME"
                    defaultValue={defaultValues.lastName}
                    isEditing={isEditing}
                    isLoading={isLoadingUser}
                    orientation="vertical"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isEditing && (
            <FormButtons
              onClose={handleClose}
              buttonText={"generic.UPDATE"}
              isLoading={isUpdating}
            />
          )}
        </FormProvider>
      </form>
    </CardWithTitle>
  )
}

export default Profile
