import { useOutletContext } from "react-router-dom"

import Dashboard from "widgets/device/Dashboard"
import { BodyContainer } from "widgets/styled/containers"
import { devicePlotDefs } from "helpers/config/plots"

import type { DeviceDashboardOutletContext } from "types/dashboard.types"

import DevicePosition from "./DevicePosition"

function DeviceData() {
  const { id: deviceId } = useOutletContext<DeviceDashboardOutletContext>()

  const config = {
    type: "device",
    plotDefs: devicePlotDefs,
  }

  return (
    <BodyContainer disableGutters maxWidth="xl">
      <Dashboard deviceId={deviceId} config={config}>
        <DevicePosition />
      </Dashboard>
    </BodyContainer>
  )
}

export default DeviceData
