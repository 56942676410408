import { BrowserRouter as Router } from "react-router-dom"
import { useSelector } from "react-redux"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import CssBaseline from "@mui/material/CssBaseline"

import { selectTheme } from "features/store/configSlice"
import { getTheme } from "styles/themes"
import Routes from "features/Routes"
import SnackbarProvider from "widgets/snackbars/SnackbarProvider"

function App() {
  const theme = getTheme(useSelector(selectTheme))

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <SnackbarProvider>
          <Routes />
        </SnackbarProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App
