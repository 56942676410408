import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useAlertsQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { ALERT_TIME } from "helpers/utils/constants"

interface useEventsProps {
  fromDate: string | undefined
  toDate: string | undefined
  isDateRangeValid: boolean
  devicesIds?: number[]
  groupId?: number
}

const useEvents = ({
  fromDate,
  toDate,
  isDateRangeValid,
  devicesIds,
  groupId,
}: useEventsProps) => {
  const { i18n } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)

  const { data: events, isLoading } = useAlertsQuery(
    {
      params: {
        from_date: fromDate,
        to_date: toDate,
        lang: i18n.resolvedLanguage,
        org_id: Number(orgId),
        ...(devicesIds && { less_id: devicesIds }),
        ...(groupId && { group_id: Number(groupId) }),
      },
    },
    {
      skip: !isDateRangeValid || !orgId,
      pollingInterval: ALERT_TIME,
    },
  )

  return {
    events,
    isLoading,
  }
}

export default useEvents
