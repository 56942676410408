import { useTranslation } from "react-i18next"

import Title from "widgets/common/Title"
import AuditTrailTable from "widgets/organization/auditTrail/AuditTrailTable"

const AuditTrail = () => {
  const { t } = useTranslation()

  return (
    <>
      <Title text={t("audit_trail.AUDIT_TRAIL")} />
      <AuditTrailTable />
    </>
  )
}

export default AuditTrail
