import { Typography } from "@mui/material"
import type { FC } from "react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { useUpdateOrgNameMutation } from "../../features/api"
import { selectCurrentOrgId, setOrgName } from "../../features/store/orgSlice"
import type { ICustomError } from "../../helpers/errors/errorTypes"
import ErrorPopUp from "../common/ErrorPopUp"
import { getTranslationKey } from "../../helpers/utils/translations"
import EditableTitle from "../common/editable/EditableTitle"

interface IEditableOrgNameProps {
  name: string
}

const EditableOrgName: FC<IEditableOrgNameProps> = ({ name }) => {
  const orgId = useSelector(selectCurrentOrgId)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [updateOrgName, { isLoading, isSuccess, isError, error }] =
    useUpdateOrgNameMutation()
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [newOrgName, setNewOrgName] = useState<string | null>(null)

  const onSubmitHandler = (newName: string) => {
    updateOrgName({
      name: newName,
      orgId: orgId as number,
    })
    setNewOrgName(newName)
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setOrgName(String(newOrgName)))
    }
  }, [isSuccess, dispatch, newOrgName])

  useEffect(() => {
    if (isError) {
      setShowErrorPopup(true)
      const newError = error as ICustomError
      setErrorMessage(newError.data.message)
    }
  }, [isError, error])

  return (
    <>
      <EditableTitle
        text={name}
        onSubmitHandler={onSubmitHandler}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
      <ErrorPopUp open={showErrorPopup} onClose={() => setShowErrorPopup(false)}>
        <Typography>{t("organization.FAILED_ORG_SETTINGS_UPDATE")}</Typography>
        {errorMessage && t([`error.${getTranslationKey(errorMessage)}`, errorMessage])}
      </ErrorPopUp>
    </>
  )
}

export default EditableOrgName
