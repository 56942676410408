import type { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded"
import EditRoundedIcon from "@mui/icons-material/EditRounded"
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"

import { type AlertSettingType } from "../../../types/alerts.types"
import Menu from "../../common/Menu"

interface AlertSettingCardMenuProps {
  alertSetting: AlertSettingType
  setSelectedAlert: Dispatch<SetStateAction<AlertSettingType | undefined>>
  onOpen: () => void
  handleOpenDeletePopup: () => void
}

function AlertSettingCardMenu({
  alertSetting,
  setSelectedAlert,
  onOpen,
  handleOpenDeletePopup,
}: AlertSettingCardMenuProps) {
  const { t } = useTranslation()

  return (
    <>
      <Menu>
        <MenuItem
          onClick={() => {
            setSelectedAlert(alertSetting)
            onOpen()
          }}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("generic.EDIT")}</ListItemText>
        </MenuItem>
        <MenuItem
          sx={{ color: (theme) => theme.palette.error.main }}
          onClick={handleOpenDeletePopup}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>{t("generic.DELETE")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default AlertSettingCardMenu
