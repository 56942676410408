import { Grid } from "@mui/material"
import fp from "lodash/fp"

import SkeletonBox from "widgets/common/SkeletonBox"

const DeviceGroupsSkeleton = () => {
  return (
    <Grid container spacing={1} alignItems="stretch" overflow={"hidden"}>
      {fp
        .times((index) => index)(8)
        .map((group) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={group}>
            <SkeletonBox height={90} />
          </Grid>
        ))}
    </Grid>
  )
}

export default DeviceGroupsSkeleton
