import { useContext, useState } from "react"
import { Responsive, WidthProvider } from "react-grid-layout"

import { GRID_LAYOUT_GAP } from "helpers/utils/constants"
import Cell from "./Cell"
import DashboardContext from "./DashboardContext"

const GridLayout = WidthProvider(Responsive)

function DashboardLayout() {
  const { dashboard, layout } = useContext(DashboardContext)
  const lgLayout = Object.values(layout)
  const lgRowHeight = 110
  const [rowHeight, setRowHeight] = useState(lgRowHeight)

  return (
    <GridLayout
      width={1000}
      style={{
        position: "relative",
        marginRight: `-${GRID_LAYOUT_GAP}px`,
        marginLeft: `-${GRID_LAYOUT_GAP}px`,
      }}
      layouts={{ lg: lgLayout }}
      breakpoints={{
        lg: 1000,
        md: 800,
        sm: 650,
      }}
      cols={{ lg: 12, md: 6, sm: 3 }}
      margin={[GRID_LAYOUT_GAP, GRID_LAYOUT_GAP]}
      rowHeight={rowHeight}
      draggableHandle=".dragIcon"
      onBreakpointChange={(breakpoint) =>
        breakpoint === "sm" ? setRowHeight(90) : setRowHeight(lgRowHeight)
      }
    >
      {dashboard.cells.map((cell) => {
        return (
          <div key={cell.id}>
            <Cell cell={cell} />
          </div>
        )
      })}
    </GridLayout>
  )
}

export default DashboardLayout
