import { ISOTYPE } from "../../helpers/utils/constants"
import { PreloaderContainer } from "../styled/containers"
import { SvgLogo } from "../styled/widgets"

const Preloader = () => {
  return (
    <PreloaderContainer maxWidth={false}>
      <SvgLogo src={ISOTYPE} />
    </PreloaderContainer>
  )
}

export default Preloader
