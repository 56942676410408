import { useTranslation } from "react-i18next"
import { useFieldArray, useFormContext } from "react-hook-form"
import { ButtonBase, IconButton, Stack, Typography } from "@mui/material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"

import ControlledInput from "widgets/common/ControlledInput"

const TenantsForm = () => {
  const { t } = useTranslation()
  const { register, control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "tenants",
  })

  return (
    <Stack>
      <Stack sx={{ maxWidth: "400px" }}>
        {fields?.map((field, index) => {
          return (
            <Stack key={field.id} sx={{ flexDirection: "row" }}>
              <ControlledInput
                variant="outlined"
                size="small"
                {...register(`tenants.${index}.tenant`, {
                  required: t("generic.FIELD_REQUIRED"),
                })}
              />
              <IconButton color="primary" onClick={() => remove(index)}>
                <DeleteOutlineIcon color="error" sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Stack>
          )
        })}
      </Stack>
      <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 0 }}>
        <ButtonBase
          sx={{ display: "flex", gap: 1 }}
          onClick={() => {
            append({
              tenant: "",
            })
          }}
        >
          <AddCircleOutlineIcon color="primary" fontSize="small" />
          <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
            {t("auth.ADD_TENANT_ID")}
          </Typography>
        </ButtonBase>
      </Stack>
    </Stack>
  )
}

export default TenantsForm
