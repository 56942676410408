import fp from "lodash/fp"

import { filterObject } from "helpers/utils/common"
import type { DeviceType } from "types/device.types"
import type {
  AddDeviceToGroupReq,
  CreateGroupReq,
  CreateGroupRes,
  DeleteGroupReq,
  DeviceGroupReq,
  DeviceGroupRes,
  DeviceGroupsReq,
  DeviceGroupsRes,
  DeviceGroupType,
  RemoveDeviceFromGroupReq,
  RemoveDevicesFromGroupReq,
  UpdateDeviceGroupNameReq,
} from "types/group.types"

import { api } from "./base"

export const deviceGroupsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deviceGroups: builder.query<DeviceGroupsRes, DeviceGroupsReq>({
      query: ({ params }) => ({
        url: "/v1/device_groups",
        params: { describe_alerts: false, ...params },
      }),
      keepUnusedDataFor: 300,
      providesTags: (result, error, { params } = {}) => {
        const e = error as { status?: number }
        if (error) {
          return e?.status === 401 ? ["UNAUTHORIZED"] : ["ERROR"]
        }
        const res = result || []

        const groupTags = res.map(({ id }) => ({
          type: "DeviceGroup" as const,
          id,
        }))

        const type = params?.describe_devices
          ? ("Device" as const)
          : ("DeviceId" as const)

        const deviceTags = getDeviceTagsFromGroups(res, type)
        return [
          ...groupTags,
          ...deviceTags,
          {
            type: "DeviceGroup" as const,
            id: `Organization(${params?.org_id})`,
          },
        ]
      },
    }),
    deviceGroup: builder.query<DeviceGroupRes, DeviceGroupReq>({
      query: ({ id, params }) => ({
        url: `/v1/device_groups/${encodeURIComponent(id)}`,
        params: { describe_alerts: false, describe_devices: false, ...params },
      }),
      // The device_list contains internal ids instead of less_ids, ignore the device tags
      providesTags: (result, _error, { id, params = { describe_devices: false } }) => {
        const res = result ? [result] : []
        const type = params?.describe_devices
          ? ("Device" as const)
          : ("DeviceId" as const)

        const deviceTags = getDeviceTagsFromGroups(res, type)

        return [...deviceTags, { type: "DeviceGroup" as const, id }]
      },
    }),
    updateDeviceGroupName: builder.mutation<null, UpdateDeviceGroupNameReq>({
      query: ({ group_id, org_id, old_group_name, new_group_name }) => {
        const body = filterObject(
          {
            group_id,
            org_id,
            old_group_name,
            new_group_name,
          },
          (_, value) => value !== undefined,
        )
        return {
          url: "/v1/device_groups",
          method: "PUT",
          body,
        }
      },
      invalidatesTags: (_result, _error, { group_id: id }) => [
        { type: "DeviceGroup", id },
      ],
    }),
    addDeviceToGroup: builder.mutation<boolean | null, AddDeviceToGroupReq>({
      query: ({ less_id, group_id, org_id }) => ({
        url: `/v1/device_groups/${encodeURIComponent(group_id)}/devices`,
        method: "POST",
        body: { less_id, org_id },
      }),
      invalidatesTags: (_result, _error, { group_id: id }) => [
        { type: "DeviceGroup", id },
      ],
    }),
    removeDeviceFromGroup: builder.mutation<null, RemoveDeviceFromGroupReq>({
      query: ({ lessId, groupId, orgId }) => ({
        url: `/v1/device_groups/${encodeURIComponent(
          groupId,
        )}/devices/${encodeURIComponent(lessId)}`,
        method: "DELETE",
        body: { org_id: orgId },
      }),
      invalidatesTags: (_result, _error, { groupId: id }) => [
        { type: "DeviceGroup", id },
      ],
    }),
    removeDevicesFromGroup: builder.mutation<null, RemoveDevicesFromGroupReq>({
      query: ({ lessIds, groupId, orgId }) => ({
        url: `/v1/device_groups/${encodeURIComponent(groupId)}/devices`,
        method: "DELETE",
        body: { org_id: orgId, less_ids: lessIds },
      }),
      invalidatesTags: (_result, _error, { groupId: id }) => [
        { type: "DeviceGroup", id },
      ],
    }),
    createGroup: builder.mutation<CreateGroupRes, CreateGroupReq>({
      query: ({ group_name, org_id }) => ({
        url: "/v1/device_groups",
        method: "POST",
        body: { group_name, org_id },
      }),
      invalidatesTags: (_result, error, { org_id }) => {
        return !error ? [{ type: "DeviceGroup", id: `Organization(${org_id})` }] : []
      },
    }),
    deleteGroup: builder.mutation<null, DeleteGroupReq>({
      query: ({ group_name, group_id, org_id }) => ({
        url: "/v1/device_groups",
        method: "DELETE",
        body: { group_name, org_id, group_id },
      }),
      invalidatesTags: (_result, error, { group_id: id }) => {
        return !error ? [{ type: "DeviceGroup", id }] : []
      },
    }),
  }),
})

const getDeviceTagsFromGroups = (
  groups: DeviceGroupType[],
  type: "Device" | "DeviceId",
): { type: "Device" | "DeviceId"; id: number }[] =>
  // flow calls each function with the result of the previous one
  // we start with the array of groups
  fp.flow(
    // join all the lists of ids in a single array
    fp.flatMap(({ device_list }) =>
      // expand device_list to ids, either each lessId, or the deviceId (depending on the given type)
      fp.map(
        (item: DeviceType | number) =>
          type === "Device" ? (item as DeviceType).less_id : (item as number),
        device_list,
      ),
    ),
    // drop weird stuff
    fp.filter((id) => id !== undefined && id !== null),
    // drop dupes
    fp.uniq,
    // create a tag with the given type
    fp.map((id) => ({ type, id })),
  )(groups)
