import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { MuiOtpInput } from "mui-one-time-password-input"
import { Button, Stack, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import CardWithTitle from "widgets/common/CardWithTitle"
import Spinner from "../Spinner"

interface TwoFactorVerificationProps {
  onClose: () => void
  verify: (value: string) => Promise<void>
  isVerifying: boolean
  otpCode: string
  handleSwitchVerification: () => void
}

const TwoFactorVerification = ({
  onClose,
  verify,
  isVerifying,
  otpCode,
  handleSwitchVerification,
}: TwoFactorVerificationProps) => {
  const { t } = useTranslation()
  const [otp, setOtp] = useState("")

  const handleChange = useCallback(
    (value: string) => {
      setOtp(value)
    },
    [setOtp],
  )

  const handleComplete = async (value: string) => {
    try {
      return await verify(value)
    } catch {
      handleChange("")
      // nothing
    }
  }

  const handlePaste = async () => {
    await navigator.clipboard.readText().then((authCode) => {
      handleChange(authCode)
      handleComplete(authCode)
    })
  }

  return (
    <>
      <CardWithTitle
        titleKey="auth.TWO_FACTOR_AUTH"
        handleAction={onClose}
        actionIcon={<CloseIcon fontSize="small" color="action" />}
      >
        <Typography
          id="otp-verifier-description"
          variant="h6"
          component={"h6"}
          align="center"
          mb={3}
        >
          {t("auth.ENTER_AUTH_CODE")}
        </Typography>
        <Stack direction="row" justifyContent={"center"} alignItems={"center"} gap={4}>
          {isVerifying ? (
            <Spinner />
          ) : (
            <Stack gap={1} justifyContent={"center"} alignItems={"center"}>
              <MuiOtpInput
                TextFieldsProps={{ type: "number" }}
                value={otp}
                onChange={handleChange}
                onComplete={handleComplete}
                autoFocus
                length={6}
                sx={{
                  maxWidth: "350px",
                  "& .MuiTextField-root": { margin: 0 },
                  "& .MuiOutlinedInput-input": { padding: "10px 5px", height: "30px" },
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "& input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                }}
                gap={"5px"}
              />
              <Button size="small" onClick={handlePaste}>
                {t("account_settings.PASTE_CODE")}
              </Button>
            </Stack>
          )}
        </Stack>
        {otpCode && (
          <Stack justifyContent={"center"} alignItems={"center"} mt={5} gap={1}>
            <Typography>{t("auth.LOST_OTP")}</Typography>
            <Typography
              color="primary"
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={handleSwitchVerification}
            >
              {t("auth.USE_RECOVERY_CODES")}
            </Typography>
          </Stack>
        )}
      </CardWithTitle>
    </>
  )
}

export default TwoFactorVerification
