import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"

import useAlertActions from "helpers/hooks/useAlertActions"
import useAlertTypes from "helpers/hooks/useAlertTypes"
import type { AccordionAlertSetting } from "types/alerts.types"
import Menu from "widgets/common/Menu"
import AlertActionPopup from "widgets/alerts/AlertActionPopup"

interface AlertAccordionMenuProps {
  alert: AccordionAlertSetting
}

const AlertAccordionMenu = ({ alert }: AlertAccordionMenuProps) => {
  const { t } = useTranslation()
  const [actionPopupVisible, setActionPopupVisible] = useState<boolean>(false)
  const [selectedAction, setSelectedAction] = useState<{
    action: string
    actionKey: string
  }>({ action: "", actionKey: "" })
  const { deviceAlertTypes } = useAlertTypes()
  const actions = deviceAlertTypes?.find((alertType) => alertType.name === alert.type)
    ?.actions as string[]
  const { actionItems } = useAlertActions({ actions, state: alert.state })
  const alertSettingName = alert.config.custom_name as string

  return (
    <>
      {alert.state !== "notTriggered" && (
        <>
          <Menu>
            {actionItems.map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  onClick={() => {
                    setSelectedAction({ action: item.name, actionKey: item.actionKey })
                    setActionPopupVisible(true)
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{t(item.title)}</ListItemText>
                </MenuItem>
              )
            })}
          </Menu>
          <AlertActionPopup
            open={actionPopupVisible}
            onClose={() => setActionPopupVisible(false)}
            action={selectedAction}
            settingId={alert.id}
            alertName={alertSettingName}
          />
        </>
      )}
    </>
  )
}

export default AlertAccordionMenu
