import type { Paged } from "types/common.types"
import type {
  ConductorsRes,
  ConductorsReq,
  ConductorRes,
  ConductorReq,
} from "types/conductors.types"
import { api } from "./base"

export const conductorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    conductors: builder.query<Paged<ConductorsRes>, ConductorsReq>({
      query: ({ params }) => ({
        url: `v1/conductors`,
        params,
      }),
      providesTags: (_result, _error) => [{ type: "Conductor" as const }],
    }),
    conductor: builder.query<ConductorRes, ConductorReq>({
      query: ({ id }) => ({
        url: `v1/conductors/${id}`,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Conductor" as const, id }],
    }),
  }),
})
