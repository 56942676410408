import { Grid } from "@mui/material"
import fp from "lodash/fp"

import SkeletonBox from "widgets/common/SkeletonBox"

const AlertsSettingsSkeleton = () => {
  return (
    <Grid container spacing={2}>
      {fp
        .times((index) => index)(3)
        .map((setting) => {
          return (
            <Grid key={setting} item xs={12} sm={6} lg={4}>
              <SkeletonBox height={360} />
            </Grid>
          )
        })}
    </Grid>
  )
}

export default AlertsSettingsSkeleton
