import { Box, Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material"
import { useMemo } from "react"
import fp from "lodash/fp"

import { PlotContainer } from "widgets/styled/containers"

const PieChartSkeleton = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const surfaceSize = useMemo(() => {
    const size = { length: 250, lengthMobile: 180 }
    return isMobile ? size.lengthMobile : size.length
  }, [isMobile])

  const pieSize = useMemo(() => surfaceSize / 1.13, [surfaceSize])

  return (
    <PlotContainer maxWidth="xl" type={"pie"}>
      <Box style={{ display: "flex", justifyContent: "space-between" }} mb={2.5}>
        <Skeleton variant="rounded" width={150} />
      </Box>
      <Box
        sx={{
          height: "calc(100% - 1rem)",
        }}
      >
        <Stack direction={"row"} justifyContent="center" alignItems="center" gap={2}>
          <Stack
            width={surfaceSize}
            height={surfaceSize}
            justifyContent={"center"}
            alignItems="center"
          >
            <Skeleton variant="circular" width={pieSize} height={pieSize} />
          </Stack>
          <Stack gap={1} width={120}>
            {fp
              .times((index) => index)(4)
              .map((label) => (
                <Skeleton key={label} variant="rounded" />
              ))}
          </Stack>
        </Stack>
      </Box>
    </PlotContainer>
  )
}

export default PieChartSkeleton
