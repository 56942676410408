import { useMemo } from "react"
import { useTheme } from "@mui/material"
import type { Config } from "@influxdata/giraffe"

import type { CellViewRes, RecordService, RecordType } from "types/dashboard.types"

import lineConfig from "./giraffeLine"

interface UseGiraffeConfig {
  view: CellViewRes
  data: RecordType[]
  recordService: RecordService
}
interface GraphConfig {
  containerStyles: object
  configuration: Config
}

export default function useGiraffeConfig({
  view,
  data,
  recordService,
}: UseGiraffeConfig) {
  const theme = useTheme()

  const graphConfig: GraphConfig = useMemo(
    () =>
      lineConfig({
        view,
        data,
        recordService,
        palette: theme.palette,
      }),
    [data, view, recordService, theme.palette],
  )

  return graphConfig
}
