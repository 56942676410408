import { useOutletContext } from "react-router-dom"
import { Grid } from "@mui/material"

import Profile from "widgets/user/Profile"
import type { UserType } from "types/users.types"
import { BodyContainer } from "widgets/styled/containers"

const AccountGeneral = () => {
  const { user, isLoadingUser, isErrorUser } = useOutletContext<{
    user: UserType | undefined
    isLoadingUser: boolean
    isErrorUser: boolean
  }>()

  return (
    <BodyContainer disableGutters maxWidth="xl" sx={{ pt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Profile user={user} isLoadingUser={isLoadingUser} isErrorUser={isErrorUser} />
        </Grid>
      </Grid>
    </BodyContainer>
  )
}

export default AccountGeneral
