import type { TFunction } from "react-i18next"
import { IconButton, Stack, Tooltip } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import type { Dictionary } from "lodash"

import type { UserOrgRole, UsersTableRow } from "types/users.types"
import type { USER_ROLES } from "helpers/utils/constants"

export const getUsersTableColumns = (
  t: TFunction,
  handleOpenEdit: (username: string, role: keyof typeof USER_ROLES) => void,
  handleOpenDelete: (username: string) => void,
) => {
  return [
    {
      name: "username",
      label: t("generic.USERNAME"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "role",
      label: t("generic.ROLE"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (role: string) => t(`roles.${role.toUpperCase()}`),
      },
    },
    {
      name: "actions",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: () => (
          <div style={{ display: "flex", justifyContent: "end" }}>
            {t("generic.ACTIONS")}
          </div>
        ),
        setCellProps: () => ({ style: { display: "flex", justifyContent: "end" } }),
        customBodyRender: ({
          username,
          role,
        }: {
          username: string
          role: keyof typeof USER_ROLES
        }) => (
          <Stack direction={"row"} gap={1}>
            <Tooltip title={t("generic.EDIT")}>
              <IconButton size="small" onClick={() => handleOpenEdit(username, role)}>
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("generic.REMOVE")}>
              <IconButton size="small" onClick={() => handleOpenDelete(username)}>
                <DeleteIcon fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
    },
  ]
}

export const getUsersTableRows = (users: UserOrgRole[]) => {
  return users.map((user) => ({
    username: user.username,
    role: user.role,
    actions: { username: user.username, role: user.role },
  }))
}

export const formatUsersTableCsv = (
  data: { index: number; data: UsersTableRow }[],
  columnsIndexes: Dictionary<number>,
  t: TFunction,
) => {
  return data?.map((row, index) => ({
    index,
    data: row?.data?.map((field: any, index) => {
      // field can be any of the types of UsersTableRow
      // array order comes from columns definition
      switch (index) {
        case columnsIndexes.role:
          return t(`roles.${field.toUpperCase()}`)
        case columnsIndexes.actions:
          return ""
        default:
          return field
      }
    }),
  }))
}
