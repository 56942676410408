import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { DateTime } from "luxon"

import CustomLink from "widgets/common/CustomLink"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <Box
      component={"footer"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      my={3}
    >
      <CustomLink href={"/terms-and-conditions/"} target="_blank" underlineHover>
        {t("generic.TERMS_AND_CONDITIONS")}
      </CustomLink>
      <CustomLink
        href={"https://www.sentrisense.com/privacy-policy/"}
        target="_blank"
        underlineHover
      >
        {t("generic.PRIVACY_POLICY")}
      </CustomLink>
      <Typography
        variant="body2"
        align="center"
        component="p"
        sx={{ color: (theme) => theme.palette.grey[400] }}
      >
        &copy; {DateTime.now().year} - Sentrisense
      </Typography>
      <Typography
        variant="body2"
        align="center"
        component="p"
        sx={{ color: (theme) => theme.palette.grey[400] }}
      >
        Powering up a greener future
      </Typography>
    </Box>
  )
}

export default Footer
