import type { ElementType, HTMLAttributeAnchorTarget, PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import { Typography } from "@mui/material"
import type { SxProps, TypographyTypeMap } from "@mui/material"

interface CustomLinkProps {
  component?: ElementType
  variant?: TypographyTypeMap["props"]["variant"]
  href: string
  hover?: boolean
  underlineHover?: boolean
  bold?: boolean
  target?: HTMLAttributeAnchorTarget
  styles?: SxProps
}

const CustomLink = ({
  children,
  component = "p",
  variant = "body1",
  href,
  hover = false,
  underlineHover = false,
  bold = false,
  target = "_self",
  styles,
}: PropsWithChildren<CustomLinkProps>) => {
  return (
    <Link
      target={target}
      to={href}
      style={{
        textDecoration: "none",
      }}
    >
      <Typography
        component={component}
        variant={variant}
        color={(theme) =>
          hover ? theme.palette.text.primary : theme.palette.primary.main
        }
        sx={{
          fontWeight: bold ? 600 : 400,
          "&:hover": {
            color: (theme) => theme.palette.primary.main,
            textDecoration: underlineHover ? "underline" : "none",
          },
          ...styles,
        }}
      >
        {children}
      </Typography>
    </Link>
  )
}

export default CustomLink
