import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import type { Theme } from "@mui/material"
import { useMediaQuery, Stack, Typography } from "@mui/material"
import LoopIcon from "@mui/icons-material/Loop"

import type { UserOTPRecoverCodes } from "types/users.types"
import Message from "widgets/common/Message"
import LoadingButton from "widgets/common/LoadingButton"
import SkeletonWrapper from "widgets/common/SkeletonWrapper"
import OTPRecoverCodesModal from "./OTPRecoverCodesModal"

interface RecoveryCodesConfigProps {
  user: {
    username: string
    enabled: boolean
    remainingRecoveryCodes: number
    isLoadingUser: boolean
    isErrorUser: boolean
  }
  recoverCodesModal: {
    openRecoverCodesModal: boolean
    handleRecoverCodesClose: () => void
  }
  generateRecoverCodes: () => Promise<void>
  otpRecoverCodes: UserOTPRecoverCodes | undefined
  isGenerating: boolean
}

const RecoveryCodesConfig = ({
  user,
  recoverCodesModal,
  generateRecoverCodes,
  otpRecoverCodes,
  isGenerating,
}: RecoveryCodesConfigProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const { username, enabled, remainingRecoveryCodes, isLoadingUser, isErrorUser } =
    useMemo(() => user, [user])
  const { openRecoverCodesModal, handleRecoverCodesClose } = useMemo(
    () => recoverCodesModal,
    [recoverCodesModal],
  )

  const handleRegenerate = async () => {
    try {
      await generateRecoverCodes()
    } catch {
      // ignore
    }
  }

  if (isErrorUser) {
    return (
      <Message
        messageKey={"error.FETCHING_ITEM"}
        messageOptions={{
          item: t("generic.USER").toLowerCase(),
        }}
        type="error"
      />
    )
  }

  return (
    <>
      <Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          p={4}
          gap={4}
        >
          <Stack>
            <Typography component={"h4"} variant="h4" mb={1}>
              {t("account_settings.RECOVERY_CODES")}
            </Typography>
            <Stack direction={"row"}>
              <Typography>
                {t("account_settings.RECOVERY_CODES_REMAINING")}
                {":"}&nbsp;
              </Typography>
              <SkeletonWrapper isWaiting={isLoadingUser} width={15}>
                <Typography>{remainingRecoveryCodes}</Typography>
              </SkeletonWrapper>
            </Stack>
            <Typography>{t("account_settings.IF_LOSE_KEYS")}</Typography>
          </Stack>
          <Stack alignItems={"center"} justifyContent={"center"}>
            <SkeletonWrapper isWaiting={isLoadingUser}>
              <LoadingButton
                type="button"
                loading={isGenerating}
                variant="outlined"
                icon={<LoopIcon fontSize="small" />}
                disabled={!enabled}
                onClick={handleRegenerate}
              >
                {t("account_settings.REGENERATE")}
              </LoadingButton>
            </SkeletonWrapper>
          </Stack>
        </Stack>
      </Stack>
      <OTPRecoverCodesModal
        open={openRecoverCodesModal}
        onClose={handleRecoverCodesClose}
        username={username}
        data={otpRecoverCodes}
      />
    </>
  )
}

export default RecoveryCodesConfig
