import type { MUISortOptions } from "mui-datatables"

import type { DeviceGroupRefType } from "types/group.types"

export const getGroupsNames = (groups: DeviceGroupRefType[]) =>
  groups.map(({ name }) => name).join(" - ")

export const getGroupsString = (groups: DeviceGroupRefType[]) =>
  groups?.length > 0 ? getGroupsNames(groups) : " - "

export const defaultSort = (order: MUISortOptions["direction"], keyName = "name") => {
  return (obj1: { data: any }, obj2: { data: any }) => {
    const obj1Name = obj1.data[keyName] ?? ""
    const obj2Name = obj2.data[keyName] ?? ""
    return order === "asc"
      ? obj1Name.localeCompare(obj2Name)
      : obj2Name.localeCompare(obj1Name)
  }
}
