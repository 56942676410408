import ConditionalInput from "widgets/common/ConditionalInput"
import type { InstallationDetailsForm } from "./helpers"

interface DetailsSectionTwoProps {
  defaultValues: InstallationDetailsForm
  isEditing: boolean
  isLoading: boolean
}

const DetailsSectionTwo = ({
  defaultValues,
  isEditing,
  isLoading,
}: DetailsSectionTwoProps) => {
  return (
    <>
      <ConditionalInput
        name="towerOneId"
        type="number"
        labelKey="device_installation.TOWER_N_ID"
        labelOptions={{ number: 1 }}
        defaultValue={defaultValues.towerOneId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerOneHeight"
        type="number"
        labelKey="device_installation.HEIGHT_TOWER_N"
        labelOptions={{ number: 1 }}
        defaultValue={defaultValues.towerOneHeight}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerOneGeolocation"
        labelKey="device_installation.TOWER_N_LOCATION"
        labelOptions={{ number: 1 }}
        defaultValue={defaultValues.towerOneGeolocation}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerTwoId"
        type="number"
        labelKey="device_installation.TOWER_N_ID"
        labelOptions={{ number: 2 }}
        defaultValue={defaultValues.towerTwoId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerTwoHeight"
        type="number"
        labelKey="device_installation.HEIGHT_TOWER_N"
        labelOptions={{ number: 2 }}
        defaultValue={defaultValues.towerTwoHeight}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerTwoGeolocation"
        labelKey="device_installation.TOWER_N_LOCATION"
        labelOptions={{ number: 2 }}
        defaultValue={defaultValues.towerTwoGeolocation}
        isEditing={isEditing}
        isLoading={isLoading}
      />
    </>
  )
}

export default DetailsSectionTwo
