import type { Middleware, PayloadAction, Reducer } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import type { RootState } from "."

interface IDefaultSort {
  name: string
  direction: "asc" | "desc"
}

interface IDefaultTable {
  qtyRows: number
  sortOrder: IDefaultSort
}

interface TablesState {
  alertsTable: IDefaultTable
  devicesTable: IDefaultTable
}

const initialState: TablesState = {
  alertsTable: {
    qtyRows: 10,
    sortOrder: { name: "timestamp", direction: "desc" },
  },
  devicesTable: {
    qtyRows: 10,
    sortOrder: { name: "alertStatus", direction: "desc" },
  },
}

function getPersistentTables() {
  // Lazy load the state from the localStorage or fallback to the initialState
  try {
    const state = JSON.parse(localStorage.getItem("tables") as string)
    const { alertsTable, devicesTable } = state

    return fp.merge(initialState, {
      alertsTable,
      devicesTable,
    })
  } catch (error) {
    return initialState
  }
}

export const tablesSlice = createSlice({
  name: "tables",
  initialState: getPersistentTables,
  reducers: {
    /*-- Alerts table --*/
    setAlertsTableRowsAmount: (state, action: PayloadAction<number>): TablesState => ({
      ...state,
      alertsTable: { ...state.alertsTable, qtyRows: action.payload },
    }),
    setAlertsTableSortOrder: (
      state,
      action: PayloadAction<IDefaultSort>,
    ): TablesState => ({
      ...state,
      alertsTable: { ...state.alertsTable, sortOrder: action.payload },
    }),

    /*-- Devices table --*/
    setDevicesTableRowsAmount: (state, action: PayloadAction<number>): TablesState => ({
      ...state,
      devicesTable: { ...state.devicesTable, qtyRows: action.payload },
    }),
    setDevicesTableSortOrder: (
      state,
      action: PayloadAction<IDefaultSort>,
    ): TablesState => ({
      ...state,
      devicesTable: { ...state.devicesTable, sortOrder: action.payload },
    }),
  },
})

export const {
  setAlertsTableRowsAmount,
  setAlertsTableSortOrder,
  setDevicesTableRowsAmount,
  setDevicesTableSortOrder,
} = tablesSlice.actions
export const tablesReducer: Reducer<TablesState> = tablesSlice.reducer
export default tablesReducer

export const selectAlertsTable = (state: RootState) => state.tables.alertsTable
export const selectDevicesTable = (state: RootState) => state.tables.devicesTable

// Store the table in localStorage, while keeping the reducers as pure functions
export const tablesMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action)
  switch (action.type) {
    case "tables/setAlertsTableRowsAmount":
    case "tables/setDevicesTableRowsAmount": {
      const tableState = store.getState()

      const tables = {
        alertsTable: {
          qtyRows: tableState.tables.alertsTable.qtyRows,
        },
        devicesTable: {
          qtyRows: tableState.tables.devicesTable.qtyRows,
        },
      }
      localStorage.setItem("tables", JSON.stringify(tables))
      break
    }
    default:
      break
  }
  return result
}
