import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Tabs from "@mui/material/Tabs"

import type { Tab } from "types/tabs.types"

import { getTabValue } from "helpers/config/tabsConfigs"
import { TabsContainer } from "widgets/styled/containers"
import { LinkTab } from "widgets/styled/widgets"

interface LinkTabsSectionProps {
  tabsConfig: Tab[]
}

const LinkTabsSection = ({ tabsConfig }: LinkTabsSectionProps) => {
  const location = useLocation()
  const { t } = useTranslation()

  const tabValue = useMemo(() => {
    const path = location.pathname
    return getTabValue(path, tabsConfig)
  }, [location.pathname, tabsConfig])

  return (
    <TabsContainer>
      <Tabs
        value={tabValue}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabsConfig.map((tab) => (
          <LinkTab
            key={tab.value}
            label={t(tab.labelKey)}
            value={tab.value}
            to={tab.to}
            replace
          />
        ))}
      </Tabs>
    </TabsContainer>
  )
}

export default LinkTabsSection
