import type { FC } from "react"
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import type {
  Control,
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from "react-hook-form"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import type { ThresholdsField } from "../../../types/alerts.types"
import type { InputRulesType } from "../../common/ControlledInput"
import ControlledInput from "../../common/ControlledInput"
import RangeInput from "./RangeInput"
import { getRangeDropdownOptions } from "./thresholds"

// import { useTranslation } from "react-i18next"

interface ThresholdInputProps {
  update: UseFieldArrayUpdate<FieldValues, "thresholds">
  remove: UseFieldArrayRemove
  rules?: InputRulesType
  statusDropdownOptions: { value: string; label: string }[] | undefined
  index?: number
  control: Control<FieldValues, any>
  value: ThresholdsField
}

const ThresholdInput: FC<ThresholdInputProps> = ({
  remove,
  index,
  rules,
  statusDropdownOptions,
}) => {
  const theme = useTheme()
  const { watch, register } = useFormContext()
  const { t } = useTranslation()

  const { rangeDropdownOptions, defaultRangeCondition } = getRangeDropdownOptions(t)
  const defaultAlertStatus = statusDropdownOptions && statusDropdownOptions[0].label
  const currentRangeCondition =
    watch(`thresholds.${index}.rangeCondition`) || defaultRangeCondition
  const currentAlertStatus =
    watch(`thresholds.${index}.alertStatus`) || defaultAlertStatus

  return (
    <>
      <Stack sx={{ flexDirection: "row", justifyContent: "flex-end", gap: 2 }}>
        <IconButton color="primary" onClick={() => remove(index)}>
          <CloseIcon sx={{ fontSize: "1rem" }} />
        </IconButton>
      </Stack>
      <Stack sx={{ gap: 1 }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 2 }}>
          <Typography sx={{ whiteSpace: "nowrap" }}>
            {t("alert_settings.WHEN_VALUE")}
          </Typography>
          <ControlledInput
            type="select"
            placeholder=""
            {...register(`thresholds.${index}.rangeCondition`)}
            // name={`thresholds[${index}].rangeCondition`}
            selectItems={rangeDropdownOptions}
            defaultValue={defaultRangeCondition}
            variant="outlined"
            rules={rules}
          />
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            width: "100%",
            gap: 2,
            alignItems: "center",
          }}
        >
          <RangeInput rangeCondition={currentRangeCondition} index={index} />
          <Box sx={{ width: "fit-content" }}>
            <Typography sx={{ whiteSpace: "nowrap" }}>
              {t("alert_settings.SET_STATUS_TO")}
            </Typography>
          </Box>
          <Box sx={{ maxWidth: "100px", width: "30%" }}>
            <ControlledInput
              type="select"
              placeholder="Status"
              // name={`alertStatus[${index}]`}
              selectItems={statusDropdownOptions}
              defaultValue={defaultAlertStatus}
              {...register(`thresholds.${index}.alertStatus`)}
              variant="outlined"
              styles={{
                "& .MuiInputBase-input": {
                  background:
                    currentAlertStatus === "ALERT"
                      ? theme.palette.error.main
                      : theme.palette.secondary.main,
                  fontWeight: 700,
                },
              }}
              rules={rules}
            />
          </Box>
        </Stack>
      </Stack>
    </>
  )
}

export default ThresholdInput
