import type { Dispatch, SetStateAction } from "react"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import type { Theme } from "@mui/material"
import { CircularProgress, Stack, Switch, Typography, useMediaQuery } from "@mui/material"

import type { UserOTPRegisterRes } from "types/users.types"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import Message from "widgets/common/Message"
import { useUserOTPRegisterMutation } from "features/api"
import SkeletonWrapper from "widgets/common/SkeletonWrapper"
import OTPRegisterModal from "./OTPRegisterModal"
import OTPDisableModal from "./OTPDisableModal"

interface TwoFactorAuthConfigProps {
  user: {
    username: string
    enabled: boolean
    isLoadingUser: boolean
    isErrorUser: boolean
  }
  registerModal: {
    openRegisterModal: boolean
    setOpenRegisterModal: Dispatch<SetStateAction<boolean>>
  }
  onComplete: () => Promise<void>
  isGenerating: boolean
}

const TwoFactorAuthConfig = ({
  user,
  registerModal,
  onComplete,
  isGenerating,
}: TwoFactorAuthConfigProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const [openOtpDisableModal, setOpenOtpDisableModal] = useState(false)
  const [otpRegisterData, setOtpRegisterData] = useState<UserOTPRegisterRes | undefined>(
    undefined,
  )
  const { username, enabled, isLoadingUser, isErrorUser } = useMemo(() => user, [user])
  const { openRegisterModal, setOpenRegisterModal } = useMemo(
    () => registerModal,
    [registerModal],
  )

  const [otpRegister, { isLoading: isRegistering }] = useUserOTPRegisterMutation()

  const handleDisableClose = useCallback(() => setOpenOtpDisableModal(false), [])

  const handleOTPSwitch = useCallback(async () => {
    try {
      if (!enabled) {
        const req = { username }
        const res = await snackbarMutation({
          mutation: otpRegister(req).unwrap(),
          getErrorMessage: buildGetErrorMessage(
            t("error.ENABLING_ITEM", {
              item: t("account_settings.TWO_FA").toLowerCase(),
              count: 1,
              context: "female",
            }),
          ),
        })
        setOtpRegisterData(res)
        setOpenRegisterModal(true)
        return res
      } else {
        setOpenOtpDisableModal(true)
      }
    } catch {
      // nothing
    }
  }, [enabled, username, otpRegister, t, setOpenRegisterModal])

  const handleRegisterClose = () => {
    setOpenRegisterModal(false)
  }

  const handleRegisterComplete = async () => {
    try {
      await onComplete()
    } catch {
      // ignore
    }
  }

  const disabled = useMemo(
    () => isRegistering || isGenerating,
    [isGenerating, isRegistering],
  )

  if (isErrorUser) {
    return (
      <Message
        messageKey={"error.FETCHING_ITEM"}
        messageOptions={{
          item: t("generic.USER").toLowerCase(),
          context: "male",
          count: 1,
        }}
        type="error"
      />
    )
  }

  return (
    <>
      <Stack borderBottom={1} borderColor={(theme) => theme.palette.neutral[400]}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          p={4}
          gap={4}
        >
          <Stack>
            <Typography component={"h4"} variant="h4" mb={1}>
              {t("account_settings.TWO_FA")}
            </Typography>
            <Typography>{t("account_settings.SECURE_ACCOUNT")}</Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            {isRegistering && <CircularProgress size={30} />}
            <SkeletonWrapper isWaiting={isLoadingUser}>
              <Switch
                size="small"
                checked={enabled}
                onChange={handleOTPSwitch}
                disabled={disabled}
              />
            </SkeletonWrapper>
          </Stack>
        </Stack>
      </Stack>
      <OTPRegisterModal
        open={openRegisterModal}
        onClose={handleRegisterClose}
        onComplete={handleRegisterComplete}
        username={username}
        data={otpRegisterData}
      />
      <OTPDisableModal
        open={openOtpDisableModal}
        onClose={handleDisableClose}
        setOtpRegisterData={setOtpRegisterData}
        username={username}
      />
    </>
  )
}

export default TwoFactorAuthConfig
