import type { FC, Ref } from "react"
import { forwardRef, useState } from "react"
import { Box, IconButton, InputAdornment, type InputLabelProps } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import type {
  ChangeHandler,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form"
import ControlledInput from "./ControlledInput"

export type InputRulesType<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<
  RegisterOptions<TFieldValues, TName>,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
>

interface ControlledPasswordInputProps {
  defaultValue?: string | number | any[] | boolean
  name: string
  label?: string
  placeholder?: string
  ref?: Ref<HTMLInputElement> | null
  required?: boolean
  rules?: InputRulesType
  size?: "small" | "medium" | undefined
  styles?: object
  onBlur?: ChangeHandler
  onFocus?: () => void
  variant?: "standard" | "filled" | "outlined" | undefined
  inputLabelProps?: Partial<InputLabelProps>
  "aria-label"?: React.ComponentProps<"input">["aria-label"]
}

const ControlledPasswordInput: FC<ControlledPasswordInputProps> = forwardRef(
  function ControlledInputInner(
    {
      defaultValue,
      name,
      label = "",
      placeholder = "",
      rules,
      size,
      styles,
      onBlur,
      onFocus,
      variant = "standard",
      inputLabelProps,
      "aria-label": ariaLabel = "password",
      ...props
    }: ControlledPasswordInputProps,
    ref: Ref<HTMLInputElement> | null,
  ) {
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
      if (onFocus) {
        onFocus()
      }
    }

    return (
      <Box width={"100%"}>
        <ControlledInput
          aria-label={ariaLabel}
          {...{
            ref,
            name,
            label,
            placeholder,
            defaultValue,
            variant,
            styles,
            rules,
            size,
            inputLabelProps,
            onFocus: onFocus ?? (() => {}),
            onBlur: onBlur ?? (() => {}),
            type: showPassword ? "text" : "password",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            ...props,
          }}
        />
      </Box>
    )
  },
)

export default ControlledPasswordInput
