import fp from "lodash/fp"

import type { IPlotDef } from "types/dashboard.types"
import { darkTheme } from "styles/themes"
import { pieView } from "./base"

const devicesStatusPlotView = fp.merge(pieView, {
  name: "Devices by status",
  nameKey: "DEVICES_BY_STATUS",
  properties: {
    colors: [
      { value: "active", hex: darkTheme.palette.primary.main },
      { value: "reporting", hex: darkTheme.palette.secondary.main },
      { value: "stopped", hex: darkTheme.palette.warning.main },
      { value: "inactive", hex: darkTheme.palette.grey[400] },
    ],
    tooltip: [
      "device.REPORTING_STATUS_WITH_TITLE",
      "device.ACTIVE_STATUS_WITH_TITLE",
      "device.STOPPED_STATUS_WITH_TITLE",
      "device.INACTIVE_STATUS_WITH_TITLE",
    ],
  },
})

export const devicesStatusPlotDef: IPlotDef = {
  view: devicesStatusPlotView,
  draggable: true,
  reqConfig: {},
}

const devicesAlertStatusPlotView = fp.merge(pieView, {
  name: "Devices by alert status",
  nameKey: "DEVICES_BY_ALERT_STATUS",
  properties: {
    colors: [
      { value: "triggered", hex: darkTheme.palette.error.main },
      {
        value: "notTriggered",
        hex: darkTheme.palette.secondary.main,
      },
    ],
  },
})

export const devicesAlertStatusPlotDef: IPlotDef = {
  view: devicesAlertStatusPlotView,
  draggable: true,
  reqConfig: {},
}

const devicesBatteryLevelPlotView = fp.merge(pieView, {
  name: "Devices by battery level",
  nameKey: "DEVICES_BY_BATTERY_LEVEL",
  properties: {
    colors: [
      { value: "batteryOk", hex: darkTheme.palette.secondary.main },
      { value: "batteryWarning", hex: darkTheme.palette.warning.main },
      { value: "batteryError", hex: darkTheme.palette.error.main },
    ],
  },
})

export const devicesBatteryLevelPlotDef: IPlotDef = {
  view: devicesBatteryLevelPlotView,
  draggable: true,
  reqConfig: {},
}
