import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { useUpdateOrgAlertStateMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"

import ConfirmationPopup from "../common/ConfirmationPopup"

interface AlertActionPopupProps {
  open: boolean
  onClose: () => void
  action: { action: string; actionKey: string }
  settingId: number
  alertName: string
}

const AlertActionPopup = ({
  open,
  onClose,
  action,
  settingId,
  alertName,
}: AlertActionPopupProps) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const [updateState, { isLoading, reset }] = useUpdateOrgAlertStateMutation()

  const handleUpdateState = () => {
    const data = {
      orgId: Number(orgId),
      id: settingId,
      action: action.action,
    }
    snackbarMutation({
      mutation: updateState(data).unwrap(),
      getErrorMessage: buildGetErrorMessage(t("error.OCCURRED")),
    })
      .then(() => {
        onClose()
        reset()
      })
      .catch()
  }

  return (
    <ConfirmationPopup
      open={open}
      onClose={onClose}
      description={t("alerts.ARE_YOU_SURE_TO_MARK_ALERT_SETTING", {
        alertName,
        actionKey: t(action.actionKey).toLowerCase(),
      })}
      mainButtonText={t("generic.ACCEPT")}
      onConfirm={handleUpdateState}
      isLoading={isLoading}
    />
  )
}

export default AlertActionPopup
