import type { CSSProperties } from "react"
import { useTranslation } from "react-i18next"
import type { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables"

import { DataTable } from "widgets/styled/widgets"

interface IGenericTableProps {
  rows: Array<object | number[] | string[]>
  columns: MUIDataTableColumn[]
  title?: string
  options: MUIDataTableOptions
  styles?: CSSProperties
}

function GenericTable({
  rows,
  columns,
  title,
  options = {},
  styles = {},
}: IGenericTableProps) {
  const { t } = useTranslation()

  return (
    <DataTable
      styles={{
        ...styles,
      }}
      title={title ? t(title) : ""}
      data={rows}
      columns={columns}
      options={{
        filter: false,
        responsive: "standard",
        selectableRows: "none",
        rowsPerPage: 10,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        textLabels: {
          body: {
            noMatch: t("table.NO_MATCH"),
            toolTip: t("table.SORT"),
            columnHeaderTooltip: (column) => `${t("table.SORT_BY")} ${column.label}`,
          },
          pagination: {
            next: t("table.NEXT_PAGE"),
            previous: t("table.PREVIOUS_PAGE"),
            rowsPerPage: t("table.ROWS_PER_PAGE") + ":",
            displayRows: t("table.OF"),
          },
          toolbar: {
            search: t("table.SEARCH"),
            downloadCsv: t("table.DOWNLOAD_CSV"),
            print: t("table.PRINT"),
            viewColumns: t("table.VIEW_COLUMNS"),
            filterTable: t("table.FILTER_TABLE"),
          },
          filter: {
            all: t("table.ALL"),
            title: t("table.FILTERS"),
            reset: t("table.RESET"),
          },
          viewColumns: {
            title: t("table.VIEW_COLUMNS_TITLE"),
            titleAria: t("table.VIEW_COLUMNS_TITLE_ARIA"),
          },
          selectedRows: {
            text: t("table.SELECTED_ROWS"),
            delete: t("table.DELETE"),
            deleteAria: t("table.DELETE_ARIA"),
          },
        },
        ...options,
      }}
    />
  )
}

export default GenericTable
