import type { FC, PropsWithChildren } from "react"
import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import PopUp from "./PopUp"

interface IErrorPopupProps extends PropsWithChildren<any> {
  onClose: () => void
  open: boolean
}

const ErrorPopUp: FC<IErrorPopupProps> = ({ onClose, open, children }) => {
  return (
    <PopUp open={open} onClose={onClose}>
      <Alert
        severity={"error"}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ p: "1rem" }}
      >
        {children}
      </Alert>
    </PopUp>
  )
}

export default ErrorPopUp
