import type {
  DeviceReq,
  DeviceRes,
  DevicesReq,
  DevicesRes,
  UpdateDeviceNameReq,
  CreateDeviceReq,
  SellDeviceReq,
  SellDeviceRes,
  ActivateDeviceReq,
  DeleteDeviceReq,
} from "types/device.types"

import { api } from "./base"

export const devicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    device: builder.query<DeviceRes, DeviceReq>({
      query: ({ id }) => ({
        url: `/v1/devices/${encodeURIComponent(id)}`,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Device" as const, id }],
    }),
    devices: builder.query<DevicesRes, DevicesReq>({
      query: ({ params }) => ({
        url: "/v1/devices",
        params,
      }),
      keepUnusedDataFor: 300,
      providesTags: (result, _error, { params }) => {
        const { org_id: orgId } = params || {}
        const devices = result || []
        const deviceIds = devices.map(({ id }) => ({
          type: "DeviceId" as const,
          id,
        }))
        const deviceLessIds = devices.map(({ less_id: lessId }) => ({
          type: "Device" as const,
          id: lessId,
        }))
        return [
          ...deviceIds,
          ...deviceLessIds,
          { type: "Device" as const, id: `Organization(${orgId})` },
        ]
      },
    }),
    updateDeviceName: builder.mutation<null, UpdateDeviceNameReq>({
      query: ({ less_id, name }) => ({
        url: "/v1/devices",
        method: "PUT",
        body: { less_id, name },
      }),
      invalidatesTags: (_result, _error, { less_id: id }) => [{ type: "Device", id }],
    }),

    // device creation using the reseller flow
    // (create, sell, activate, delete if failed)
    createDevice: builder.mutation<null, CreateDeviceReq>({
      query: ({ lessId, deviceName, deviceType, settings }) => ({
        url: `/v1/devices/${encodeURIComponent(lessId)}`,
        method: "POST",
        body: { less_id: lessId, name: deviceName, device_type: deviceType, settings },
      }),
      invalidatesTags: (_result, error, { orgId }) => {
        return !error ? [{ type: "Device", id: `Organization(${orgId})` }] : []
      },
    }),
    sellDevice: builder.mutation<SellDeviceRes, SellDeviceReq>({
      query: ({ lessId }) => ({
        url: `/v1/devices/${encodeURIComponent(lessId)}/sales`,
        method: "POST",
      }),
    }),
    activateDevice: builder.mutation<null, ActivateDeviceReq>({
      query: ({ lessId, qrId, groupId, deviceName, orgId }) => ({
        url: `/v1/devices/${encodeURIComponent(lessId)}/activate`,
        method: "POST",
        body: { qr_id: qrId, group_id: groupId, device_name: deviceName, org_id: orgId },
      }),
      invalidatesTags: (_result, error, { orgId, groupId }) => {
        return !error
          ? [
              { type: "Device", id: `Organization(${orgId})` },
              { type: "DeviceGroup", id: groupId },
            ]
          : []
      },
    }),
    deleteDevice: builder.mutation<null, DeleteDeviceReq>({
      query: ({ lessId }) => ({
        url: `/v1/devices/${encodeURIComponent(lessId)}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, error, { lessId }) => {
        return !error ? [{ type: "Device", id: lessId }] : []
      },
    }),
  }),
})
