import fp from "lodash/fp"
import type { AuthMechanismKey } from "types/orgs.types"

export const authMechanismsTranslations = {
  Password: "PASSWORD",
  "2FA": "2FA",
  OAuth: "OAUTH",
  OAuthGoogle: "OAUTH_GOOGLE",
  OAuthMicrosoft: "OAUTH_MICROSOFT",
  OAuthMicrosoftWithTenant: "OAUTH_MICROSOFT_WITH_TENANT",
}

export const allAuthMechanisms: AuthMechanismKey[] = [
  "Password",
  "2FA",
  "OAuth",
  "OAuthGoogle",
  "OAuthMicrosoft",
  "OAuthMicrosoftWithTenant",
]

export const getDefaultValues = (
  authMechanismsTypes: AuthMechanismKey[],
  tenants: string[] | undefined,
) => {
  const authMechs = fp.fromPairs(
    fp.map((key) => [key, fp.includes(key, authMechanismsTypes)], allAuthMechanisms),
  ) as { [key in AuthMechanismKey]: boolean }
  const tenantValues = tenants?.map((tenant: string) => ({ tenant }))
  return { ...authMechs, tenants: tenantValues }
}
