import { useTranslation } from "react-i18next"
import { Grid, Typography } from "@mui/material"
import { useMemo } from "react"

interface LabelValueStackProps {
  labelKey: string
  value: string | number | undefined
  orientation?: "horizontal" | "vertical"
}

const LabelValueStack = ({
  labelKey,
  value,
  orientation = "horizontal",
}: LabelValueStackProps) => {
  const { t } = useTranslation()

  const alignment = useMemo(
    () => (orientation === "horizontal" ? "center" : "start"),
    [orientation],
  )
  const size = useMemo(() => (orientation === "horizontal" ? 6 : 12), [orientation])
  const columnSpacing = useMemo(
    () => (orientation === "horizontal" ? 4 : 0),
    [orientation],
  )
  const rowSpacing = useMemo(() => (orientation === "vertical" ? 2 : 0), [orientation])

  return (
    <Grid
      container
      columnSpacing={columnSpacing}
      rowSpacing={rowSpacing}
      mb={2}
      alignItems={alignment}
    >
      <Grid item xs={size}>
        <Typography>{t(labelKey)}</Typography>
      </Grid>
      <Grid item xs={size}>
        <Typography color={"text.disabled"}>{value ?? "-"}</Typography>
      </Grid>
    </Grid>
  )
}

export default LabelValueStack
