import type { PopperPlacementType } from "@mui/material"
import { alpha, useTheme } from "@mui/material"
import type {
  DateTimePickerProps as MuiDateTimePickerProps,
  PickerValidDate,
} from "@mui/x-date-pickers"
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers"

type DateTimePickerProps<
  T extends PickerValidDate,
  V extends boolean,
> = MuiDateTimePickerProps<T, V>

type DateTimePickerType = (<
  TDate extends PickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false,
>(
  props: DateTimePickerProps<TDate, TEnableAccessibleFieldDOMStructure> &
    React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & {
  propTypes?: any
}

const DateTimePicker: DateTimePickerType = ({ ...props }) => {
  const theme = useTheme()
  const slotProps = {
    day: {
      sx: {
        "&.MuiPickersDay-root.Mui-selected": {
          backgroundColor: theme.palette.primary.main,
        },
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.2),
        },
      },
    },
    textField: {
      size: "small" as const,
      sx: { minWidth: 120, mb: 0 },
    },
    popper: {
      placement: "bottom-end" as PopperPlacementType,
      sx: {
        "& .MuiPickersPopper-paper": {
          backgroundImage: "unset",
          backgroundColor: theme.palette.background.light,
        },
        "& .MuiPickersLayout-contentWrapper": {
          height: "290px",
        },
        "& .MuiDateCalendar-root": {
          height: "290px",
        },
      },
    },
    layout: {
      sx: {
        ul: {
          "::-webkit-scrollbar": {
            width: "2px",
          },
        },
      },
    },
  }

  return <MuiDateTimePicker slotProps={slotProps} {...props} />
}

export default DateTimePicker
