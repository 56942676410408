import { useMemo } from "react"
import { useParams } from "react-router-dom"

import useDeviceGroups from "helpers/hooks/useDeviceGroups"
import useDevices from "helpers/hooks/useDevices"
import AlertsContainer from "widgets/alerts/AlertContainer"
import { BodyContainer } from "widgets/styled/containers"
import useDateRange from "helpers/hooks/useDateRange"

const GroupEventsLog = () => {
  const { id } = useParams()
  const { eventsDateRange, dispatchEventsDateRange, isEventsDateRangeValid } =
    useDateRange()
  const { groups } = useDeviceGroups()
  const { lessIdByDeviceId } = useDevices()
  const devicesIds = useMemo(() => {
    const group = groups?.find((g) => g.id === Number(id))
    const deviceList = group?.device_list as number[]
    if (lessIdByDeviceId) {
      return deviceList.map((d) => lessIdByDeviceId[d])
    }
    return
  }, [lessIdByDeviceId, groups, id])

  return (
    <BodyContainer disableGutters maxWidth="xl">
      <AlertsContainer
        dateRange={eventsDateRange}
        dispatchDateRange={dispatchEventsDateRange}
        isDateRangeValid={isEventsDateRangeValid}
        devicesIds={devicesIds}
        groupId={Number(id)}
      />
    </BodyContainer>
  )
}

export default GroupEventsLog
