import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import fp from "lodash/fp"

import type { AlertsFilterOptions } from "types/alerts.types"

const OPTIONS = [
  { label: "alerts.OPEN", value: "open" },
  { label: "alerts.SILENCED", value: "silenced" },
  { label: "alerts.NOT_TRIGGERED", value: "notTriggered" },
]

interface FilterSelectProps {
  filters: AlertsFilterOptions
  updateFilters: (filters: AlertsFilterOptions) => void
}

const FilterSelect = ({ filters, updateFilters }: FilterSelectProps) => {
  const { t } = useTranslation()
  const countActive = useMemo(
    () =>
      fp.flow(
        fp.values,
        fp.sumBy((v) => (v ? 1 : 0)),
      ),
    [],
  )

  return (
    <FormControl size="small" sx={{ my: 1, maxWidth: 130 }}>
      <InputLabel shrink={false} data-testid={"filter-select-label"}>{`${t(
        "table.FILTERS",
      )} (${countActive(filters)})`}</InputLabel>
      <Select value={[]} multiple color="primary" data-testid={"filter-select"}>
        {OPTIONS.map((opt) => (
          <MenuItem key={opt.value} value={opt.value} data-testid={opt.value}>
            <FormControlLabel
              label={`${t(opt.label)}`}
              control={
                <Checkbox
                  checked={filters[opt.value as keyof typeof filters]}
                  onChange={() =>
                    updateFilters({
                      ...filters,
                      [opt.value]: !filters[opt.value as keyof typeof filters],
                    })
                  }
                />
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FilterSelect
