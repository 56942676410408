import { useMemo } from "react"
import { useSelector } from "react-redux"

import { useOrgAlertSettingsStatesQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import {
  formatSettingsByDeviceId,
  formatSettingsByGroupId,
  formatStateBySettingId,
} from "helpers/formatters/alertSettingsFormatters"
import { ALERT_TIME } from "helpers/utils/constants"

interface useAlertSettingsStatesProps {
  deviceId?: number
  groupId?: number
}

const useAlertSettingsStates = ({
  deviceId,
  groupId,
}: useAlertSettingsStatesProps = {}) => {
  const orgId = useSelector(selectCurrentOrgId)
  const {
    currentData: orgAlertsStates,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useOrgAlertSettingsStatesQuery(
    {
      org_id: orgId as number,
    },
    {
      pollingInterval: ALERT_TIME,
      skip: !orgId,
    },
  )

  const { alertSettingsByDeviceId, alertSettingsByGroupId } = useMemo(() => {
    const alertSettingsByDeviceId = formatSettingsByDeviceId(orgAlertsStates)
    const alertSettingsByGroupId = formatSettingsByGroupId(orgAlertsStates)
    return { alertSettingsByDeviceId, alertSettingsByGroupId }
  }, [orgAlertsStates])

  const statesBySettingId = useMemo(
    () => formatStateBySettingId(orgAlertsStates),
    [orgAlertsStates],
  )

  const areTriggeredAlerts = useMemo(
    () =>
      orgAlertsStates?.some(
        (alertState) =>
          alertState.state.triggered && !alertState.state.silenced && alertState.less_id,
      ),
    [orgAlertsStates],
  )

  const allDeviceAlerts = useMemo(
    () => orgAlertsStates?.filter((alert) => alert.less_id === deviceId),
    [deviceId, orgAlertsStates],
  )

  const allGroupAlerts = useMemo(
    () => orgAlertsStates?.filter((alert) => alert.group_id === groupId),
    [groupId, orgAlertsStates],
  )

  return {
    allAlerts: orgAlertsStates,
    allDeviceAlerts,
    allGroupAlerts,
    areTriggeredAlerts,
    statesBySettingId,
    alertSettingsByDeviceId,
    alertSettingsByGroupId,
    isFetching,
    isLoading,
    isError,
    refetch,
  }
}

export default useAlertSettingsStates
