import { useOutletContext } from "react-router-dom"
import { Grid } from "@mui/material"
import { useMemo } from "react"

import type { DeviceDashboardOutletContext } from "types/dashboard.types"

import AlertSettings from "widgets/alerts/settings/AlertSettings"
import DeviceDetails from "widgets/device/settings/DeviceDetails"
import DeviceLocation from "widgets/device/settings/DeviceLocation"
import InstallationDetails from "widgets/device/settings/InstallationDetails"
import { BodyContainer } from "widgets/styled/containers"
import { isVirtualDevice } from "helpers/utils/devices"

function DeviceSettings() {
  const { id, device } = useOutletContext<DeviceDashboardOutletContext>()

  const isVirtual = useMemo(
    () => isVirtualDevice(device.device_type),
    [device.device_type],
  )

  return (
    <BodyContainer disableGutters maxWidth="xl" sx={{ pt: 2 }}>
      <Grid container spacing={2}>
        {device && !isVirtual && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <DeviceDetails device={device} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <DeviceLocation deviceId={Number(id)} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InstallationDetails device={device} />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AlertSettings />
        </Grid>
      </Grid>
    </BodyContainer>
  )
}

export default DeviceSettings
