import type { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { IconButton, Stack, Typography } from "@mui/material"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

interface PaginationProps {
  count: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  limit?: number
}

const Pagination = ({ count, page, setPage, limit = 10 }: PaginationProps) => {
  const { t } = useTranslation()
  const pages = Math.ceil(count / limit)
  const firstPage = page === 0

  return (
    <>
      {count > 0 && (
        <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} gap={1}>
          <IconButton
            sx={{ color: (theme) => theme.palette.text.primary }}
            disabled={firstPage}
            onClick={() => setPage(page - 1)}
            data-testid={"prev-arrow"}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography data-testid={"pages-info"}>{`${page + 1} ${t(
            "table.OF",
          )} ${pages}`}</Typography>
          <IconButton
            sx={{ color: (theme) => theme.palette.text.primary }}
            disabled={page + 1 === pages}
            onClick={() => setPage(page + 1)}
            data-testid={"next-arrow"}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Stack>
      )}
    </>
  )
}

export default Pagination
