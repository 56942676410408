import type { FC } from "react"
import { useContext, useMemo } from "react"

import "leaflet/dist/leaflet.css"

import Spinner from "widgets/common/Spinner"
import type { RecordType } from "types/dashboard.types"
import {
  formatDeviceLatLonData,
  getLatLonDataFromRecord,
  getMapCenterFromDeviceLocation,
} from "helpers/utils/map"

import useDevices from "helpers/hooks/useDevices"
import DashboardContext from "widgets/device/Dashboard/DashboardContext"
import { getCellHeight } from "helpers/utils/dashboards"
import DeviceMapMarkers from "./DeviceMapMarkers"
import Map from "./Map"

import "./map.css"

interface GeoPlotProps {
  data: RecordType[]
}

const GeoPlot: FC<GeoPlotProps> = ({ data }) => {
  const dashboardContext = useContext(DashboardContext)
  const { devsWRelsByIdWStates } = useDevices()

  const latlonData = useMemo(() => getLatLonDataFromRecord(data), [data])

  const formattedDevicesData = useMemo(
    () => formatDeviceLatLonData(latlonData, devsWRelsByIdWStates),
    [devsWRelsByIdWStates, latlonData],
  )

  const mapHeight = useMemo(
    () =>
      dashboardContext?.config?.type === "span"
        ? `calc(${
            getCellHeight("LOCATION", dashboardContext.config.type) *
            dashboardContext.rowHeight *
            0.9
          }px)`
        : "inherit",
    [dashboardContext],
  )

  const center = useMemo(
    () => getMapCenterFromDeviceLocation(formattedDevicesData),
    [formattedDevicesData],
  )

  return (
    <>
      {data ? (
        <Map center={center} mapHeight={mapHeight}>
          <DeviceMapMarkers devices={formattedDevicesData} />
        </Map>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default GeoPlot
