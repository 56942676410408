import type { Dispatch, SetStateAction } from "react"
import type { TFunction } from "i18next"
import { Tooltip } from "@mui/material"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import type { Dictionary } from "lodash"

import type { Paged } from "types/common.types"
import type { AuditTrailTableRow, UsersLog, UsersLogsRes } from "types/users.types"

import { formatDateFromMillis } from "helpers/utils/dateUtils"

export const getAuditTrailTableColumns = (t: TFunction) => {
  return [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "username",
      label: t("audit_trail.USERNAME"),
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "event",
      label: t("audit_trail.EVENT"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "reason",
      label: t("audit_trail.DESCRIPTION"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return value && value !== "" ? value : "-"
        },
        setCellProps: () => ({ style: { maxWidth: "300px" } }),
      },
    },
    {
      name: "timestamp",
      label: t("audit_trail.TIMESTAMP"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: number) => {
          return formatDateFromMillis(value)
        },
      },
    },
    {
      name: "successful",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: boolean) => {
          return value ? (
            <Tooltip title={t("audit_trail.EVENT_SUCCESSFUL")}>
              <CheckCircleOutlineIcon color="success" fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title={t("audit_trail.EVENT_FAILED")}>
              <ErrorOutlineIcon color="error" fontSize="small" />
            </Tooltip>
          )
        },
      },
    },
  ]
}

export const updateTableData = (
  data: Paged<UsersLogsRes> | undefined,
  results: UsersLog[],
  setResults: Dispatch<SetStateAction<UsersLog[]>>,
) => {
  if (data) {
    setResults([...results, ...data.results])
  }
}

export const formatAuditTrailTableCsv = (
  data: { index: number; data: AuditTrailTableRow }[],
  columnsIndexes: Dictionary<number>,
  t: TFunction,
) => {
  return data?.map((row, index) => ({
    index,
    data: row?.data?.map((field: any, index) => {
      // field can be any of the types of AuditTrailTableRow
      // array order comes from columns definition
      switch (index) {
        case columnsIndexes.successful:
          return field ? t("audit_trail.EVENT_SUCCESSFUL") : t("audit_trail.EVENT_FAILED")
        default:
          return field
      }
    }),
  }))
}
