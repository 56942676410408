import { useEffect, useMemo } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Stack from "@mui/material/Stack"
import CircleIcon from "@mui/icons-material/Circle"

import usePermissions from "helpers/hooks/usePermissions"
import { ItemTag } from "widgets/styled/widgets"
import { useDeviceGroupQuery } from "features/api"
import PreviousPage from "widgets/common/PreviousPage"
import Spinner from "widgets/common/Spinner"
import Title from "widgets/common/Title"
import EditableDeviceGroupName from "widgets/deviceGroups/editor/EditableDeviceGroupName"
import useRoutes from "helpers/hooks/useRoutes"
import { getColorFromString } from "helpers/utils/styles"
import LinkTabsSection from "widgets/common/LinkTabsSection"
import { getGroupTabsConfig } from "helpers/config/tabsConfigs"
import useDateRange from "helpers/hooks/useDateRange"

function DeviceGroup() {
  const { baseOrgURL } = useRoutes()
  const { isEditor } = usePermissions()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    currentData: deviceGroup,
    isFetching: deviceGroupFetching,
    isSuccess: deviceGroupSuccess,
    isError,
  } = useDeviceGroupQuery({
    id: Number(id),
    params: {},
  })
  const { eventsSearchParams } = useDateRange()

  const groupColor = useMemo(
    () => getColorFromString(deviceGroup?.name ?? ""),
    [deviceGroup],
  )
  const groupTabsConfig = useMemo(
    () => getGroupTabsConfig(eventsSearchParams),
    [eventsSearchParams],
  )

  useEffect(() => {
    if (isError) {
      navigate(`${baseOrgURL}/device-groups`)
    }
  }, [isError, navigate, baseOrgURL])

  return (
    <>
      {deviceGroupFetching && !deviceGroup ? (
        <Spinner />
      ) : (
        <>
          <PreviousPage href={`${baseOrgURL}/device-groups`} />
          {deviceGroupSuccess && deviceGroup && (
            <>
              <Stack
                flexDirection={"row"}
                gap={"15px"}
                alignItems={"center"}
                marginBottom={"0.5rem"}
              >
                <ItemTag>{t("device_settings.GROUP")}</ItemTag>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <CircleIcon sx={{ fontSize: "0.7rem", color: groupColor }} />
                  {isEditor ? (
                    <EditableDeviceGroupName name={deviceGroup.name} />
                  ) : (
                    <Title text={deviceGroup.name} styles={{ p: 0 }} />
                  )}
                </Stack>
              </Stack>
              <LinkTabsSection tabsConfig={groupTabsConfig} />
              <Outlet context={{ deviceGroup, deviceGroupFetching }} />
            </>
          )}
        </>
      )}
    </>
  )
}

export default DeviceGroup
