import type { FC, PropsWithChildren } from "react"
import Dialog from "@mui/material/Dialog"
import { Box } from "@mui/material"

interface IPopUpProps extends PropsWithChildren<any> {
  open: boolean
  onClose: () => void
  disableOverflow?: boolean
  styles?: object
}

const PopUp: FC<IPopUpProps> = ({ children, open, onClose, disableOverflow, styles }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        ...styles,
        "& .MuiPaper-root": {
          overflow: disableOverflow ? "visible" : "auto",
        },
      }}
    >
      <Box overflow={disableOverflow ? "auto" : "hidden"}>{children}</Box>
    </Dialog>
  )
}

export default PopUp
