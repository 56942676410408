import type { Paged } from "types/common.types"
import type {
  PowerlineSpansRes,
  PowerlineSpansReq,
  SpansRes,
  SpansReq,
  SpanRes,
  SpanReq,
} from "types/spans.types"
import { api } from "./base"

export const spansApi = api.injectEndpoints({
  endpoints: (builder) => ({
    powerlineSpans: builder.query<Paged<PowerlineSpansRes>, PowerlineSpansReq>({
      query: ({ id, params }) => ({
        url: `v1/powerlines/${id}/spans`,
        params,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Span" as const, id }],
    }),
    spans: builder.query<Paged<SpansRes>, SpansReq>({
      query: ({ params }) => ({
        url: `v1/powerlines_spans`,
        params,
      }),
      providesTags: (_result, _error, { params }) => [
        { type: "Span" as const, id: params.org_id },
      ],
    }),
    span: builder.query<SpanRes, SpanReq>({
      query: ({ id, params }) => ({
        url: `v1/powerlines_spans/${id}`,
        params,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Span" as const, id }],
    }),
  }),
})
