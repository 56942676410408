import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { devicesAlertStatusPlotDef } from "helpers/config/plots/pieDefs"
import useDevices from "helpers/hooks/useDevices"
import useAlertSettingsStates from "helpers/hooks/useAlertSettingsStates"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import { getDevicesByAlertStatus } from "helpers/utils/devices"
import Message from "widgets/common/Message"
import Plot from "widgets/plots/Plot"
import { SectionCard } from "widgets/styled/containers"
import PieChartSkeleton from "./PieChartSkeleton"

const DevicesAlertStatusPieChart = () => {
  const { t } = useTranslation()
  const { devsWRelsById, isLoading: devicesLoading, isError: devicesError } = useDevices()
  const {
    allAlerts,
    isLoading: statesLoading,
    isError: statesError,
  } = useAlertSettingsStates()
  const {
    orgAlertsBySettingId,
    isLoading: settingsLoading,
    isError: settingsError,
  } = useAlertSettings()

  const isLoading = useMemo(
    () => devicesLoading || statesLoading || settingsLoading,
    [devicesLoading, settingsLoading, statesLoading],
  )
  const isError = useMemo(
    () => devicesError || statesError || settingsError,
    [devicesError, settingsError, statesError],
  )
  const devicesByAlertStatus = useMemo(
    () => getDevicesByAlertStatus(devsWRelsById, allAlerts, orgAlertsBySettingId),
    [allAlerts, devsWRelsById, orgAlertsBySettingId],
  )
  return (
    <SectionCard styles={{ margin: 0, border: "none" }}>
      {isLoading ? (
        <PieChartSkeleton />
      ) : isError ? (
        <Message
          messageKey={"error.FETCHING_ITEM"}
          messageOptions={{
            item: t("alerts.CHART").toLowerCase(),
          }}
          type="error"
        />
      ) : (
        <Plot view={devicesAlertStatusPlotDef.view} data={devicesByAlertStatus} />
      )}
    </SectionCard>
  )
}

export default DevicesAlertStatusPieChart
