import fp from "lodash/fp"

import type { ICustomError } from "helpers/errors/errorTypes"

import { errorMessage, successMessage } from "./snackbars"
import { addEndPeriodIfMissing } from "./translations"

interface SnackbarMutationProps<T> {
  mutation: Promise<T>
  getSuccessMessage?: (data: T) => string | undefined
  getErrorMessage: (error: ICustomError | TypeError) => string
}

export const snackbarMutation = async <T,>({
  mutation,
  getSuccessMessage = () => undefined,
  getErrorMessage,
}: SnackbarMutationProps<T>): Promise<T> => {
  return mutation
    .then((data: T) => {
      const message = getSuccessMessage(data)
      if (message) {
        successMessage(message)
      }
      return data
    })
    .catch((error: ICustomError | TypeError) => {
      errorMessage(getErrorMessage(error))
      return Promise.reject(error)
    })
}

export const buildGetErrorMessage =
  (errorText: string) =>
  (error: ICustomError | TypeError): string => {
    const message = fp.get("data.message", error)
    return message ? `${errorText}: ${addEndPeriodIfMissing(message)}` : errorText
  }
