import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import fp from "lodash/fp"

import type { FC } from "react"
import { Box } from "@mui/material"
import { Popup } from "widgets/styled/map"
import useRoutes from "helpers/hooks/useRoutes"
import GroupsChips from "widgets/deviceGroups/GroupsChips"
import CustomLink from "widgets/common/CustomLink"
import { featureProperties } from "helpers/utils/map"
import type { MarkerDeviceType } from "types/geolocation.types"

interface IGeoPopupProps {
  device: MarkerDeviceType
}

const GeoPopup: FC<IGeoPopupProps> = ({ device }) => {
  const { groups } = device
  const { baseOrgURL } = useRoutes()
  const { t } = useTranslation()

  return (
    <div data-testid="geo-popup">
      <Popup>
        <Stack spacing={0}>
          {featureProperties.Device.map((property: string, i) => {
            if (
              property in device &&
              !fp.isNil(device[property as keyof MarkerDeviceType])
            ) {
              return (
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  key={`DeviceProperty-${device.id}-${i}`}
                >
                  <Typography fontWeight="600">
                    {t(`geo_info.${property.toUpperCase()}`)}
                  </Typography>
                  <Typography variant="body2">
                    {device[property as keyof MarkerDeviceType]}
                  </Typography>
                </Stack>
              )
            }
          })}
          {groups?.length && (
            <Stack gap="0.5rem">
              <Typography fontWeight="600">{t("device_groups.GROUPS")}:</Typography>{" "}
              <GroupsChips groups={groups} />
            </Stack>
          )}
        </Stack>
        {device.less_id && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            margin="0.2rem 0"
          >
            <CustomLink href={`${baseOrgURL}/devices/${device.less_id}`} bold>
              {t("generic.GO_TO_DEVICE")}
            </CustomLink>
          </Box>
        )}
      </Popup>
    </div>
  )
}

export default GeoPopup
