import type { ChangeEvent } from "react"
import { useSelector, useDispatch } from "react-redux"
import Switch from "@mui/material/Switch"

import { selectTheme, switchTheme } from "../../features/store/configSlice"

export default function ThemeSwitch() {
  const userTheme = useSelector(selectTheme)
  const dispatch = useDispatch()

  const handleThemeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const theme = event.target.checked ? "dark" : "light"
    dispatch(switchTheme(theme))
  }

  return (
    <Switch checked={userTheme === "dark"} onChange={handleThemeChange} color="default" />
  )
}
