import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import type { TFailurePassword, TWarningPassword } from "../../types/password.types"

interface PasswordAlertProps {
  passwordCode?: TWarningPassword | TFailurePassword
}

const PasswordAlert: FC<PasswordAlertProps> = ({ passwordCode }) => {
  const { t } = useTranslation()

  if (!passwordCode) {
    return <></>
  }

  return (
    <Box
      mt="10px"
      sx={{
        px: "20px",
      }}
    >
      <Alert
        severity="warning"
        sx={{
          alignItems: "center",
          p: 1,
        }}
      >
        {t(`change_password.MESSAGE_${passwordCode}`)}
      </Alert>
    </Box>
  )
}

export default PasswordAlert
