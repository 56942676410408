import type { DateRange } from "types/date.types"

export interface MultipleDateRangeAction {
  type: "UPDATE"
  payload: { [key: string]: DateRange }
}

export const multipleDateRangeReducer = (
  dateRange: { [key: string]: DateRange },
  action: MultipleDateRangeAction,
) => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...dateRange,
        ...action.payload,
      }
    default:
      return dateRange
  }
}
