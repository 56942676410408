import { useOutletContext } from "react-router-dom"

import { useMemo } from "react"
import { powerlinePlotDefs, spanPlotDefs } from "helpers/config/plots"
import GeneralDashboard from "widgets/device/Dashboard"
import InfluxDashboard from "widgets/device/Dashboard/InfluxDashboard"
import { BodyContainer } from "widgets/styled/containers"

import type { DeviceDashboardOutletContext } from "types/dashboard.types"

// This component had to be created so that both Span tab and Info tab could share the index route
function MainDashboard() {
  const { id: deviceId, device } = useOutletContext<DeviceDashboardOutletContext>()

  const config = useMemo(() => {
    switch (device.device_type) {
      case "virtual_powerline":
        return {
          type: "powerline",
          plotDefs: powerlinePlotDefs,
        }
      default:
        return {
          type: "span",
          plotDefs: spanPlotDefs,
        }
    }
  }, [device])

  const Dashboard = useMemo(() => {
    switch (device.device_type) {
      case "weather_station":
        return <InfluxDashboard id={deviceId} />
      default:
        return <GeneralDashboard deviceId={deviceId} config={config} />
    }
  }, [device, config, deviceId])

  return (
    <BodyContainer disableGutters maxWidth="xl">
      {Dashboard}
    </BodyContainer>
  )
}

export default MainDashboard
