import type { ChangeEvent, ChangeEventHandler, FormEvent } from "react"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Typography, TextField, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import CardWithTitle from "widgets/common/CardWithTitle"
import LoadingButton from "widgets/common/LoadingButton"

interface RecoveryCodesVerificationProps {
  onClose: () => void
  verify: (value: string) => Promise<void>
  isVerifying: boolean
  otpCode: string
  handleSwitchVerification: () => void
}

const RecoveryCodesVerification = ({
  onClose,
  verify,
  isVerifying,
  otpCode,
  handleSwitchVerification,
}: RecoveryCodesVerificationProps) => {
  const { t } = useTranslation()
  const [otp, setOtp] = useState("")

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOtp(e.target.value)
      },
      [setOtp],
    )

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    value: string,
  ): Promise<void> => {
    e.preventDefault()
    try {
      return await verify(value)
    } catch {
      // nothing
    }
  }

  return (
    <>
      <CardWithTitle
        titleKey="auth.RECOVERY_CODE_AUTH"
        handleAction={onClose}
        actionIcon={<CloseIcon fontSize="small" color="action" />}
      >
        <Typography
          id="otp-verifier-description"
          variant="h6"
          component={"h6"}
          align="center"
          mb={3}
        >
          {t("auth.ENTER_RECOVERY_CODE", { code: otpCode })}
        </Typography>
        <form onSubmit={(e) => handleSubmit(e, otp)}>
          <TextField
            id="otp-recovery-code"
            value={otp}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            autoFocus
          />
          <Stack mt={1} alignItems={"center"}>
            <LoadingButton loading={isVerifying} variant="contained">
              {t("generic.ACCEPT")}
            </LoadingButton>
          </Stack>
        </form>
        <Stack justifyContent={"center"} alignItems={"center"} mt={5} gap={1}>
          <Typography>{t("auth.PREFER_OTP")}</Typography>
          <Typography
            color="primary"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={handleSwitchVerification}
          >
            {t("auth.USE_TWO_FACTOR")}
          </Typography>
        </Stack>
      </CardWithTitle>
    </>
  )
}

export default RecoveryCodesVerification
