import i18n from "../../features/i18n"

export function errorMessage(errors, target, minLength = 0) {
  const t = i18n.t
  let result = ""
  switch (errors[target]?.type) {
    case "minLength":
      result = t("generic.FIELD_MIN_CHAR", { minLength })
      break
    case "matchesPreviousPassword":
      result = t("register.PASSWORD_MATCH")
      break
    case "pattern":
      result = t("generic.FIELD_INVALID_EMAIL")
      break
    case "required":
      result = t("generic.FIELD_REQUIRED")
      break
    default:
      result = ""
  }

  return result
}
