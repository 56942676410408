import { useSelector } from "react-redux"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { useGeoQuery } from "features/api"

const useGeolocations = () => {
  const orgId = useSelector(selectCurrentOrgId)

  const {
    data: geoData,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useGeoQuery(
    {
      orgId: Number(orgId),
    },
    {
      skip: !orgId,
    },
  )

  return {
    geoData,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export default useGeolocations
