import { useState } from "react"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"

import CreateDevicePopup from "widgets/device/editor/CreateDevicePopup"

const CreateDeviceButton = () => {
  const { t } = useTranslation()
  const [createPopupOpen, setCreatePopupOpen] = useState(false)

  const handleCreateDeviceOpen = () => {
    setCreatePopupOpen(true)
  }
  const handleCreateDeviceClose = () => {
    setCreatePopupOpen(false)
  }

  return (
    <>
      <Box>
        <Button
          onClick={handleCreateDeviceOpen}
          startIcon={<AddIcon />}
          variant="outlined"
          size="small"
        >
          {t("device.CREATE_DEVICE")}
        </Button>
      </Box>
      {createPopupOpen && (
        <CreateDevicePopup open={createPopupOpen} onClose={handleCreateDeviceClose} />
      )}
    </>
  )
}

export default CreateDeviceButton
