import { batteryStatusPlotDef, signalStatusPlotDef } from "./gaugeDefs"
import {
  batteryLevelPlotDef,
  currentPlotDef,
  displacementAmplitudePlotDef,
  motionPlotDef,
  motionIntensityPlotDef,
  sagPlotDef,
  temperaturePlotDef,
  transverseWindSpeedPlotDef,
  ampacityAARPlotDef,
} from "./lineDefs"
import { mapPlotDef } from "./mapDefs"
import {
  maxDisplacementAmplitudePlotDef,
  lastSagPlotDef,
  lastTemperaturePlotDef,
  lastCurrentPlotDef,
  lastWindSpeedPlotDef,
  lastAmpacityAARPlotDef,
} from "./singleStatDefs"

export { lineView, singleStatView } from "./base"
export { positionPlotView } from "./lineDefs"
export { mapView } from "./mapDefs"

export const spanPlotDefs = [
  mapPlotDef,
  temperaturePlotDef,
  lastTemperaturePlotDef,
  motionPlotDef,
  motionIntensityPlotDef,
  displacementAmplitudePlotDef,
  maxDisplacementAmplitudePlotDef,
  transverseWindSpeedPlotDef,
  lastWindSpeedPlotDef,
  sagPlotDef,
  lastSagPlotDef,
  currentPlotDef,
  lastCurrentPlotDef,
]

export const devicePlotDefs = [
  batteryLevelPlotDef,
  batteryStatusPlotDef,
  signalStatusPlotDef,
]

export const powerlinePlotDefs = [ampacityAARPlotDef, lastAmpacityAARPlotDef]
