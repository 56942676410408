import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { Box, useTheme } from "@mui/material"
import fp from "lodash/fp"

import { useDeviceTelemetryQuery } from "features/api"
import { lineView } from "helpers/config/plots"
import { alertTypeDefs } from "helpers/config/plots/alertTypeDefs"
import { getRecordService } from "helpers/formatters/dataFormatters"
import { formatTelemetryPlotData } from "helpers/utils/plots"
import AlertsContext from "widgets/alerts/AlertsContext"
import Message from "widgets/common/Message"
import Spinner from "widgets/common/Spinner"
import Plot from "widgets/plots/Plot"

import type { AccordionAlertSetting, ReverseThresholdField } from "types/alerts.types"
import type { DeviceTelemetryType } from "types/telemetries.types"
import type { RecordService } from "types/dashboard.types"

interface IAlertChartProps {
  alert: AccordionAlertSetting
}

function AlertChart({ alert }: IAlertChartProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { multipleDateRange } = useContext(AlertsContext)
  const dateRange = useMemo(
    () => multipleDateRange[alert.id],
    // update only when this accordion date range changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multipleDateRange[alert.id], alert.id],
  )
  const formattedDateRange = useMemo(
    () => dateRange && { from_date: dateRange.fromDate, to_date: dateRange.toDate },
    [dateRange],
  )
  const { fields, view } = getAlertViewAndFields(alert)

  const { currentData: data, isLoading } = useDeviceTelemetryQuery(
    {
      id: alert.less_id,
      params: {
        field: fields,
        ...formattedDateRange,
        group: ["_field"],
      },
    },
    { skip: !formattedDateRange },
  )

  const recordService: RecordService = useMemo(() => {
    // added name as required by giraffe
    const fill = ["_field", "name"]
    return getRecordService(fill, t)
  }, [t])

  const formattedData = useMemo(() => {
    // needed for giraffe as we can't put code in the tooltip
    type AddName = (records: DeviceTelemetryType[]) => DeviceTelemetryType[]
    const addName: AddName = fp.map((record: DeviceTelemetryType) => ({
      ...record,
      name: recordService.getName(record),
    }))
    return addName(formatTelemetryPlotData(data))
  }, [data, recordService])

  return isLoading || data === undefined ? (
    <Spinner />
  ) : (
    <>
      {formattedData.length ? (
        <Box sx={{ height: "400px" }}>
          <Plot
            data={formattedData}
            view={view}
            recordService={recordService}
            styles={{
              padding: 0,
              border: "none",
              [theme.breakpoints.up("sm")]: {
                padding: 0,
              },
            }}
          />
        </Box>
      ) : (
        <Message messageKey={"generic.NO_DATA_IN_TIME_WINDOW"} />
      )}
    </>
  )
}

const getAlertViewAndFields = (alert: AccordionAlertSetting) => {
  const type = alert.type
  const isThreshold = !fp.has(type, alertTypeDefs)
  const nonThresholdPlotDef = alertTypeDefs[type as keyof typeof alertTypeDefs]
  const view = isThreshold
    ? getAlertView(alert)
    : { ...nonThresholdPlotDef.view, name: "", nameKey: "" }
  const fields = isThreshold
    ? (alert.config.fields as string[])
    : (nonThresholdPlotDef.reqConfig.fields as string[])

  return { fields, view }
}

const getAlertView = (alert: AccordionAlertSetting) => {
  const alertFields = alert.config.fields as string[]
  const fields = alertFields?.length > 0 ? alertFields : []
  const alertThresholds = alert.config.thresholds as ReverseThresholdField[]
  const thresholds = alertThresholds?.length > 0 ? alertThresholds : []
  const { min, max } = findMinAndMaxAlertThresholds(thresholds)
  const bounds = getAlertBounds(fields, min, max)

  return fp.merge(lineView, {
    properties: {
      axes: {
        y: {
          ...bounds,
        },
      },
      fill: ["_field"],
      legendColumns: ["_time", "_value", "name"],
    },
  })
}

const findMinAndMaxAlertThresholds = (
  thresholds: ReverseThresholdField[],
): { min: number | null; max: number | null } => {
  const allThresholds = thresholds.flatMap((th) => {
    if (th.min !== undefined && th.max !== undefined) {
      return [th.min, th.max]
    }
    if (th.min !== undefined) {
      return [th.min]
    }
    return [th.max]
  }) as number[]
  const min = allThresholds.length > 0 ? Math.min(...allThresholds) : null
  const max = allThresholds.length > 0 ? Math.max(...allThresholds) : null
  return { min, max }
}

const getAlertBounds = (fields: string[], min: number | null, max: number | null) => {
  const isBattery = fields.includes("power.battery.level")

  return isBattery
    ? { bounds: ["0", "100"] }
    : { bounds: [min !== null ? min : "", max !== null ? max : ""] }
}

export default AlertChart
