import { useMemo, useReducer, useState } from "react"

import {
  formatAlerts,
  getAlertAmounts,
  getFilteredSortedAlerts,
} from "helpers/formatters/alertSettingsFormatters"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import useAlertSettingsStates from "helpers/hooks/useAlertSettingsStates"
import useDevices from "helpers/hooks/useDevices"
import { multipleDateRangeReducer } from "helpers/reducers/dateRangeReducer"
import { paginateList } from "helpers/utils/common"
import type { AlertsFilterOptions } from "types/alerts.types"
import AlertsContext from "widgets/alerts/AlertsContext"
import { SectionContainer } from "widgets/styled/containers"
import Spinner from "widgets/common/Spinner"
import AlertsFilter from "widgets/alerts/AlertsFilter"
import AccordionsSection from "widgets/alerts/AccordionsSection"
import Pagination from "widgets/common/Pagination"

function AlertsList() {
  const { allAlerts, isLoading } = useAlertSettingsStates()
  const { orgAlertsBySettingId } = useAlertSettings()
  const { devsWRelsById } = useDevices()
  const [multipleDateRange, dispatchMultipleDateRange] = useReducer(
    multipleDateRangeReducer,
    {},
  )
  const [filters, setFilters] = useState<AlertsFilterOptions | null>(null)
  const [page, setPage] = useState(0)

  const formattedAlerts = useMemo(
    () => formatAlerts(allAlerts, orgAlertsBySettingId, devsWRelsById),
    [devsWRelsById, orgAlertsBySettingId, allAlerts],
  )
  const alertsAmount = useMemo(() => getAlertAmounts(formattedAlerts), [formattedAlerts])
  const alertsSortedByState = useMemo(
    () => getFilteredSortedAlerts(formattedAlerts, filters),
    [formattedAlerts, filters],
  )
  const pagedAlerts = useMemo(
    () => paginateList(page, alertsSortedByState),
    [alertsSortedByState, page],
  )

  return (
    <AlertsContext.Provider
      value={{
        multipleDateRange,
        dispatchMultipleDateRange,
      }}
    >
      <SectionContainer disableGutters maxWidth="xl">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <AlertsFilter
              alertsAmount={alertsAmount}
              filters={filters}
              setFilters={setFilters}
            />
            <AccordionsSection alerts={pagedAlerts} />
            <Pagination
              count={alertsSortedByState.length}
              page={page}
              setPage={setPage}
            />
          </>
        )}
      </SectionContainer>
    </AlertsContext.Provider>
  )
}

export default AlertsList
