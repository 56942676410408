import { StrictMode, Suspense } from "react"
import { render as reactDOMRender } from "react-dom"
import { Provider } from "react-redux"

import App from "./App"
import Spinner from "./widgets/common/Spinner"
import { store } from "./features/store"
import "./features/i18n"

reactDOMRender(
  <StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <App />
      </Suspense>
    </Provider>
  </StrictMode>,
  document.getElementById("root"),
)
