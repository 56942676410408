import type { FC } from "react"
import { useEffect, useRef, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTheme } from "@mui/system"

import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import ControlledInput from "../ControlledInput"
import { TitleWithRef } from "../Title"
import LoadingButton from "../LoadingButton"

interface IEditableTitleProps {
  text: string
  onSubmitHandler: (newValue: string) => void
  isLoading: boolean
  isSuccess: boolean
}

interface IEditableTitleForm {
  title: string
}

const EditableTitle: FC<IEditableTitleProps> = ({
  text,
  onSubmitHandler,
  isLoading,
  isSuccess,
}) => {
  const theme = useTheme()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  // ref used for matching the input's width to the title's original width,
  // otherwise the input will be fixed width (and can't be easily set dinamically)
  const ref = useRef<HTMLDivElement>(null)
  const [titleWidth, setTitleWidth] = useState<string>("100%")

  const methods = useForm<IEditableTitleForm>({
    mode: "all",
    defaultValues: {
      title: text,
    },
  })

  const { resetField, setValue } = methods

  const handleEditClick = () => {
    setValue("title", text)
    setIsEditing(true)
    if (ref.current?.offsetWidth !== undefined) {
      // must include units, since the default is in a different unit ("%" vs "px")
      setTitleWidth(ref.current?.offsetWidth + "px")
    }
  }

  const handleCancelEdit = () => {
    resetField("title")
    setIsEditing(false)
  }

  const onSubmit = (data: IEditableTitleForm) => {
    onSubmitHandler(data.title)
  }

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false)
    }
  }, [isSuccess])

  useEffect(() => {
    resetField("title", { defaultValue: text })
  }, [text, resetField])

  if (isEditing || isLoading) {
    return (
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{
          height: "fit-content",
          maxWidth: "100%",
          minWidth: 0 /* allows shrinking */,
        }}
      >
        <FormProvider {...methods}>
          <Box
            sx={{
              display: "flex",
              marginTop: "1px",
              marginBottom: "-5px",
              [theme.breakpoints.up("xl")]: {
                marginTop: "-2px",
                marginBottom: "-3px",
              },
            }}
          >
            <Typography
              variant="h3"
              sx={{
                width: `calc(${titleWidth} + 5ch)`,
              }}
            >
              <ControlledInput
                disabled={isLoading}
                name="title"
                placeholder={text}
                styles={{
                  "& .MuiInput-root": {
                    font: "inherit",
                  },
                }}
              />
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ borderRadius: "4px" }}
                disabled={isLoading}
                color="error"
                aria-label="cancel"
                onClick={handleCancelEdit}
              >
                <CloseIcon />
              </IconButton>
              <LoadingButton
                loading={isLoading}
                styles={{
                  width: "fit-content",
                  minWidth: "auto",
                  p: 1,
                  color: theme.palette.success.main,
                }}
                aria-label="submit"
                icon={<CheckIcon />}
              ></LoadingButton>
            </Box>
          </Box>
        </FormProvider>
      </form>
    )
  } else {
    return (
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <TitleWithRef text={text} ref={ref} />
        <IconButton
          color="primary"
          aria-label="edit"
          size="small"
          onClick={handleEditClick}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    )
  }
}

export default EditableTitle
