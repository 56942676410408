import type { CSSProperties } from "react"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import type { StringMap, TOptions } from "i18next"

interface IMessageProps {
  messageKey: string
  messageOptions?: string | TOptions<StringMap> | undefined
  containerStyles?: CSSProperties
  messageStyles?: CSSProperties
  type?: string
}

const Message = ({
  messageKey,
  messageOptions = messageKey,
  containerStyles,
  messageStyles,
  type = "",
}: IMessageProps) => {
  const { t } = useTranslation()

  return (
    <Container
      disableGutters
      sx={{
        height: "200px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        ...containerStyles,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: (theme) =>
            type === "error" ? theme.palette.error.main : theme.palette.text.secondary,
          width: "100%",
          textAlign: "center",
          ...messageStyles,
        }}
      >
        {t(messageKey, messageOptions)}
      </Typography>
    </Container>
  )
}

export default Message
