import { DateTime } from "luxon"
import { stringify } from "query-string"

import type {
  IDateRange,
  IDateRangeISO,
  DateReference,
  DateRange,
} from "types/date.types"

/**
 * Dispatch an action to refresh de date range
 */
export default function refreshDateRange(
  dateReference: DateReference,
  dispatchDateRange: any,
): void {
  const dateNow = DateTime.now()
  dispatchDateRange({
    type: "UPDATE_DATE_RANGE",
    payload: {
      to_date: dateNow,
      from_date: dateNow.minus({
        [dateReference.timeUnit]: dateReference.timeAmount,
      }),
    },
  })
}

/**
 * Function that returns the datetime formatted
 *
 * @returns {string} the date formatted using "yyyy-MM-dd, HH:mm"
 */
export const formatDateTime = (datetime: DateTime | undefined): string =>
  datetime !== undefined ? datetime.toFormat("yyyy-MM-dd HH:mm") : "-"

/**
 * Function that returns the date with a new format
 *
 * @param date number of **milliseconds** (uses the default zone)
 * @returns {string} the date is parsed to the format "yyyy-MM-dd, HH:mm"
 */
export const formatDateFromMillis = (date: number | undefined): string =>
  formatDateTime(date !== undefined ? DateTime.fromMillis(date) : undefined)

/**
 * Function that returns the date with a new format.
 *
 * @param {IDateRange} dateRange
 * @returns {IDateRangeISO}
 */
export const formatDateTimeToISO = (dateRange: IDateRange): IDateRangeISO => {
  return {
    fromDate: dateRange.fromDate?.toISO(),
    toDate: dateRange.toDate?.toISO(),
  }
}

export const formatSearchParams = (dateRange: DateRange | null): string => {
  if (!dateRange) return ""
  return dateRange.range === "custom"
    ? stringify(dateRange)
    : stringify({ range: dateRange.range })
}
