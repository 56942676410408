import { DateTime, Duration } from "luxon"
import type { Palette } from "@mui/material"

import type { CellViewType, CustomProperties, RecordType } from "types/dashboard.types"
import type { ISingleStatConfig } from "types/singleStatPlot.types"
import { valueFormatter } from "helpers/formatters/plots/plots"
import { isValidValue } from "helpers/utils/common"

interface SingleStatConfigParams {
  view: CellViewType
  data: RecordType[]
  palette: Palette
  locale?: string
}

export default function singleStatConfig({
  view,
  data,
  palette,
  locale,
}: SingleStatConfigParams): ISingleStatConfig {
  const decimalPlaces = view?.properties?.decimalPlaces ?? {
    digits: 0,
    isEnforced: false,
  }

  const getPlotFooter = (data: RecordType) => {
    return (view.properties as CustomProperties)?.footerFields?.includes("_time")
      ? DateTime.fromISO(data._time as string).toFormat("yyyy-MM-dd HH:mm:ss")
      : undefined
  }

  const dateFormatter = (date: string): string | Error => {
    const value = DateTime.fromISO(date)
    if (!value.isValid) {
      return new Error(value.toString().replace(/ /g, "_").toUpperCase())
    }
    return value.toFormat("yyyy-MM-dd HH:mm:ss")
  }

  const timeFormatter = (durationValue: any): string | Error => {
    try {
      return Duration.fromDurationLike({ hours: durationValue }).toFormat("hh:mm")
    } catch (e) {
      return new Error("INVALID_DURATION")
    }
  }

  const getData = (value: string | number | Error) => {
    if (value instanceof Error) return value
    switch (typeof value) {
      case "string":
        return `${view.properties.prefix} ${value} ${view.properties.suffix}`.trim()
      case "number":
        return valueFormatter({
          value,
          prefix: view.properties.prefix,
          suffix: view.properties.suffix,
          decimalPlaces: decimalPlaces.digits,
          formatUnits:
            "formatUnits" in view.properties ? view.properties.formatUnits : false,
          locale,
        })
    }
  }

  const getValue = (data: RecordType[], view: CellViewType) => {
    const name = view.name.toLowerCase()
    // Casting was done because _value could be of type string[], but not in this case

    const lastResult = data[data.length - 1]
    const firstResult = data[0]

    const lastResultValue =
      lastResult && (lastResult._value as string | number | undefined)

    const firstResultValue = firstResult && firstResult._value

    if (
      data.length > 0 &&
      (isValidValue(firstResultValue) || isValidValue(lastResultValue))
    ) {
      if (isValidValue(firstResultValue)) {
        // For the 'first message' stat we want the first object (assuming that the data arrives in ascending order).
        if (name === "first message" && typeof firstResultValue === "string") {
          const value = dateFormatter(firstResultValue)
          const footer = getPlotFooter(firstResult)
          return { value, footer }
        }
      }
      if (isValidValue(lastResultValue)) {
        // For the 'last message' stat we want the last object (assuming that the data arrives in ascending order).
        const footer = getPlotFooter(lastResult)
        if (name === "last message" && typeof lastResultValue === "string") {
          return { value: getData(dateFormatter(lastResultValue)), footer }
        } else if (name.includes("time per loop")) {
          return { value: getData(timeFormatter(lastResultValue)), footer }
        } else if (typeof lastResultValue === "number") {
          return { value: getData(lastResultValue), footer }
        } else {
          return { value: getData(lastResultValue as string), footer }
        }
      }
    }
    return { value: "No data" }
  }

  const formattedData = getValue(data, view)

  const config = {
    data: formattedData.value,
    error: formattedData.value instanceof Error ? formattedData.value : null,
    isValid: !(formattedData.value instanceof Error),
    textColor: palette.text.primary,
    footer: formattedData.footer ?? "",
    size: (view.properties as CustomProperties).size,
  }

  return config
}
