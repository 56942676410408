import type { FC } from "react"
import { useTheme } from "@mui/system"
import { ButtonBase, IconButton, Stack, Typography } from "@mui/material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import ControlledInput from "../common/ControlledInput"

interface TelephonesFormProps {
  telephones?: string[]
}

const TelephonesForm: FC<TelephonesFormProps> = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { register, control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: "telephones",
  })

  return (
    <Stack>
      <Stack sx={{ maxWidth: "400px" }}>
        {fields?.map((field, index) => {
          return (
            <Stack key={field.id} sx={{ flexDirection: "row" }}>
              <ControlledInput
                placeholder=""
                variant="outlined"
                size="small"
                styles={{
                  "& .MuiOutlinedInput-input": {
                    "&::webkitOuterSpinButton, &::webkitInnerSpinButton": {
                      webkitAppearance: "none",
                    },
                  },
                }}
                rules={{
                  required: t("generic.FIELD_REQUIRED"),
                  pattern: {
                    value: /^\+?[0-9]+/,
                    message: t("enterprise.MUST_BE_VALID_PHONE_NUMBER"),
                  },
                }}
                {...register(`telephones.${index}.telephone`)}
              />
              <IconButton color="primary" onClick={() => remove(index)}>
                <DeleteOutlineIcon color="error" sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Stack>
          )
        })}
      </Stack>
      <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 0 }}>
        <ButtonBase
          sx={{ display: "flex", gap: 1 }}
          onClick={() => {
            append({
              telephone: "",
            })
          }}
        >
          <AddCircleOutlineIcon color="primary" fontSize="small" />
          <Typography sx={{ color: theme.palette.primary.main }}>
            {t("generic.ADD")}
          </Typography>
        </ButtonBase>
      </Stack>
    </Stack>
  )
}

export default TelephonesForm
