import type { FC } from "react"
import { marker } from "leaflet"
import { LayerGroup, LayersControl, GeoJSON } from "react-leaflet"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"

import type {
  DeviceFeaturePropsWGroups,
  IDeviceFeatureProps,
  GeoJSONLayersData,
} from "types/geolocation.types"
import { getMarkerIcon, onEachFeature, TOWER } from "helpers/utils/map"
import { hasDeviceTriggeredAlerts } from "helpers/utils/alerts"
import type { AlertSettingStatesByIdType } from "types/alerts.types"
import useDevices from "helpers/hooks/useDevices"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import MapMarker from "widgets/plots/geo/MapMarker"
import GeoPopup from "widgets/plots/geo/GeoPopup"

interface GeoJSONLayersProps {
  data: GeoJSONLayersData | undefined
}

const GeoJSONLayers: FC<GeoJSONLayersProps> = ({ data }) => {
  const { i18n, t } = useTranslation()
  const locale = i18n.resolvedLanguage
  const theme = useTheme()

  const { orgAlertsBySettingId } = useAlertSettings()
  const { devsWRelsByIdWStates } = useDevices()

  return (
    <>
      {data && (
        <LayersControl collapsed={false} position="topright">
          {data.Powerline && data.Powerline.features.length > 0 && (
            <LayersControl.Overlay checked name={t("geo_info.POWERLINES")}>
              <LayerGroup>
                <GeoJSON
                  data={data.Powerline}
                  style={(_) => {
                    return {
                      color: theme.palette.primary.main,
                      "& .leaflet-popup-content-wrapper": {
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                      },
                    }
                  }}
                  onEachFeature={onEachFeature({
                    kind: "Powerline",
                    t,
                    locale,
                  })}
                />
              </LayerGroup>
            </LayersControl.Overlay>
          )}
          {data.PowerlineSpan && data.PowerlineSpan.features.length > 0 && (
            <LayersControl.Overlay checked name={t("geo_info.SPANS")}>
              <LayerGroup>
                <GeoJSON
                  data={data.PowerlineSpan}
                  style={(_) => {
                    return { color: theme.palette.primary.dark }
                  }}
                  onEachFeature={onEachFeature({
                    kind: "PowerlineSpan",
                    t,
                    locale,
                  })}
                />
              </LayerGroup>
            </LayersControl.Overlay>
          )}
          {data.PowerlineTower && data.PowerlineTower.features.length > 0 && (
            <LayersControl.Overlay checked name={t("geo_info.TOWERS")}>
              <LayerGroup>
                <GeoJSON
                  data={data.PowerlineTower}
                  pointToLayer={(_, latlng) => {
                    return marker(latlng, {
                      icon: TOWER,
                    })
                  }}
                  onEachFeature={onEachFeature({
                    kind: "PowerlineTower",
                    t,
                    locale,
                  })}
                />
              </LayerGroup>
            </LayersControl.Overlay>
          )}
          {data.Device && data.Device.features.length > 0 && (
            <LayersControl.Overlay checked name={t("geo_info.DEVICES")}>
              <LayerGroup>
                {data.Device.features.map((feature) => {
                  const { less_id: lessId } = feature.properties as IDeviceFeatureProps
                  const device = {
                    ...feature.properties,
                    groups:
                      devsWRelsByIdWStates[feature.properties?.less_id]?.groups ?? [],
                  } as DeviceFeaturePropsWGroups

                  const triggered = Boolean(
                    devsWRelsByIdWStates[lessId]?.settingsStates &&
                      hasDeviceTriggeredAlerts(
                        devsWRelsByIdWStates[lessId]
                          .settingsStates as AlertSettingStatesByIdType,
                        orgAlertsBySettingId,
                      ),
                  )
                  const deviceVersion = device.settings?.device_version ?? ""
                  const hwVersion = device.hardware_version ?? ""
                  const [, deviceVersionPart] = deviceVersion.split("v")
                  const [, hwVersionPart] = hwVersion.split("-")
                  const version = deviceVersionPart ?? hwVersionPart
                  const target = deviceVersionPart ? "3.5" : "3.3"
                  const icon = getMarkerIcon(triggered, version, target)

                  if (
                    feature.properties &&
                    (feature.properties.device_type === "virtual_span" ||
                      feature.properties.device_type === "virtual_powerline")
                  )
                    return

                  return (
                    <MapMarker
                      key={lessId}
                      popup={<GeoPopup device={device} />}
                      position={[
                        feature.geometry.coordinates[1],
                        feature.geometry.coordinates[0],
                      ]}
                      zIndexOffset={triggered ? 500 : 100}
                      icon={icon}
                      tooltipContent={device.name}
                    />
                  )
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          )}
        </LayersControl>
      )}
    </>
  )
}

export default GeoJSONLayers
