import { useCallback, useState } from "react"
import { Dialog } from "@mui/material"

import TwoFactorVerification from "./TwoFactorVerification"
import RecoveryCodesVerification from "./RecoveryCodesVerification"

const OTP = "otp"
const RECOVERY = "recovery"
type VerificationTypeType = typeof OTP | typeof RECOVERY

interface OTPVerifierModalProps {
  open: boolean
  onClose: () => void
  onParentClose?: () => void
  otpCode: string
  onVerify: (value: string) => Promise<any>
  isVerifying: boolean
}

const OTPVerifierModal = ({
  open,
  onClose,
  onParentClose = () => {},
  otpCode,
  onVerify,
  isVerifying,
}: OTPVerifierModalProps) => {
  const [verificationType, setVerificationType] = useState<VerificationTypeType>(OTP)
  const handleSwitchVerification = () => {
    setVerificationType((current) => (current == OTP ? RECOVERY : OTP))
  }

  const verify = useCallback(
    (value: string) =>
      onVerify(value).then(() => {
        onClose()
        onParentClose()
      }),
    [onClose, onParentClose, onVerify],
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="otp-verifier-title"
      aria-describedby="otp-verifier-description"
      fullWidth
      maxWidth={"sm"}
    >
      {verificationType === OTP ? (
        <TwoFactorVerification
          onClose={onClose}
          verify={verify}
          isVerifying={isVerifying}
          otpCode={otpCode}
          handleSwitchVerification={handleSwitchVerification}
        />
      ) : (
        <RecoveryCodesVerification
          onClose={onClose}
          verify={verify}
          isVerifying={isVerifying}
          otpCode={otpCode}
          handleSwitchVerification={handleSwitchVerification}
        />
      )}
    </Dialog>
  )
}

export default OTPVerifierModal
