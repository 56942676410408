import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useDeleteAlertSettingMutation } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"

import type { AlertSettingType } from "types/alerts.types"

import ConfirmationPopup from "../../common/ConfirmationPopup"

interface IDeleteAlertSettingPopupProps {
  alertSetting: AlertSettingType
  open: boolean
  onClose: () => void
}

const DeleteAlertSettingPopup: FC<IDeleteAlertSettingPopupProps> = ({
  alertSetting,
  onClose,
  open,
}) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const [deleteAlert, { isLoading }] = useDeleteAlertSettingMutation()

  const handleDeleteAlert = () => {
    snackbarMutation({
      mutation: deleteAlert({
        id: alertSetting.id as number,
        orgId: orgId as number,
        lessId: alertSetting.less_id,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(t("error.OCCURRED")),
    })
      .then(() => {
        onClose()
      })
      .catch()
  }

  return (
    <ConfirmationPopup
      open={open}
      onClose={onClose}
      description={t("alert_settings.ARE_YOU_SURE_TO_DELETE_SETTING", {
        alertName: alertSetting.config.custom_name,
      })}
      mainButtonText={t("alert_settings.DELETE_ALERT_SETTING")}
      onConfirm={handleDeleteAlert}
      isLoading={isLoading}
      isDeletePopup
    />
  )
}

export default DeleteAlertSettingPopup
