import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../features/store/authSlice"
import { selectCurrentOrgId } from "../../features/store/orgSlice"
import useOrgs from "./useOrgs"

const useRoutes = () => {
  const { orgsFetching, orgsInfoLoading } = useOrgs()
  const orgId = useSelector(selectCurrentOrgId)
  const username = useSelector(selectCurrentUser)
  const baseOrgURL = useMemo(() => `/orgs/${orgId}`, [orgId])
  const baseUserURL = useMemo(() => `/users/${username}`, [username])
  const [indexURL, setIndexURL] = useState<string | undefined>()

  useEffect(() => {
    if (username) {
      if (!(orgsFetching && orgsInfoLoading)) {
        if (orgId) {
          setIndexURL(baseOrgURL)
        } else {
          setIndexURL(baseUserURL)
        }
      }
    }
  }, [orgsFetching, orgsInfoLoading, baseOrgURL, baseUserURL, orgId, username])

  return {
    baseOrgURL,
    baseUserURL,
    indexURL,
  }
}

export default useRoutes
