import { Typography, Stack } from "@mui/material"
import { useTheme } from "@mui/system"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

interface EmailListProps {
  emails: string[]
}

const EmailList: FC<EmailListProps> = ({ emails }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Stack>
      <Typography variant="h5" component="h5" sx={{ pt: 1, pb: 0.5 }}>
        {t("enterprise.EMAILS")}
      </Typography>
      {emails.map((email) => (
        <Typography sx={{ color: theme.palette.grey[400] }} key={email}>
          {email}
        </Typography>
      ))}
    </Stack>
  )
}

export default EmailList
