import { useState } from "react"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"

import CreateGroupPopup from "./CreateGroupPopup"

const CreateGroupButton = () => {
  const { t } = useTranslation()
  const [createPopupOpen, setCreatePopupOpen] = useState(false)

  const handleCreateGroupOpen = () => {
    setCreatePopupOpen(true)
  }
  const handleCreateGroupClose = () => {
    setCreatePopupOpen(false)
  }

  return (
    <>
      <Box>
        <Button
          onClick={handleCreateGroupOpen}
          startIcon={<AddIcon />}
          variant="outlined"
          size="small"
        >
          {t("device.CREATE_DEVICE_GROUP")}
        </Button>
      </Box>
      {createPopupOpen && (
        <CreateGroupPopup open={createPopupOpen} onClose={handleCreateGroupClose} />
      )}
    </>
  )
}

export default CreateGroupButton
