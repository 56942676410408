import type { FC } from "react"
import {
  CheckCircleOutline,
  InfoOutlined,
  Loop,
  WarningAmberOutlined,
} from "@mui/icons-material"
import { SnackbarProvider as Wrapper } from "notistack"

import { StyledMaterialDesignContent } from "widgets/styled/snackbars"

const COMMON_STYLES = { marginRight: "8px", fontSize: "20px" }

const SnackbarProvider: FC = ({ children }) => {
  return (
    <Wrapper
      maxSnack={5}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      Components={{
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        triggered: StyledMaterialDesignContent,
        renotification: StyledMaterialDesignContent,
      }}
      iconVariant={{
        success: (
          <CheckCircleOutline
            sx={{
              ...COMMON_STYLES,
              color: (theme) => theme.palette.success.main,
            }}
          />
        ),
        warning: (
          <WarningAmberOutlined
            sx={{
              ...COMMON_STYLES,
              color: (theme) => theme.palette.warning.main,
            }}
          />
        ),
        error: (
          <InfoOutlined
            sx={{
              ...COMMON_STYLES,
              color: (theme) => theme.palette.error.main,
            }}
          />
        ),
        info: (
          <InfoOutlined
            sx={{
              ...COMMON_STYLES,
              color: (theme) => theme.palette.info.main,
            }}
          />
        ),
        triggered: (
          <InfoOutlined
            sx={{
              ...COMMON_STYLES,
              color: (theme) => theme.palette.error.main,
            }}
          />
        ),
        renotification: (
          <Loop
            sx={{
              ...COMMON_STYLES,
              color: (theme) => theme.palette.warning.main,
            }}
          />
        ),
      }}
    >
      {children}
    </Wrapper>
  )
}

export default SnackbarProvider
