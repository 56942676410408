import { useMemo } from "react"
import { useParams, Outlet } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useUserQuery } from "features/api"
import { getAccountSettingsTabsConfig } from "helpers/config/tabsConfigs"
import LinkTabsSection from "widgets/common/LinkTabsSection"
import Title from "widgets/common/Title"

const AccountSettings = () => {
  const { t } = useTranslation()
  const { username } = useParams()

  const accountSettingsTabsConfig = useMemo(() => getAccountSettingsTabsConfig(), [])
  const {
    data: user,
    isError: isErrorUser,
    isLoading: isLoadingUser,
  } = useUserQuery({ username: String(username) }, { skip: !username })

  return (
    <>
      <Title text={t("account_settings.SETTINGS")} styles={{ mb: 2 }} />
      <LinkTabsSection tabsConfig={accountSettingsTabsConfig} />
      <Outlet context={{ user, isLoadingUser, isErrorUser }} />
    </>
  )
}

export default AccountSettings
