import { useMemo } from "react"
import VolumeOffIcon from "@mui/icons-material/VolumeOff"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import CheckIcon from "@mui/icons-material/Check"

interface useAlertActionsProps {
  actions: string[]
  state: "open" | "silenced" | "notTriggered"
}

const useAlertActions = ({ actions, state }: useAlertActionsProps) => {
  const actionItems = useMemo(
    () => [
      {
        id: 1,
        name: "silence",
        title: "alerts.MARK_AS_SILENCED",
        icon: <VolumeOffIcon fontSize="small" />,
        show: state === "open",
        actionKey: "alerts.SILENCED",
      },
      {
        id: 2,
        name: "unsilence",
        title: "alerts.MARK_AS_OPEN",
        icon: <VolumeUpIcon fontSize="small" />,
        show: state === "silenced",
        actionKey: "alerts.OPEN",
      },
      {
        id: 3,
        name: "resolve",
        title: "alerts.MARK_AS_RESOLVED",
        icon: <CheckIcon fontSize="small" />,
        show: actions?.includes("resolve"),
        actionKey: "alerts.RESOLVED",
      },
    ],
    [actions, state],
  ).filter((item) => item.show)

  return {
    actionItems,
  }
}

export default useAlertActions
