import type { FC } from "react"

import Gravatar from "react-gravatar"

interface AvatarProps {
  email: string | null | undefined
  size?: number
}

const Avatar: FC<AvatarProps> = ({ email, size }) => {
  return (
    <Gravatar
      email={email ?? undefined}
      size={size ?? 32}
      default="identicon"
      style={{ borderRadius: "50%" }}
      protocol="https://"
    />
  )
}

export default Avatar
