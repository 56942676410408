import type { FC } from "react"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Box from "@mui/material/Box"
import type { Theme } from "@mui/material"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { useCreateGroupMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"

import Spinner from "../../common/Spinner"
import ControlledInput from "../../common/ControlledInput"

interface ICreateGroupPopupProps {
  open: boolean
  onClose: () => void
}

interface ICreateGroupForm {
  group_name: string
}

const CreateGroupPopup: FC<ICreateGroupPopupProps> = ({ onClose, open }) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const methods = useForm<ICreateGroupForm>({
    mode: "all",
    defaultValues: {
      group_name: "",
    },
  })
  const [createGroup, { isLoading: createGroupIsLoading, isSuccess }] =
    useCreateGroupMutation()

  const onSubmit = (data: ICreateGroupForm) => {
    snackbarMutation({
      mutation: createGroup({
        group_name: data.group_name,
        org_id: orgId as number,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.CREATING_ITEM", { item: t("device_groups.DEVICE_GROUP").toLowerCase() }),
      ),
      getSuccessMessage: () => t("device_groups.SUCCESS_ALERT_NEW_GROUP"),
    })
      .then(() => {
        onClose()
      })
      .catch()
  }

  return (
    <>
      <Dialog open={open} scroll={"paper"} onClose={onClose} maxWidth="xl">
        <DialogTitle>{t("device.CREATE_DEVICE_GROUP")}</DialogTitle>
        {createGroupIsLoading && (
          <Box
            sx={{
              minWidth: "250px",
              minHeight: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </Box>
        )}
        {!createGroupIsLoading && !isSuccess && (
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <DialogContent dividers={true}>
                <ControlledInput
                  autoFocus
                  name="group_name"
                  placeholder={t("device_groups.GROUP_NAME")}
                  label={t("device_groups.GROUP_NAME")}
                  styles={{
                    "& .MuiInput-root": {
                      fontSize: "1rem",
                    },
                  }}
                />
              </DialogContent>
              <DialogActions sx={{ justifyContent: "flex-end" }}>
                <Button
                  onClick={onClose}
                  sx={{
                    color: (theme: Theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.grey[300]
                        : theme.palette.grey[600],
                  }}
                >
                  {t("generic.CANCEL")}
                </Button>
                <Button sx={{ width: "fit-content" }} type="submit">
                  {t("generic.CREATE")}
                </Button>
              </DialogActions>
            </FormProvider>
          </form>
        )}
      </Dialog>
    </>
  )
}

export default CreateGroupPopup
