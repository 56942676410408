import type { FC } from "react"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

import type { CellViewRes, RecordType } from "types/dashboard.types"

import useSingleStatConfig from "./configuration/useSingleStatConfig"

interface SingleStatProps {
  view: CellViewRes
  data: RecordType[]
}

const SingleStat: FC<SingleStatProps> = ({ view, data }) => {
  const { i18n, t } = useTranslation()
  const locale = i18n.resolvedLanguage
  const config = useSingleStatConfig({ view, data, locale })

  return (
    <>
      <Stack alignItems="center" height={"100%"}>
        <Stack alignItems="center" justifyContent="center" height={"80%"}>
          {config && !config.isValid && (
            <Typography component="p" color="error" data-testid="stat-error">
              {config.error?.message &&
                t([`error.${config.error.message}`, config.error.message])}
            </Typography>
          )}
          {config?.isValid && (
            <>
              <Typography
                sx={{
                  fontSize: config.size === "large" ? "2rem" : "1.1rem",
                  fontWeight: "700",
                  pb: "1rem",
                  textAlign: "center",
                }}
                color={config.textColor}
                data-testid="stat-value"
              >
                {config.data}
              </Typography>
            </>
          )}
        </Stack>
        {config?.isValid && config.footer && (
          <Typography
            variant="body1"
            color="text.secondary"
            position="absolute"
            bottom="2rem"
          >
            {config.footer}
          </Typography>
        )}
      </Stack>
    </>
  )
}

export default SingleStat
