import type { FC, MouseEvent as ReactMouseEvent, PropsWithChildren } from "react"
import { useState } from "react"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import IconButton from "@mui/material/IconButton"
import MuiMenu from "@mui/material/Menu"
import type { SxProps, Theme } from "@mui/material"

interface IMenuProps extends PropsWithChildren<any> {
  iconSx?: SxProps<Theme>
}

const Menu: FC<IMenuProps> = ({ children, iconSx }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: ReactMouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? "more" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        color="inherit"
        data-testid="menu-button"
        sx={{
          pointerEvents: "auto",
          borderRadius: "4px",
          height: "100%",
          ...iconSx,
        }}
      >
        <MoreHorizIcon />
      </IconButton>

      <MuiMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 12,
            sx: {
              mt: 1,
              overflow: "inherit",
              backgroundColor: (theme: Theme) => theme.palette.background.paper,
              backgroundImage: "none",
              minWidth: "150px",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {children}
      </MuiMenu>
    </>
  )
}

export default Menu
