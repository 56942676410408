import type { FC, PropsWithChildren } from "react"
import Box from "@mui/material/Box"

type ITableToolbarSelectProps = PropsWithChildren<any>

const TableToolbarSelect: FC<ITableToolbarSelectProps> = ({ children }) => {
  return <Box>{children}</Box>
}

export default TableToolbarSelect
