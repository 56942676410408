import type { FC } from "react"
import MenuItem from "@mui/material/MenuItem"
import { useTheme } from "@mui/material"

import Menu from "../common/Menu"
import CsvButton from "./CsvButton"
import useTelemetryCSV from "./hooks"

const DashboardMenu: FC = () => {
  const { download: downloadCSV, isFetching: isFetchingCSV } = useTelemetryCSV()
  const theme = useTheme()

  return (
    <Menu
      iconSx={{
        border: `1px solid ${theme.palette.neutral[400]}`,
        "&:hover": {
          borderColor: theme.palette.primary.main,
          transition: "all 0.5s ease",
        },
      }}
    >
      <MenuItem sx={{ p: 0 }}>
        <CsvButton isFetching={isFetchingCSV} download={downloadCSV} />
      </MenuItem>
    </Menu>
  )
}

export default DashboardMenu
