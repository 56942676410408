import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import {
  formatAlerts,
  getAlertAmounts,
  getFilteredSortedAlerts,
} from "helpers/formatters/alertSettingsFormatters"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import useAlertSettingsStates from "helpers/hooks/useAlertSettingsStates"
import useDevices from "helpers/hooks/useDevices"
import { paginateList } from "helpers/utils/common"
import type { AlertsFilterOptions } from "types/alerts.types"
import Spinner from "widgets/common/Spinner"
import AlertsFilter from "widgets/alerts/AlertsFilter"
import AccordionsSection from "widgets/alerts/AccordionsSection"
import Pagination from "widgets/common/Pagination"
import { SectionContainer } from "widgets/styled/containers"

const DeviceAlertsList = () => {
  const { id } = useParams()
  const { allDeviceAlerts, isLoading } = useAlertSettingsStates({
    deviceId: Number(id),
  })
  const { orgAlertsBySettingId } = useAlertSettings()
  const { devsWRelsById } = useDevices()
  const [filters, setFilters] = useState<AlertsFilterOptions | null>(null)
  const [page, setPage] = useState(0)

  const formattedDeviceAlerts = useMemo(
    () => formatAlerts(allDeviceAlerts, orgAlertsBySettingId, devsWRelsById),
    [devsWRelsById, orgAlertsBySettingId, allDeviceAlerts],
  )
  const deviceAlertsAmount = useMemo(
    () => getAlertAmounts(formattedDeviceAlerts),
    [formattedDeviceAlerts],
  )
  const deviceAlertsSortedByState = useMemo(
    () => getFilteredSortedAlerts(formattedDeviceAlerts, filters),
    [formattedDeviceAlerts, filters],
  )
  const pagedAlerts = useMemo(
    () => paginateList(page, deviceAlertsSortedByState),
    [page, deviceAlertsSortedByState],
  )

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <SectionContainer disableGutters maxWidth="xl">
          <AlertsFilter
            alertsAmount={deviceAlertsAmount}
            filters={filters}
            setFilters={setFilters}
          />
          <AccordionsSection alerts={pagedAlerts} />
          <Pagination
            count={deviceAlertsSortedByState.length}
            page={page}
            setPage={setPage}
          />
        </SectionContainer>
      )}
    </>
  )
}

export default DeviceAlertsList
