import type { Path } from "react-router-dom"
import type { Tab } from "types/tabs.types"

const ALERTS_SEARCH_PARAMS = "open=true&silenced=true&notTriggered=false"

const getSentrisenseTabsConfig = (search: string): Tab[] => [
  {
    labelKey: "device.SPAN",
    value: 0,
    to: { pathname: "", search },
  },
  {
    labelKey: "generic.DEVICE",
    value: 1,
    to: { pathname: "device-data", search },
  },
  {
    labelKey: "alerts.ALERTS",
    value: 2,
    to: { pathname: "alerts", search: ALERTS_SEARCH_PARAMS },
  },
  {
    labelKey: "alerts.EVENTS_LOG",
    value: 3,
    to: { pathname: "events-log", search },
  },
  {
    labelKey: "generic.SETTINGS",
    value: 4,
    to: { pathname: "settings" },
  },
]

const getOtherDeviceTypesTabsConfig = (search: string): Tab[] => [
  {
    labelKey: "generic.INFO",
    value: 0,
    to: { pathname: "", search },
  },
  {
    labelKey: "alerts.ALERTS",
    value: 1,
    to: { pathname: "alerts", search: ALERTS_SEARCH_PARAMS },
  },
  {
    labelKey: "alerts.EVENTS_LOG",
    value: 2,
    to: { pathname: "events-log", search },
  },
  {
    labelKey: "generic.SETTINGS",
    value: 3,
    to: { pathname: "settings" },
  },
]

export const getDashboardTabsConfig = (search: string, deviceType: string): Tab[] => {
  switch (deviceType) {
    case "sentrisense":
      return getSentrisenseTabsConfig(search)
    default:
      return getOtherDeviceTypesTabsConfig(search)
  }
}

export const getGroupTabsConfig = (search: string): Tab[] => [
  {
    labelKey: "device_information.INFORMATION",
    value: 0,
    to: { pathname: "" },
  },
  {
    labelKey: "alerts.ALERTS",
    value: 1,
    to: { pathname: "alerts", search: ALERTS_SEARCH_PARAMS },
  },
  {
    labelKey: "alerts.EVENTS_LOG",
    value: 2,
    to: { pathname: "events-log", search },
  },
]

export const getAlertsTabsConfig = (search: string): Tab[] => [
  {
    labelKey: "alerts.ALERTS",
    value: 0,
    to: { pathname: "", search: ALERTS_SEARCH_PARAMS },
  },
  {
    labelKey: "alerts.EVENTS_LOG",
    value: 1,
    to: { pathname: "events-log", search },
  },
]

export const getAPITabsConfig = (): Tab[] => [
  {
    labelKey: "generic.DOCUMENTATION",
    value: 0,
    to: { pathname: "" },
  },
  {
    labelKey: "api.TOKEN",
    value: 1,
    to: { pathname: "create-token" },
  },
]

export const getAccountSettingsTabsConfig = (): Tab[] => [
  {
    labelKey: "account_settings.GENERAL",
    value: 0,
    to: { pathname: "" },
  },
  {
    labelKey: "account_settings.SECURITY",
    value: 1,
    to: { pathname: "security" },
  },
]

export const getTabValue = (path: string, tabConfig: Tab[]): number => {
  const tabsWithoutMain = tabConfig.filter((tab) => tab.value !== 0)
  const tab = tabsWithoutMain.find((tab) => path.includes((tab.to as Path).pathname))
  return tab ? tab.value : 0
}
