import type { FC } from "react"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import { Box, Grid, Typography } from "@mui/material"

import FormButtons from "widgets/common/FormButtons"
import type { OrgType } from "../../types/orgs.types"
import { useUpdateOrgSettingsMutation } from "../../features/api"
import type { ICustomError } from "../../helpers/errors/errorTypes"
import ErrorPopUp from "../common/ErrorPopUp"
import { getTranslationKey } from "../../helpers/utils/translations"
import EmailsForm from "./EmailsForm"
import TelephonesForm from "./TelephonesForm"

interface NotificationSettingsProps {
  org: OrgType
  onClose: () => void
}

type NotificationSettingsFormT = {
  telephones: { telephone: string }[]
  emails: { email: string }[]
}

const NotificationSettingsForm: FC<NotificationSettingsProps> = ({ org, onClose }) => {
  const { t } = useTranslation()
  const [updateOrgSettings, { isLoading, isError, error, isSuccess }] =
    useUpdateOrgSettingsMutation()
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const telephoneFormDefaultValue = useMemo(
    () =>
      org?.telephone_list?.map((telephone) => ({
        telephone,
      })),
    [org],
  )

  const emailsFormDefaultValue = useMemo(
    () =>
      org?.email_list?.map((email) => ({
        email,
      })),
    [org],
  )

  const methods = useForm<NotificationSettingsFormT>({
    mode: "all",
    defaultValues: {
      telephones: telephoneFormDefaultValue || [],
      emails: emailsFormDefaultValue || [],
    },
  })

  const onSubmit = (data: NotificationSettingsFormT) => {
    const telephones = data.telephones.map((telephone) => telephone.telephone).join(", ")
    const emails = data.emails.map((email) => email.email).join(", ")
    updateOrgSettings({ telephones, emails, orgId: org.id })
  }

  useEffect(() => {
    if (isError) {
      setShowErrorPopup(true)
      const newError = error as ICustomError
      setErrorMessage(newError.data.message)
    } else if (isSuccess) {
      onClose()
    }
  }, [isError, error, isSuccess, onClose])

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Typography variant="h5" component="h5" sx={{ pt: 1, pb: 0.5 }}>
                  {t("enterprise.TELEPHONES")}
                </Typography>
              </Box>
              <TelephonesForm telephones={org?.telephone_list} />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Typography variant="h5" component="h5" sx={{ pt: 1, pb: 0.5 }}>
                  {t("enterprise.EMAILS")}
                </Typography>
              </Box>
              <EmailsForm emails={org?.email_list} />
            </Grid>
          </Grid>
          <FormButtons
            onClose={onClose}
            buttonText="generic.UPDATE"
            isLoading={isLoading}
          />
        </FormProvider>
      </form>
      <ErrorPopUp open={showErrorPopup} onClose={() => setShowErrorPopup(false)}>
        <Typography>{t("organization.FAILED_ORG_SETTINGS_UPDATE")}</Typography>
        {errorMessage && t([`error.${getTranslationKey(errorMessage)}`, errorMessage])}
      </ErrorPopUp>
    </>
  )
}

export default NotificationSettingsForm
