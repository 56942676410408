import type { CSSProperties } from "react"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { alpha, useTheme } from "@mui/material"
import type { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables"

import {
  selectDevicesTable,
  setDevicesTableRowsAmount,
  setDevicesTableSortOrder,
} from "features/store/tablesSlice"
import useRoutes from "helpers/hooks/useRoutes"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import {
  getDeviceTableColumns,
  getDevicesTableRows,
  formatDevicesTableCsv,
  buildDevicesSearch,
} from "helpers/utils/devices"
import { getColsByName } from "helpers/utils/alerts"
import type { DeviceWGroupsNStatesType } from "types/device.types"
import GenericTable from "widgets/common/Table"
import SkeletonWrapper from "widgets/common/SkeletonWrapper"

interface DevicesTableProps {
  devices: DeviceWGroupsNStatesType[]
  isLoading: boolean
  isFetching?: boolean
  titleKey?: string
  customColumns?: MUIDataTableColumn[]
  options?: MUIDataTableOptions
  styles?: CSSProperties
  onRowSelectionChange?: (
    currentRowsSelected: any,
    allRowsSelected: any,
    rowsSelected: any,
    rows: any,
  ) => void
}

function DevicesTable({
  devices,
  isLoading,
  isFetching,
  titleKey,
  customColumns = [],
  options,
  styles = {},
  onRowSelectionChange,
}: DevicesTableProps) {
  const { baseOrgURL } = useRoutes()
  const { t } = useTranslation()
  const devicesTable = useSelector(selectDevicesTable)
  const dispatch = useDispatch()
  const theme = useTheme()
  const { orgAlertsBySettingId } = useAlertSettings()

  const columns: MUIDataTableColumn[] = useMemo(
    () =>
      customColumns.length > 0 ? customColumns : getDeviceTableColumns(baseOrgURL, t),
    [baseOrgURL, customColumns, t],
  )

  const { rows, columnsIndexes } = useMemo(() => {
    return {
      rows: getDevicesTableRows(devices, orgAlertsBySettingId),
      columnsIndexes: getColsByName(columns),
    }
  }, [devices, orgAlertsBySettingId, columns])

  return (
    <SkeletonWrapper
      isWaiting={isLoading || Boolean(isFetching)}
      height={540}
      width={"100vw"}
    >
      <GenericTable
        styles={styles}
        title={titleKey}
        rows={rows}
        columns={columns}
        options={{
          selectableRows: "none",
          setRowProps: (row) => {
            const index = columnsIndexes.alertStatus
            const triggered =
              row[index]?.props.children.props.title === t("alerts.TRIGGERED_ALERT")
            if (triggered) {
              return {
                style: {
                  backgroundColor: alpha(theme.palette.error.main, 0.1),
                },
              }
            }
            return {}
          },
          customSearch: buildDevicesSearch(columnsIndexes, t),
          onDownload: (buildHead, buildBody, columns, data) => {
            const formattedData = formatDevicesTableCsv(data, columnsIndexes, t)
            return `${buildHead(columns)}${buildBody(formattedData)}`.trim()
          },

          /* --- Persistence values --- */
          rowsPerPage: devicesTable?.qtyRows,
          sortOrder: devicesTable?.sortOrder,
          onChangeRowsPerPage: (numberOfRows) => {
            dispatch(setDevicesTableRowsAmount(numberOfRows))
          },
          onColumnSortChange: (changedColumn, direction) => {
            dispatch(setDevicesTableSortOrder({ name: changedColumn, direction }))
          },
          onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) =>
            onRowSelectionChange?.(
              currentRowsSelected,
              allRowsSelected,
              rowsSelected,
              rows,
            ),
          ...options,
        }}
      />
    </SkeletonWrapper>
  )
}

export default DevicesTable
