import type { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { ButtonGroup } from "@mui/material"

import { TabButton } from "widgets/styled/buttons"

interface TabButtonGroupProps {
  buttons: { name: string; value: number }[]
  tab: number
  setTab: Dispatch<SetStateAction<number>>
}

const TabButtonGroup = ({ buttons, tab, setTab }: TabButtonGroupProps) => {
  const { t } = useTranslation()

  return (
    <ButtonGroup variant="outlined" size="small">
      {buttons.map((button) => {
        return (
          <TabButton
            key={button.value}
            variant={tab === button.value ? "contained" : "outlined"}
            onClick={() => setTab(button.value)}
            value={button.value}
          >
            {t(button.name)}
          </TabButton>
        )
      })}
    </ButtonGroup>
  )
}

export default TabButtonGroup
