import fp from "lodash/fp"
import type { DateTime } from "luxon"
import type { FormEvent } from "react"
import { useCallback, useMemo, useRef, useState } from "react"
import { Button, Tooltip, useTheme } from "@mui/material"
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded"
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp"
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp"
import { useTranslation } from "react-i18next"

import type { DateRangeDateTime } from "types/date.types"
import useSelectOptions from "helpers/hooks/useSelectOptions"
import Spinner from "widgets/common/Spinner"
import DatesPopover from "./DatesPopover"

interface DateRangeSelectProps {
  dateRangeDateTime: DateRangeDateTime
  updateDateRange: (
    fields: { [key: string]: DateTime | null },
    dateRange: DateRangeDateTime,
  ) => void
  setSelectedDateReference: (dateReferenceName: string) => void
  isFetching: boolean
}

export default function DateRangeCustomSelect({
  dateRangeDateTime,
  updateDateRange,
  setSelectedDateReference,
  isFetching,
}: DateRangeSelectProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { dateRefOptions } = useSelectOptions()
  const [isOpen, setIsOpen] = useState(false)
  const anchorRef = useRef<null | HTMLButtonElement>(null)
  const onClose = useCallback(() => setIsOpen(false), [])
  const onOpen = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      setIsOpen(!isOpen)
    },
    [isOpen],
  )
  const tooltipText = useMemo(
    () => (
      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <p>{dateRangeDateTime.fromDate.toFormat("yyyy-MM-dd HH:mm:ss")}</p>
        <p>{t("generic.TO").toLowerCase()}</p>
        <p>{dateRangeDateTime.toDate.toFormat("yyyy-MM-dd HH:mm:ss")}</p>
      </div>
    ),
    [t, dateRangeDateTime],
  )

  const label = useMemo(
    () => fp.find({ value: dateRangeDateTime.range }, dateRefOptions)?.label ?? "",
    [dateRangeDateTime.range, dateRefOptions],
  )

  return (
    <>
      <Tooltip
        title={<>{tooltipText}</>}
        enterTouchDelay={0}
        arrow
        PopperProps={{ style: { zIndex: 1298 } }} // Avoids having tooltip on top
      >
        <Button
          aria-label="dates select button"
          variant="outlined"
          onClick={onOpen}
          startIcon={
            isFetching ? (
              <Spinner size="1rem" disableGutters />
            ) : (
              <AccessTimeRoundedIcon />
            )
          }
          endIcon={isOpen ? <ArrowDropUpSharpIcon /> : <ArrowDropDownSharpIcon />}
          ref={anchorRef}
          sx={{
            color: theme.palette.text.secondary,
            borderColor: theme.palette.neutral[400],
          }}
        >
          {dateRangeDateTime.range && t(label)}
        </Button>
      </Tooltip>
      {isOpen && (
        <DatesPopover
          updateDateRange={updateDateRange}
          dateRangeDateTime={dateRangeDateTime}
          anchorEl={anchorRef.current}
          handleClose={onClose}
          open={isOpen}
          setSelectedDateReference={setSelectedDateReference}
        />
      )}
    </>
  )
}
