import type { TFunction } from "i18next"

export const getRangeDropdownOptions = (t: TFunction) => {
  const rangeDropdownOptions = [
    { label: t("alert_settings.IS_BELOW"), value: "below" },
    { label: t("alert_settings.IS_INSIDE_RANGE"), value: "inside" },
    { label: t("alert_settings.IS_OUTSIDE_RANGE"), value: "outside" },
    { label: t("alert_settings.IS_ABOVE"), value: "above" },
  ]
  const defaultRangeCondition = rangeDropdownOptions[0].value
  return { rangeDropdownOptions, defaultRangeCondition }
}
