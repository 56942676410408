import { useState } from "react"
import { IconButton, useTheme } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"

import type { AccordionAlertSetting } from "types/alerts.types"
import { Accordion, AccordionDetails, AccordionSummary } from "widgets/styled/widgets"
import AlertAccordionSummaryContent from "widgets/alerts/AlertAccordionSummaryContent"
import AlertAccordionDetailsContent from "widgets/alerts/AlertAccordionDetailsContent"

interface AlertAccordionProps {
  alert: AccordionAlertSetting
}

function AlertAccordion({ alert }: AlertAccordionProps) {
  const [details, setDetails] = useState(false)
  const theme = useTheme()

  return (
    <Accordion variant="outlined" state={alert.state}>
      <AccordionSummary
        state={alert.state}
        expandIcon={
          <IconButton size="small" onClick={() => setDetails(true)}>
            <ExpandMore
              sx={{
                color: (theme) => theme.palette.text.primary,
                pointerEvents: "auto",
              }}
            />
          </IconButton>
        }
        sx={{
          pointerEvents: "none",
          [theme.breakpoints.down("md")]: { p: "0.5rem" },
        }}
      >
        <AlertAccordionSummaryContent alert={alert} />
      </AccordionSummary>
      {details && (
        <AccordionDetails state={alert.state}>
          <AlertAccordionDetailsContent alert={alert} />
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default AlertAccordion
