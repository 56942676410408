import { Box, Skeleton } from "@mui/material"

interface SkeletonBoxProps {
  height: number | string
  width?: number | string
}

const SkeletonBox = ({ height, width = "100vw" }: SkeletonBoxProps) => {
  return (
    <Skeleton variant="rounded">
      <Box height={height} width={width} />
    </Skeleton>
  )
}

export default SkeletonBox
