import type { FC } from "react"
import { forwardRef } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

interface ITitleProps {
  text: string
  styles?: object
}

const Title: FC<ITitleProps> = ({ text, styles }) => {
  return (
    <Box sx={{ ...styles }}>
      <Typography variant="h3" component="h3">
        {text}
      </Typography>
    </Box>
  )
}

export const TitleWithRef = forwardRef<HTMLDivElement, ITitleProps>(
  function TitleWithRef(props, ref) {
    return (
      <div {...props} ref={ref} style={{ height: "fit-content" }}>
        <Title {...props} styles={{ pb: 0 }} />
      </div>
    )
  },
)

export default Title
