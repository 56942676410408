import type { FC, PropsWithChildren, ReactNode } from "react"
import { Box } from "@mui/material"

import { SectionCard } from "widgets/styled/containers"
import SectionTitle from "./SectionTitle"

interface CardWithTitleProps {
  titleKey: string
  actionPermission?: boolean
  isDoingAction?: boolean
  handleAction?: () => void
  actionIcon?: ReactNode
}

const CardWithTitle: FC<PropsWithChildren<CardWithTitleProps>> = ({
  titleKey,
  actionPermission = true,
  isDoingAction = false,
  handleAction,
  actionIcon,
  children,
}) => {
  return (
    <SectionCard styles={{ margin: 0, height: "100%" }}>
      <SectionTitle
        titleKey={titleKey}
        actionPermission={actionPermission}
        isDoingAction={isDoingAction}
        handleAction={handleAction}
        actionIcon={actionIcon}
      />
      <Box p={2.5}>{children}</Box>
    </SectionCard>
  )
}

export default CardWithTitle
