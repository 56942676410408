import { useTranslation } from "react-i18next"

import {
  useDeviceAlertTypesQuery,
  // useGroupAlertsInfoQuery
} from "features/api"

const useAlertTypes = () => {
  const { i18n } = useTranslation()
  const userLanguage = i18n.resolvedLanguage

  const {
    data: deviceAlertTypes,
    isLoading,
    isFetching,
  } = useDeviceAlertTypesQuery({
    lessId: "-",
    params: {
      lang: userLanguage,
    },
  })

  //   const { data: groupAlertTypes } = useGroupAlertsInfoQuery({
  //     params: {
  //       lang: userLanguage,
  //     },
  //   })

  return {
    isFetching,
    isLoading,
    deviceAlertTypes,
    // groupAlertTypes
  }
}

export default useAlertTypes
