import { useTranslation } from "react-i18next"
import type { Theme } from "@mui/material"
import { Fab, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material"
import AssignmentIcon from "@mui/icons-material/Assignment"

import type { UserOTPRecoverCodes } from "types/users.types"
import { SectionCard } from "widgets/styled/containers"
import { infoMessage } from "helpers/utils/snackbars"

interface OTPRecoverCodesProps {
  codes: UserOTPRecoverCodes
}

const OTPRecoverCodes = ({ codes }: OTPRecoverCodesProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const codeToString = ({ index, code }: { index: number; code: string }) =>
    `${String(index).padStart(2, "0")}: ${code}`

  const handleClipboardClick = async () => {
    const content = codes.map(codeToString).join("\n")
    await navigator.clipboard
      .writeText(content)
      .then(() => infoMessage(t("account_settings.COPIED_TO_CLIPBOARD")))
  }
  return (
    <>
      <SectionCard maxWidth={"sm"} width={"100%"} p={2}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          gap={2}
        >
          <Stack>
            {codes.map((recoverCode) => (
              <Typography key={recoverCode.index} sx={{ fontFamily: "Monospace" }}>
                {codeToString(recoverCode)}
              </Typography>
            ))}
          </Stack>
          <Stack justifyContent={"end"} alignItems={"end"}>
            <Tooltip title={t("account_settings.COPY_TO_CLIPBOARD")}>
              <Fab onClick={handleClipboardClick} size="small">
                <AssignmentIcon />
              </Fab>
            </Tooltip>
          </Stack>
        </Stack>
      </SectionCard>
    </>
  )
}

export default OTPRecoverCodes
