import type { CSSProperties, ReactNode } from "react"
import Box from "@mui/material/Box"

import {
  Error,
  Info,
  NeutralDark,
  NeutralLight,
  Success,
  Warning,
} from "../styled/widgets"

interface IStatusBoxProps {
  children: ReactNode
  status?: "info" | "success" | "error" | "warning" | "neutral-light" | "neutral-dark"
  styles?: CSSProperties
}

export default function StatusBox({ children, status, styles = {} }: IStatusBoxProps) {
  switch (status) {
    case "info":
      return (
        <Info styles={styles} data-testid="info-status-box">
          {children}
        </Info>
      )
    case "success":
      return (
        <Success styles={styles} data-testid="success-status-box">
          {children}
        </Success>
      )
    case "error":
      return (
        <Error styles={styles} data-testid="error-status-box">
          {children}
        </Error>
      )
    case "warning":
      return (
        <Warning styles={styles} data-testid="warning-status-box">
          {children}
        </Warning>
      )
    case "neutral-light":
      return (
        <NeutralLight styles={styles} data-testid="neutral-light-status-box">
          {children}
        </NeutralLight>
      )
    case "neutral-dark":
      return (
        <NeutralDark styles={styles} data-testid="neutral-dark-status-box">
          {children}
        </NeutralDark>
      )
    default:
      return (
        <Box sx={styles} data-testid="status-box">
          {children}
        </Box>
      )
  }
}
