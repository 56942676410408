import type { FC } from "react"
import { useTranslation } from "react-i18next"
import type { Theme } from "@mui/material"
import Button from "@mui/material/Button"

import TableToolbarSelect from "../../common/TableToolbarSelect"

interface IDevicesTableToolbarSelectProps {
  handleOpen: () => void
}

const DevicesTableToolbarSelect: FC<IDevicesTableToolbarSelectProps> = ({
  handleOpen,
}) => {
  const { t } = useTranslation()

  return (
    <TableToolbarSelect>
      <Button
        onClick={handleOpen}
        sx={{ color: (theme: Theme) => theme.palette.error.main }}
      >
        {t("device_groups.REMOVE_DEVICES")}
      </Button>
    </TableToolbarSelect>
  )
}

export default DevicesTableToolbarSelect
