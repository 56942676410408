import type { Middleware, PayloadAction, Reducer } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { DEFAULT_LANGUAGE, DEFAULT_THEME } from "../../helpers/utils/constants"
import type { RootState } from "."

interface ConfigState {
  language: string
  theme: string
}

const initialState: ConfigState = {
  language: DEFAULT_LANGUAGE,
  theme: DEFAULT_THEME,
}

function getPersistentConfig(): ConfigState {
  // Lazy load the state from the localStorage or fallback to the initialState
  try {
    const state = JSON.parse(localStorage.getItem("config") as string)
    const { language, theme } = state
    return {
      language,
      theme,
    }
  } catch (error) {
    return initialState
  }
}

export const configSlice = createSlice({
  name: "config",
  initialState: getPersistentConfig,
  reducers: {
    switchLanguage: (state, action: PayloadAction<string>): ConfigState => ({
      ...state,
      language: action.payload,
    }),
    switchTheme: (state, action: PayloadAction<string>): ConfigState => ({
      ...state,
      theme: action.payload,
    }),
  },
})

export const { switchLanguage, switchTheme } = configSlice.actions
export const configReducer: Reducer<ConfigState> = configSlice.reducer
export default configReducer

export const selectLanguage = (state: RootState) => state.config.language
export const selectTheme = (state: RootState) => state.config.theme

// Store the config in localStorage, while keeping the reducers as pure functions
export const configMiddleware: Middleware = (store) => (next) => (action) => {
  // console.group(action.type)
  const result = next(action)
  switch (action.type) {
    case "config/switchLanguage": // fallthrough
    case "config/switchTheme": {
      const { config } = store.getState()
      // console.log(`store: ${JSON.stringify(config, undefined, 2)}`)
      localStorage.setItem("config", JSON.stringify(config))
      // console.log("done")
      break
    }
    default:
      break
  }
  // console.groupEnd()
  return result
}
