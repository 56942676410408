import type { Middleware, PayloadAction, Reducer } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import type { RootState } from "."

interface OrgState {
  id: number | null
  name: string | null
  userRole: string | null
}

const initialState: OrgState = {
  id: null,
  name: null,
  userRole: null,
}

function getPersistentOrg(): OrgState {
  // Lazy load the state from the localStorage or fallback to the initialState
  try {
    const state = JSON.parse(localStorage.getItem("org") as string)
    const { id, name, userRole } = state
    return {
      id,
      name,
      userRole,
    }
  } catch (error) {
    return initialState
  }
}

export const orgSlice = createSlice({
  name: "org",
  initialState: getPersistentOrg,
  reducers: {
    setOrg: (state, { payload }: PayloadAction<OrgState>): OrgState => {
      return { ...state, ...payload }
    },
    setOrgName: (state, { payload }: PayloadAction<string>): OrgState => {
      return { ...state, name: payload }
    },
    setOrgUserRole: (state, { payload }: PayloadAction<string>): OrgState => {
      return { ...state, userRole: payload }
    },
  },
})

export const { setOrg, setOrgName, setOrgUserRole } = orgSlice.actions
export const orgReducer: Reducer<OrgState> = orgSlice.reducer
export default orgReducer

export const selectCurrentOrgId = (state: RootState) => state.org.id
export const selectCurrentOrgName = (state: RootState) => state.org.name
export const selectCurrentOrgUserRole = (state: RootState) => state.org.userRole

// Store the org in localStorage, while keeping the reducers as pure functions
export const orgMiddleware: Middleware = (store) => (next) => (action) => {
  // console.group(action.type)
  const result = next(action)
  switch (action.type) {
    case "org/setOrgName": // fallthrough
    case "org/setOrgUserRole":
    case "org/setOrg": {
      const { org } = store.getState()
      localStorage.setItem("org", JSON.stringify(org))
      // console.log("done")
      break
    }
    default:
      break
  }
  // console.groupEnd()
  return result
}
